import React, { useState } from "react";
import styles from "../../styles/JobPage.module.css";
import Pagination from "../Pagination/Pagination";
import PersonCard from "../PersonCard/PersonCard";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ITEMS_PER_PAGE = 10;

const SearchResults = ({ peopleData }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(peopleData.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const onViewClick = (data) => {
    console.log("data ==> ", data);
    history.push(`/profile/${data.id}/view`);
  };

  return (
    <div className={styles.resultsContainer}>
      <div className={styles.resultsHeader}>
        <div className={styles.iconContainer}>
          <AccountBoxIcon sx={{ width: 32, height: 32, fill: "#ee2f75" }} />
        </div>
        <span className={styles.headerTitle}>{t("filter.searchResult")}</span>
      </div>

      {/* People List */}
      <div className={styles.peopleList}>
        {peopleData.length === 0 ? (
          <div className={styles.noDataContainer}>
            <img
              src={"/Image/noprofileDataIllustration.svg"}
              alt="No Data Found"
              className={styles.noDataImage}
            />
          </div>
        ) : (
          peopleData
            .slice(startIndex, startIndex + ITEMS_PER_PAGE)
            .map((person) => (
              <PersonCard
                key={person.id}
                person={person}
                onViewClick={onViewClick}
              />
            ))
        )}

        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          data={peopleData}
        />
      </div>
    </div>
  );
};

export default SearchResults;
