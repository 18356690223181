import { Avatar } from "@material-ui/core";
import ClassIcon from "@material-ui/icons/Class";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import GroupIcon from "@material-ui/icons/Group";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import styles from "../Components/CSSFiles/Sidebar.module.css";
import { Component } from "react";
import MiddleContain from "../../ClassesGroups/ClassModal/MiddleContain";
import { ModalWithHeader } from "../shared/ui/Modal/Modal";
import ReactDOM from "react-dom";
import ReactTooltip from "react-tooltip";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import StartLive from "../../../components/commons/StartLive";
import SidebarClass from "./SidebarClass";
import SidebarGroup from "./SidebarGroup";
import { getAboutUser } from "../../../redux/actions/about";
import SaveButton from "../../../components/commons/SaveButton";
import MyClass from "../Components/MyClass";
import MyGroup from "../Components/MyGroup";
import MyCourse from "../Components/MyCourse";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { fetchSearchResultsData } from "../../../redux/actions/profile";
import { fetchPeopleYouMayKnow } from "../../../redux/actions/peopleyoumayknow";
import {
  FETCH_SEARCH_RESULTS,
  GET_PROFILE_DATA_EDIT,
} from "../../../redux/constants/actionTypes";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { fetchProfileDataEdit } from "../../../redux/actions/profile";

import { getAllCourses } from "../../../redux/actions/courses";
import { thumbnailList } from "../../../redux/constants/commonFunctions";
import { Box, Button, Typography } from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import StorefrontIcon from "@mui/icons-material/Storefront";
import DealsCarousel from "./DealsCarousel";

class Sidebar extends Component {
  constructor() {
    super();

    this.state = {
      showMenu1: false,
      search: "",
    };

    this.state = {
      showMenu2: false,
    };

    this.state = {
      showMenu3: false,
    };

    this.state = {
      showShareModal: false,
    };

    this.state = {
      showShareGroupModal: false,
    };

    this.state = {
      img: "/Image/DashImage/live-session.svg",
    };
  }

  shareModalCloser = () => {
    this.setState({ showShareModal: false });
  };

  shareModalOpener = () => {
    this.setState({ showShareModal: true });
  };

  shareGroupModalCloser = () => {
    this.setState({ showShareGroupModal: false });
  };

  shareGroupModalOpener = () => {
    this.setState({ showShareGroupModal: true });
  };

  toogleMenu1 = () => {
    this.setState({
      showMenu1: !this.state.showMenu1,
    });
  };

  toogleMenu2 = () => {
    this.setState({
      showMenu2: !this.state.showMenu2,
    });
  };

  toogleMenu3 = () => {
    this.setState({
      showMenu3: !this.state.showMenu3,
    });
  };

  searchResult = () => {
    let token = JSON.parse(localStorage.getItem("userData")).token;
    this.props
      .fetchSearchResultsData(
        FETCH_SEARCH_RESULTS,
        `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/results?search=`
      )

      .then((res) => {
        if (res.val) {
          this.props.history.push("/connections");
          return;
        }
        this.setState({ error: true, message: res.message });
      });
  };

  componentDidMount() {
    let token = JSON.parse(localStorage.getItem("userData"))?.token;

    document.addEventListener("click", this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  handleCompleteProfile = () => {
    this.props.history.push(
      `/profile/${JSON.parse(localStorage?.userData || "{}").profile_id}/edit`
    );
  };

  handleClickOutside = (event) => {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        showMenu1: false,
        showMenu2: false,
      });
    }
  };

  handleProfileClick = () => {
    this.props.history.push(
      `/profile/${JSON.parse(localStorage?.userData || "{}").profile_id}/edit`
    );
  };

  render() {
    const profileStatus = [
      { name: "complete", val: this.props.profile_completion_status * 100 },
      {
        name: "pending",
        val: 100 - this.props.profile_completion_status * 100,
      },
    ];

    return (
      <div className={styles.sidebar}>
        <div className={styles.profile_section}>
          <div className={styles.sidebar_top}>
            <img
              src={
                this.props.coverPicUrl ??
                "/Image/apps/profile-resume-edit-view/profile_cover.png"
              }
              alt=""
            />
            <img
              src={
                this.props.profilePicUrl
                  ? this.props.profilePicUrl
                  : this.props.avatarImg
                  ? this.props.avatarImg
                  : "/Image/header/ddefault_img.svg"
              }
              className={styles.sidebar_avatar}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "100%",
                cursor: "pointer",
              }}
              onClick={this.handleProfileClick}
            />
            <div className={styles.name}>
              <h2 style={{ textTransform: "capitalize" }}>
                {this.props?.name ??
                  this.props.user.first_name +
                    " " +
                    this.props.user.last_name ??
                  ""}
              </h2>
            </div>
            <p
              style={{
                display: this.props.designation === "" ? "none" : "block",
              }}
            >
              {this.props.designation}
              <br />
              {this.props.organisation}
            </p>
          </div>
          <hr className={styles.Hr} />
          <div
            style={{
              paddingTop: "10px",
              marginLeft: "10px",
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
              marginBottom: "10px",
              justifyContent: "center",
            }}
          >
            <div>
              <p
                style={{ fontSize: "14px", fontWeight: 500, color: "#666666" }}
              >
                {this.props.t("dashboard.profileComplete", {
                  completionPercent: parseInt(
                    this.props.profile_completion_status * 100
                  ),
                })}
              </p>

              <Button
                onClick={this.handleCompleteProfile}
                disableRipple
                size="small"
                sx={{
                  textTransform: "capitalize",
                  background: "var(--optional-gradient)",
                  borderRadius: "20px",
                  color: "#fff",
                  px: 2,
                  mt: 2,
                  ":hover": {
                    background: "var(--optional-gradient)",
                  },
                }}
              >
                {this.props.t("dashboard.completeProfile")}
              </Button>
            </div>
          </div>

          <div className={styles.view}>
            <Button
              startIcon={<AddIcon />}
              onClick={this.searchResult}
              disableRipple
              size="medium"
              sx={{
                textTransform: "none",
                background: "var(--primary-gradient)",
                borderRadius: "20px",
                color: "#fff",
                px: 2,
                mt: 1,
                ":hover": {
                  background: "var(--primary-gradient)",
                },
                fontWeight: 500,
                lineHeight: { xs: 1.75, sm: 1.75, md: 1.25, lg: 1.75 },
              }}
            >
              {this.props.t("dashboard.myConnections")}
            </Button>
          </div>
          <div className={styles.view}>
            <Button
              startIcon={<AutoAwesomeIcon />}
              onClick={() => this.props.history.push("/discover")}
              disableRipple
              size="medium"
              sx={{
                textTransform: "none",
                background: "var(--secondary-gradient)",
                borderRadius: "20px",
                color: "#fff",
                px: 2,
                mt: 1,
                ":hover": {
                  background: "var(--secondary-gradient)",
                },
                fontWeight: 500,
                lineHeight: { xs: 1.75, sm: 1.75, md: 1.25, lg: 1.75 },
              }}
            >
              {this.props.t("dashboard.startLive")}
            </Button>
          </div>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img
              src="/Image/DashImage/business.svg"
              alt="business"
              style={{ marginTop: 8, width: "90%" }}
            />
          </Box>

          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              textAlign: "left",
              ml: 3,
              color: "#535353",
              fontStyle: "italic",
            }}
          >
            {this.props.t("dashboard.ownBusiness")}
          </Typography>

          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              textAlign: "left",
              ml: 3,
              color: "#535353",
              mr: { lg: 7, md: 5 },
            }}
          >
            {this.props.t("dashboard.businessDesc")}
          </Typography>

          <div className={styles.view}>
            <Button
              startIcon={<StorefrontIcon />}
              onClick={() =>
                this.props.history.push({
                  pathname: "/signup",
                  state: { detail: "LOCAL BUSINESS" },
                })
              }
              disableRipple
              size="medium"
              sx={{
                textTransform: "none",
                background: "var(--primary-optional)",
                borderRadius: "20px",
                color: "#fff",
                px: 2,
                mt: 2,
                ":hover": {
                  background: "var(--primary-optional)",
                },
                fontWeight: 500,
                lineHeight: { xs: 1.75, sm: 1.75, md: 1.25, lg: 1.75 },
                mb: 2,
              }}
            >
              {this.props.t("dashboard.createBusinessPage")}
            </Button>
          </div>
        </div>
        <DealsCarousel />
        <ReactTooltip />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state?.profile?.user,
    first_name: state?.profile?.user?.first_name,
    last_name: state?.profile?.user?.last_name,
    profilePicUrl: state?.profile?.userProfilePic,
    avatarImg: state?.profile?.avatarImg,
    coverPicUrl: state?.profile?.userCoverPic,
    designation: state?.profile?.designation,
    connections: state?.profile?.myConnections || 0,
    organisation: state?.profile?.organisation,
    name: state?.profile?.name,
    interests: state?.profile?.interests || [],
    profile_completion_status: state?.profile?.profile_completion_status || "",
    viewsProfile: state?.profile?.viewsProfile || 0,
    publicCourses: state?.courses?.publicCourses || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPeopleYouMayKnow: (action, token) =>
      dispatch(fetchPeopleYouMayKnow(action, token)),
    fetchSearchResultsData: (action, url) =>
      dispatch(fetchSearchResultsData(action, url)),
    // fetchProfileDataEdit: (action, token, id) => dispatch(fetchProfileDataEdit(action, token, id)),
    getAllCourses: (
      classId,
      isPublic,
      isEnrolled,
      isFaculty,
      isFeed,
      isExploring,
      limit
    ) =>
      dispatch(
        getAllCourses(
          classId,
          isPublic,
          isEnrolled,
          isFaculty,
          isFeed,
          isExploring,
          limit
        )
      ),
  };
};
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar))
);
