import React from "react";
import ReactTooltip from "react-tooltip";
import desc from "../../apps/Profile/pages/templates/desc";
import toggleStyles from "../../apps/ClassesGroups/ClassModal/css/MiddleContain.module.css";
import styles from "../../apps/Profile/Profile.module.css";
import ContactModal from "./ContactModal";
import { connect } from "react-redux";
import Modal from "./Modal";
import ChooseTemplates from "../../apps/Profile/pages/templates/ChooseTemplates";
import HandleApiError from "./HandleApiError";
import { withTranslation } from "react-i18next";
import { Box } from "@mui/material";
import PublicPrivateToggle from "./PublicPrivateToggle";
import ProfileTypeToggle from "./ProfileTypeToggle";
import { updateProfileData } from "../../redux/actions/profile";
import { EDIT_PROFILE_DATA } from "../../redux/constants/actionTypes";
import ShortBioModal from "./ShortBioModal";

class InfoCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      editcontact: false,
      editShortBio: false,
      showMenu: false,
      profile_type: this.props.profile_type,
      showTemlates: false,
      profileLink: desc.website,
      phone: this.props.phone,
      address: this.props.address,
      email: this.props.email,
      dob: this.props.dob,
      user: {},
      username:
        this.props?.user?.first_name || this.props?.user?.last_name
          ? this.props.user.first_name + " " + this.props.user.last_name
          : "",
      designation: this.props.designation || "Director-Corporate Affairs",
      organisation: this.props.organisation || "J kakehasti Services Pvt Ltd.",
      location: this.props.location || "Kolkata, West Bengal, India",
      connections: this.props.connections || 0,
    };
  }

  changeStates(
    username,
    designation,
    location,
    edit,
    phone,
    email,
    address,
    dob
  ) {
    this.setState({
      username: username,
      designation: designation,
      location: location,
      edit: edit,
      phone: phone,
      email: email,
      address: address,
      dob: dob,
    });
  }

  changeEdit(editcontact) {
    this.setState({ editcontact: editcontact });
  }

  handleShowShortBio(editShortBio){
    this.setState({editShortBio: editShortBio})
  }

  componentDidMount() {
    this.setState({
      designation: this.props.designation,
      organisation: this.props.organisation,
      location: this.props.location,
      connections: this.props.connections,
      user: this.props.user,
      error: false,
      message: "",
    });

    setTimeout(() => {
      this.setState({
        profile_type: this.props.profile_type || "PUBLIC",
      });
    }, 2000);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.profile_type !== this.props.profile_type) {
      this.setState({
        profile_type: this.props.profile_type || "PUBLIC",
      });
    }
  }

  changeShowTemplatesState(showTemlates) {
    this.setState({ showTemlates: showTemlates });
  }

  SwitchProfileType = () => {
    let newProfileType =
      this.state.profile_type === "PUBLIC" ? "PRIVATE" : "PUBLIC";

    let data = new FormData();
    data.append("profile_type", newProfileType);

    this.props.updateProfileData(EDIT_PROFILE_DATA, data).then((res) => {
      if (res.val) {
        this.setState({ profile_type: newProfileType });
        document.body.style.overflow = "visible";
        return;
      }
    });
  };

  render() {
    const { t } = this.props;
    console.log(this.state.profile_type);
    return (
      <>
        <HandleApiError
          error={this.state.error}
          message={this.state.message}
          onClose={() => this.setState({ error: false })}
        />
        {this.props.editpage ? (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0px 10px",
              }}
            >
              <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
                <p
                  style={{
                    margin: 0,
                    marginLeft: 5,
                    fontSize: 17,
                    fontWeight: 600,
                    color: "#606060",
                    textAlign: "center",
                    maxWidth: "180px",
                    textTransform: "capitalize",
                  }}
                >
                  {this.props.name}
                </p>
                <div onClick={() => this.props.handleEditClick()}>
                  <img
                    src="/Image/Profile/edit.svg"
                    style={{ height: 15, cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: 1,
                padding: "0px 10px",
                mb: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                {this.props.address || this.props.location ? (
                  <img
                    src="/Image/DashImage/RightSidebar/connect_icon.svg"
                    alt="location icon"
                    style={{
                      width: "9px",
                      filter: "grayscale(100%)",
                      marginRight: 5,
                    }}
                  />
                ) : (
                  ""
                )}

                <p
                  className={styles.section_desc}
                  style={{
                    fontSize: 14,
                    textAlign: "center",
                  }}
                >
                  {this.props.address ||
                    JSON.parse(localStorage.getItem("address"))}
                </p>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                {this.props.userType === "INDIVIDUAL" ? (
                  <p
                    className={styles.section_desc}
                    style={{
                      fontSize: 14,
                      textAlign: "center",
                    }}
                  >
                    Nick Name - {this.props.location}
                  </p>
                ) : (
                  <>
                    <img
                      src="/Image/Business Profile/phone.svg"
                      alt="phone icon"
                      style={{
                        width: "9px",
                        filter: "grayscale(100%)",
                        marginRight: 5,
                      }}
                    />
                    <p
                      className={styles.section_desc}
                      style={{
                        fontSize: 14,
                        textAlign: "center",
                        color: "#4773b9",
                      }}
                    >
                      {this.props.phone}
                    </p>
                  </>
                )}
              </Box>
            </Box>

            {this.props.userType === "INDIVIDUAL" && (
              <ProfileTypeToggle
                profile_type={this.state.profile_type}
                SwitchProfileType={this.SwitchProfileType}
              />
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                className={styles.section_desc}
                style={{
                  textAlign: "center",
                }}
              >
                {this.props.designation}
                {this.props.designation != "" && this.props.organisation != ""
                  ? " | "
                  : ""}

                {this.props.organisation}
              </p>

              <p
                style={{
                  fontWeight: 600,
                  fontSize: 14,
                  margin: 0,
                  color: "#F33070",
                  cursor: "pointer",
                  marginTop: 8,
                }}
                onClick={() => {
                  this.handleShowShortBio(true);
                  document.body.style.overflow = "hidden";
                }}
              >
                <u>{t("profile.shortBio")}</u>
              </p>
              <p
                style={{
                  fontWeight: 600,
                  fontSize: 14,
                  margin: 0,
                  color: "#F33070",
                  cursor: "pointer",
                  marginTop: 0,
                }}
                onClick={() => {
                  this.changeEdit(true);
                  document.body.style.overflow = "hidden";
                }}
              >
                <u>{t("profile.contactInfo")}</u>
              </p>

              {this.props.connections.length == 0 ? (
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    color: "var(--secondary-main)",
                    margin: 5,
                  }}
                ></p>
              ) : this.props.connections.length > 100 ? (
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    color: "var(--secondary-main)",
                    margin: 5,
                  }}
                >
                  100+ {t("common.connections")}
                </p>
              ) : (
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    color: "var(--secondary-main)",
                    margin: 5,
                  }}
                >
                  {this.props.connections.length} {t("common.connections")}
                </p>
              )}

              {/* {this.props?.user?.user_type === "LOCAL BUSINESS" ? (
                <p
                  style={{
                    fontWeight: 600,
                    fontSize: 14,
                    margin: 0,
                    color: "var(--secondary-main)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open("https://ads-creation.nxnearby.com/", "_blank");
                  }}
                >
                  <u>{t("profile.createAd")}</u>
                </p>
              ) : null} */}
            </div>

            {this.state.showTemlates ? (
              <ChooseTemplates
                changeShowTemplatesState={this.changeShowTemplatesState.bind(
                  this
                )}
                mobileView={this.props.mobileView}
              />
            ) : null}

            {this.state.editcontact ? (
              <Modal
                card={
                  <ContactModal
                    editpage={true}
                    profileLink={this.state.profileLink}
                    phone={this.state.phone}
                    address={this.state.address}
                    email={this.state.email}
                    dob={this.state.dob}
                    changeEdit={this.changeEdit.bind(this)}
                  />
                }
              />
            ) : null}

            {this.state.editShortBio ? (
              <Modal
                card={
                  <ShortBioModal
                    editpage={true}
                    profileLink={this.state.profileLink}
                    phone={this.state.phone}
                    address={this.state.address}
                    email={this.state.email}
                    dob={this.state.dob}
                    changeEdit={this.handleShowShortBio.bind(this)}
                  />
                }
              />
            ) : null}
            <ReactTooltip />
          </>
        ) : (
          ////////////////////////////////////////////////////////////// view page ////////////////////////////////////////////////////////////////////////////
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0px 10px",
              }}
            >
              <div>
                <p
                  style={{
                    margin: 0,
                    marginLeft: 5,
                    fontSize: 17,
                    fontWeight: 600,
                    color: "#606060",
                    textAlign: "center",
                    maxWidth: "180px",
                    textTransform: "capitalize",
                  }}
                >
                  {this.props.nameView}
                </p>
              </div>
            </div>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: 1,
                padding: "0px 10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                {this.props.addressView || this.props.locationView ? (
                  <img
                    src="/Image/DashImage/RightSidebar/connect_icon.svg"
                    alt="location icon"
                    style={{
                      width: "9px",
                      filter: "grayscale(100%)",
                      marginRight: 5,
                    }}
                  />
                ) : (
                  ""
                )}

                <p
                  className={styles.section_desc}
                  style={{
                    fontSize: 14,
                    textAlign: "center",
                  }}
                >
                  {this.props.addressView}
                </p>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                {this.props.userTypeView === "INDIVIDUAL" ? (
                  <p
                    className={styles.section_desc}
                    style={{
                      fontSize: 14,
                      textAlign: "center",
                    }}
                  >
                    Nick Name - {this.props.locationView}
                  </p>
                ) : (
                  <>
                    <img
                      src="/Image/Business Profile/phone.svg"
                      alt="phone icon"
                      style={{
                        width: "9px",
                        filter: "grayscale(100%)",
                        marginRight: 5,
                      }}
                    />
                    <p
                      className={styles.section_desc}
                      style={{
                        fontSize: 14,
                        textAlign: "center",
                        color: "#4773b9",
                      }}
                    >
                      {this.props.phoneView}
                    </p>
                  </>
                )}
              </Box>
            </Box>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 8,
              }}
            >
              <p
                className={styles.section_desc}
                style={{
                  textAlign: "center",
                }}
              >
                {this.props.designationView}
                {this.props.designationView != "" &&
                this.props.organisationView != ""
                  ? " | "
                  : ""}
                {this.props.organisationView}
              </p>

              {this.state.profile_type === "PUBLIC" && (
                <p
                  style={{
                    fontWeight: 600,
                    fontSize: 14,
                    margin: 0,
                    color: "#F33070",
                    cursor: "pointer",
                    marginTop: 8,
                  }}
                  onClick={() => {
                    this.changeEdit(true);
                    document.body.style.overflow = "hidden";
                  }}
                >
                  <u>{t("profile.contactInfo")}</u>
                </p>
              )}

              {this.props.connectionsView.length == 0 ? (
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    color: "var(--secondary-main)",
                    margin: 5,
                  }}
                ></p>
              ) : this.props.connectionsView.length > 100 ? (
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    color: "var(--secondary-main)",
                    margin: 5,
                  }}
                >
                  100+ {t("common.connections")}
                </p>
              ) : (
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: 600,
                    color: "var(--secondary-main)",
                    margin: 5,
                  }}
                >
                  {this.props.connectionsView.length} {t("common.connections")}
                </p>
              )}
            </div>

            {this.state.editcontact ? (
              <Modal
                card={
                  <ContactModal
                    editpage={false}
                    profileLink={this.state.profileLink}
                    phone={this.state.phone}
                    address={this.state.address}
                    email={this.state.email}
                    dob={this.state.dob}
                    changeEdit={this.changeEdit.bind(this)}
                  />
                }
              />
            ) : null}
            <ReactTooltip />
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    user: state?.profile?.user || "",
    name: state?.profile?.name || "",
    dob: state?.profile?.dob || "dd/mm/yyyy",
    organisation: state?.profile?.organisation || "",
    designation: state?.profile?.designation || "",
    location: state?.profile?.location || "",
    connections: state?.profile?.connections || 0,
    phone: state?.profile?.contact_no,
    address: state?.profile?.address || "",
    nameView: state?.profile?.nameView || "",
    dobView: state?.profile?.dobView || "dd/mm/yyyy",
    organisationView: state?.profile?.organisationView || "",
    designationView: state?.profile?.designationView || "",
    locationView: state?.profile?.locationView || "",
    connectionsView: state?.profile?.connectionsView || 0,
    phoneView: state?.profile?.contact_no_view,
    addressView: state?.profile?.addressView || "",
    short_bio_view: state?.profile?.short_bio_view || "",
    profile_type: state?.profile?.profile_type || "PUBLIC",
    userType: state?.profile?.user?.user_type,
    userTypeView: state?.profile?.user_type_view,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    updateProfileData: (action, data) =>
      dispatch(updateProfileData(action, data)),
  };
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(InfoCard)
);
