import React, { useEffect, useState } from "react";
import { ModalWithHeader } from "../shared/ui/Modal/ModalNew";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Tooltip,
  Typography,
} from "@mui/material";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import { mapStyle } from "../../../components/MapStyles";
import styles from "./LocationModal.module.css";
import axios from "axios";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocationOffIcon from "@mui/icons-material/LocationOff";

const GoogleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const LocationModal = ({
  show,
  closeModal,
  method,
  coordinates,
  setCoordinates,
}) => {
  const [address, setAddress] = useState(
    JSON.parse(localStorage.getItem("address")) ?? ""
  );
  const [locationLoading, setLocationLoading] = useState(false);
  const [isLocationPermission, setIsLocationPermission] = useState(true);

  const setAddressOnLocalStorageChange = () => {
    setAddress(JSON.parse(localStorage.getItem("address")) ?? "");
    setCoordinates({
      lat: localStorage.getItem("lat") ?? 0,
      lng: localStorage.getItem("lng") ?? 0,
    });
  };

  useEffect(() => {
    window.addEventListener(
      "getLocationFromLocalStorage",
      setAddressOnLocalStorageChange
    );
    checkPermissionStatus();
    return () => {
      window.removeEventListener(
        "getLocationFromLocalStorage",
        setAddressOnLocalStorageChange
      );
    };
  }, []);

  const checkPermissionStatus = async () => {
    if (!navigator.permissions) {
      console.log("Permissions API not supported");
      return;
    }

    try {
      const permissionStatus = await navigator.permissions.query({
        name: "geolocation",
      });
      if (permissionStatus.state === "denied") {
        console.log("User has denied location access.");
        setIsLocationPermission(false);
      } else {
        setIsLocationPermission(true);
      }

      permissionStatus.onchange = () => {
        permissionStatus.state === "denied"
          ? setIsLocationPermission(false)
          : setIsLocationPermission(true);
      };
    } catch (error) {
      console.error("Error checking location permission:", error);
    }
  };

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const ll = await getLatLng(results[0]);
    setAddress(value);
    setCoordinates(ll);
  };

  const selectLocation = () => {
    method(address);
    closeModal();
  };

  const _mapLoaded = (mapProps, map) => {
    map.setOptions({
      styles: mapStyle,
    });
  };

  const getCurrentLocation = () => {
    setLocationLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          try {
            const res = await axios.get(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GoogleMapsApiKey}`
            );
            setAddress(res.data.results[0].formatted_address);
            setCoordinates({
              lat: latitude,
              lng: longitude,
            });
          } catch (e) {
            console.log("failed to fetch location", e);
          } finally {
            setLocationLoading(false);
          }
        },
        () => {
          console.log("Permission Denied");
          setLocationLoading(false);
        }
      );
    } else {
      setLocationLoading(false);
      console.log("Geolocatio not supported");
    }
  };

  return (
    <>
      <ModalWithHeader
        title={"Move map to pin location"}
        show={show}
        closeHandler={closeModal}
      >
        <Box
          sx={{
            background: "#fff",
            borderRadius: "0px 0px 8px 8px",
            minHeight: "65vh",
          }}
        >
          <Divider sx={{ background: "rgba(0,0,0,0.08)" }} />
          <Box sx={{ maxHeight: "40vh" }}>
            <Map
              google={google}
              zoom={16}
              style={{ width: "100%", height: "40vh" }}
              containerStyle={{ width: "100%", height: "40vh" }}
              initialCenter={{
                lat: coordinates.lat,
                lng: coordinates.lng,
              }}
              center={{
                lat: coordinates.lat,
                lng: coordinates.lng,
              }}
              onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
            >
              <Marker
                name={"Current location"}
                draggable={true}
                position={{ lat: coordinates.lat, lng: coordinates.lng }}
                icon={{
                  url: "/Image/Discover/location-icon.svg",
                  // anchor: new google.maps.Point(32, 32),
                  // scaledSize: new google.maps.Size(64, 64),
                }}
              />
            </Map>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              zIndex: 20,
              position: "relative",
              mt: "32vh",
            }}
          >
            <Button
              onClick={selectLocation}
              variant="contained"
              disableRipple
              sx={{
                display: "flex",
                flexDirection: "column",
                textTransform: "none",
                background: "#0076B6",
                color: "#fff",
                padding: { lg: "12px 45px", xs: "10px 20px" },
                borderRadius: "50px",
                width: "50%",
                minHeight: "68px",
                ":hover": {
                  background: "#0076B6",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: { lg: "17px", xs: "14px" },
                  fontWeight: 600,
                  lineHeight: "24px",
                }}
              >
                {address ? "Use this location" : "Select a location"}
              </Typography>
              <Typography
                sx={{ fontSize: "15px", fontWeight: 400, lineHeight: "20px" }}
              >
                {address && address.slice(0, 50) + "..."}
              </Typography>
            </Button>

            <PlacesAutocomplete
              value={address}
              onChange={setAddress}
              onSelect={handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div
                  key={suggestions.description}
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <div className={styles.locationSearchContainer}>
                    <SearchIcon />
                    <input
                      {...getInputProps({
                        placeholder: "Search Places ...",
                        className: styles.searchInput,
                      })}
                    />
                    <div>
                      {locationLoading ? (
                        <CircularProgress
                          size="2rem"
                          sx={{ color: "#0076b6" }}
                        />
                      ) : isLocationPermission ? (
                        <button
                          className={styles.getCurrentLocationBtn}
                          onClick={getCurrentLocation}
                        >
                          <Tooltip title="Set your current location.">
                            <LocationOnIcon sx={{ fontSize: "30px" }} />
                          </Tooltip>
                        </button>
                      ) : (
                        <Tooltip title="Cannot retrieve location. Check your browser settings.">
                          <LocationOffIcon
                            sx={{ fontSize: "30px", opacity: "0.5" }}
                          />
                        </Tooltip>
                      )}
                    </div>
                  </div>
                  <div
                    className="autocomplete-dropdown-container"
                    style={{
                      width: "70%",
                      maxHeight: "10vh",
                      overflowY: "scroll",
                    }}
                  >
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </Box>
        </Box>
      </ModalWithHeader>
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: GoogleMapsApiKey,
})(LocationModal);
