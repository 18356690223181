import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  UPDATE_EXPERIENCE,
  UPDATE_EDUCATION,
  UPDATE_LICENSES,
  UPDATE_VOLUNTEER,
  ADD_VOLUNTEER,
  UPDATE_SKILLS,
  UPDATE_INTERESTS,
  UPDATE_ACHIEVEMENTS,
  ADD_PATENT,
  UPDATE_PATENT,
  ADD_TRAININGS,
  UPDATE_TRAININGS,
  ADD_PROJECT,
  UPDATE_PROJECT,
  UPDATE_RESEARCH,
  ADD_RESEARCH,
  ADD_EXPERIENCE,
  GET_MODEL_INTERESTS,
  GET_INTERESTS_VIEW,
  GET_EXPERIENCE_VIEW,
  GET_PROFILE_PIC,
  UPDATE_PROFILE_PIC,
  GET_TAGS,
  ADD_TAGS,
  GET_ABOUT,
  UPDATE_ABOUT,
  GET_PROFILE_DATA_EDIT,
  GET_PROFILE_DATA_VIEW,
  GET_MODEL_SKILLS,
  ADD_EDUCATION,
  ADD_LICENSES,
  ADD_ACHIEVEMENTS,
  GET_COVER_PIC,
  UPDATE_COVER_PIC,
  GET_PEOPLE_YOU_MAY_KNOW,
  GET_PEOPLE_YOU_MAY_KNOW_VIEW,
  EDIT_PROFILE_DATA,
  UPDATE_SOCIAL_LINKS,
  ADD_SOCIAL_LINKS,
  FETCH_SEARCH_RESULTS,
  SENT_CONNECTIONS,
  CONNECTION_INVITATION,
  ACCPET_CONNECTION,
  MY_CONNECTIONS,
  WITHDRAW_CONNECTION,
  REMOVE_CONNECTION,
  IGNORE_CONNECTION,
  SEND_CONNECTION,
  GET_CODE_SUGGESTIONS,
  SIGNUP_STATUS,
  SEARCH_PAGINATED_RESULT,
  DELETE_USER,
} from "../constants/actionTypes";

const defaultState = {
  user: null,
  error: "",
  serachResults: "",
  enterprise_codes: [],
  nextSearch: null,
  prevSearch: null,
  profileId: "",
  about: "",
  interests: [],
  education: [],
  experience: [],
  certification: [],
  volunteer: [],
  skills: [],
  achievement: [],
  modelInterests: [],
  modelSkills: [],
  tags: [],
  userProfilePic: "",
  avatarImg: "",
  userCoverPic: "",
  peopleYouMayKnow: [],
  address: "",
  dob: "",
  name: "",
  contact_no: "",
  contact_no_view: "",
  designation: "",
  location: "",
  organisation: "",
  connections: "",
  bookmarks: "",
  search_appearance: "",
  language: "",
  socialLinks: {},
  profileId: "",
  viewSentConnections: [],
  viewConnectionInvitation: [],
  myConnections: [],
  short_bio: "",
  deptCode: "",
  profile_completion_status: "",
  viewsProfile: "",
  aboutView: "",
  interestsView: [],
  experienceView: [],
  certificationView: [],
  volunteerView: [],
  skillsView: [],
  achievementView: [],
  userProfilePicView: "",
  avatarImgView: "",
  tagsView: [],
  userCoverPicView: "",
  peopleYouMayKnowView: [],
  addressView: "",
  dobView: "",
  nameView: "",
  designationView: "",
  locationView: "",
  organisationView: "",
  connectionsView: "",
  bookmarksView: "",
  language_knownView: "",
  socialLinksView: {},
  profileIdView: "",
  user_email: "",
  connectionStateView: "",
  connection_id_view: "",
  short_bio_view: "",
  deptCodeView: "",
  profile_completion_status_view: "",
  viewsProfileView: "",
  profile_type: "",
  business_category: "",
  business_web_url: "",
  delivery: "",
  status: "",
  user_type_view: "",
  business_category_view: "",
  business_web_url_view: "",
  delivery_view: "",
  status_view: "",
  profile_rating_view: "",
  average_profile_rating: "",
  average_profile_rating_view: "",
  take_away: "",
  take_away_view: "",
  opening_hours: "",
  opening_hours_view: "",
  patents: [],
  trainings: [],
  projects: [],
  research: [],
};
let userData = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { ...state, user: {} };
    case LOGIN_SUCCESS:
      userData = {
        user: { ...state.user },
        error: { ...state.error },
      };
      userData.user = action.payload;
      userData.error = "";
      return userData;
    case LOGIN_FAILURE:
      userData = {
        user: { ...state.user },
        error: { ...state.error },
      };
      userData.user = {};
      userData.error = action.payload;
      return userData;

    case LOGOUT_REQUEST:
      userData = {
        user: {},
        error: "",
      };
      return userData;

    case SIGNUP_STATUS:
      userData = {
        user: { ...state.user },
        error: { ...state.error },
      };
      userData.status = action.payload;
      return userData;

    //profile
    case UPDATE_EXPERIENCE:
      return {
        ...state,
        experience: state.experience.map((item) =>
          action.payload.id === item.id ? action.payload : item
        ),
      };

    case GET_EXPERIENCE_VIEW:
      return {
        ...state,
        experienceView: action.payload,
      };

    case ADD_EXPERIENCE:
      return {
        ...state,
        experience: [action.payload, ...state.experience],
      };

    case UPDATE_EDUCATION:
      return {
        ...state,
        education: state.education.map((item) =>
          action.payload.id === item.id ? action.payload : item
        ),
      };

    case ADD_EDUCATION:
      return {
        ...state,
        education: [action.payload, ...state.education],
      };

    case ADD_LICENSES:
      return {
        ...state,
        certification: [action.payload, ...state.certification],
      };

    case UPDATE_LICENSES:
      return {
        ...state,
        certification: state.certification.map((item) =>
          action.payload.id === item.id ? action.payload : item
        ),
      };

    case ADD_EDUCATION:
      return {
        ...state,
        education: [action.payload, ...state.education],
      };

    case ADD_VOLUNTEER:
      return {
        ...state,
        volunteer: [action.payload, ...state.volunteer],
      };

    case UPDATE_VOLUNTEER:
      return {
        ...state,
        volunteer: state.volunteer.map((item) =>
          action.payload.id === item.id ? action.payload : item
        ),
      };

    case ADD_ACHIEVEMENTS:
      return {
        ...state,
        achievement: [action.payload, ...state.achievement],
      };

    case UPDATE_ACHIEVEMENTS:
      return {
        ...state,
        achievement: state.achievement.map((item) =>
          action.payload.id === item.id ? action.payload : item
        ),
      };

    case ADD_PATENT:
      return {
        ...state,
        patents: [action.payload, ...state.patents],
      };

    case UPDATE_PATENT:
      return {
        ...state,
        patents: state.patents.map((item) =>
          action.payload.id === item.id ? action.payload : item
        ),
      };

    case ADD_PROJECT:
      return {
        ...state,
        projects: [action.payload, ...state.projects],
      };

    case UPDATE_PROJECT:
      return {
        ...state,
        projects: state.projects.map((item) =>
          action.payload.id === item.id ? action.payload : item
        ),
      };

    case ADD_TRAININGS:
      return {
        ...state,
        trainings: [action.payload, ...state.trainings],
      };

    case UPDATE_TRAININGS:
      return {
        ...state,
        trainings: state.trainings.map((item) =>
          action.payload.id === item.id ? action.payload : item
        ),
      };

    case ADD_RESEARCH:
      return {
        ...state,
        research: [action.payload, ...state.research],
      };

    case UPDATE_RESEARCH:
      return {
        ...state,
        research: state.research.map((item) =>
          action.payload.id === item.id ? action.payload : item
        ),
      };

    case GET_MODEL_SKILLS:
      return {
        ...state,
        modelSkills: action.payload,
      };

    case UPDATE_SKILLS:
      return {
        ...state,
        skills: action.payload,
      };

    case GET_MODEL_INTERESTS:
      return {
        ...state,
        modelInterests: action.payload,
      };

    case UPDATE_INTERESTS:
      return {
        ...state,
        interests: action.payload,
      };

    case GET_INTERESTS_VIEW:
      return {
        ...state,
        interestsView: action.payload,
      };

    case UPDATE_ACHIEVEMENTS:
      userData = {
        ...state,
        achievements: action.payload,
      };
      return userData;

    case GET_TAGS:
      return {
        ...state,
        tagsView: action.payload,
      };
    case ADD_TAGS:
      userData = {
        ...state,
        tags: action.payload,
      };
      return userData;

    case GET_ABOUT:
      return {
        ...state,
        about: action.payload,
      };

    case UPDATE_ABOUT:
      userData = {
        ...state,
        about: action.payload,
      };
      return userData;

    case GET_PROFILE_PIC:
      return {
        ...state,
        userProfilePic: action.payload,
      };

    case UPDATE_PROFILE_PIC:
      userData = {
        ...state,
        userProfilePic: action.payload,
      };
      return userData;

    case UPDATE_SOCIAL_LINKS:
      return {
        ...state,
        socialLinks: action.payload,
      };
    case ADD_SOCIAL_LINKS:
      return {
        ...state,
        socialLinks: action.payload,
      };

    case GET_PROFILE_DATA_EDIT:
      return {
        ...state,
        profileId: action.payload.id,
        about: action.payload.about,
        interests: action.payload.interests,
        education: action.payload.education,
        experience: action.payload.experience,
        certification: action.payload.certification,
        volunteer: action.payload.volunteer,
        skills: action.payload.skills,
        achievement: action.payload.achievement,
        tags: action.payload.tags,
        language: action.payload.language_known,
        userCoverPic: action.payload.cover_pic,
        userProfilePic: action.payload.profile_pic,
        avatarImg: action.payload.avatarImg,
        address: action.payload.address,
        dob: action.payload.dob,
        name: action.payload.name,
        contact_no: action.payload.contact_no,
        designation: action.payload.designation,
        location: action.payload.location,
        organisation: action.payload.organisation,
        connections: action.payload.connections,
        bookmarks: action.payload.bookmarks,
        socialLinks: action.payload.social_links,
        profileId: action.payload.id,
        short_bio: action.payload.short_bio,
        patents: action.payload.patents,
        research: action.payload.research,
        trainings: action.payload.trainings,
        projects: action.payload.projects,
        deptCode: action.payload.dept_code,
        profile_completion_status: action.payload.profile_completion_status,
        viewsProfile: action.payload.views,
        profile_type: action.payload.profile_type,
        business_category: action.payload.business_category,
        business_web_url: action.payload.business_offering.business_web_url,
        delivery: action.payload.business_offering.delivery,
        status: action.payload.business_offering.status,
        average_profile_rating: action.payload.average_profile_rating,
        take_away: action.payload.business_offering.take_away,
        opening_hours: action.payload.business_offering.opening_hours,
      };

    case GET_PROFILE_DATA_VIEW:
      return {
        ...state,
        profileIdView: action.payload.id,
        aboutView: action.payload.about,
        interestsView: action.payload.interests,
        educationView: action.payload.education,
        experienceView: action.payload.experience,
        certificationView: action.payload.certification,
        volunteerView: action.payload.volunteer,
        skillsView: action.payload.skills,
        achievementView: action.payload.achievement,
        tagsView: action.payload.tags,
        language_knownView: action.payload.language_known,
        userCoverPicView: action.payload.cover_pic,
        userProfilePicView: action.payload.profile_pic,
        avatarImgView: action.payload.avatarImg,
        addressView: action.payload.address,
        dobView: action.payload.dob,
        nameView: action.payload.name,
        designationView: action.payload.designation,
        locationView: action.payload.location,
        organisationView: action.payload.organisation,
        connectionsView: action.payload.connections,
        bookmarksView: action.payload.bookmarks,
        socialLinksView: action.payload.social_links,
        profileIdView: action.payload.id,
        user_email: action.payload.user_email,
        connectionStateView: action.payload.connection_state,
        connection_id_view: action.payload.connection_id,
        short_bio_view: action.payload.short_bio,
        deptCodeView: action.payload.dept_code,
        profile_completion_status_view:
          action.payload.profile_completion_status,
        viewsProfileView: action.payload.views,
        profile_type: action.payload.profile_type,
        business_category_view: action.payload.business_category,
        user_type_view: action.payload.user_details[0].user_type,
        business_web_url_view:
          action.payload.business_offering.business_web_url,
        delivery_view: action.payload.business_offering.delivery,
        status_view: action.payload.business_offering.status,
        profile_rating_view: action.payload.profile_rating,
        average_profile_rating_view: action.payload.average_profile_rating,
        take_away_view: action.payload.business_offering.take_away,
        opening_hours_view: action.payload.business_offering.opening_hours,
        contact_no_view: action.payload.contact_no,
      };

    case EDIT_PROFILE_DATA:
      userData = {
        ...state,
        about: action.payload?.about || state?.about,
        interests: action.payload?.interests || state?.interests,
        education: action.payload?.education || state?.education,
        experience: action.payload?.experience || state?.experience,
        certification: action.payload?.certification || state?.certification,
        volunteer: action.payload?.volunteer || state?.volunteer,
        skills: action.payload?.skills || state?.skills,
        achievement: action.payload?.achievement || state?.achievement,
        tags: action.payload?.tags || state?.tags,
        userCoverPic: action.payload?.cover_pic || state?.userCoverPic,
        userProfilePic: action.payload?.profile_pic || state?.userProfilePic,
        avatarImg: action.payload?.avatarImg || state?.avatarImg,
        address: action.payload?.address || state?.address,
        dob: action.payload?.dob || state?.dob,
        name: action.payload?.name || state?.name,
        contact_no: action.payload?.contact_no || state?.contact_no,
        designation: action.payload?.designation || state?.designation,
        location: action.payload?.location || state?.location,
        organisation: action.payload?.organisation || state?.organisation,
        connections: action.payload?.connections || state?.connections,
        bookmarks: action.payload?.bookmarks || state?.bookmarks,
        language: action.payload?.language_known || state?.language,
        short_bio: action.payload?.short_bio || state?.short_bio,
        deptCode: action.payload?.dept_code || state?.deptCode,
        profile_completion_status:
          action.payload?.profile_completion_status ||
          state?.profile_completion_status,
        profile_type: action.payload?.profile_type || state?.profile_type,
        business_category:
          action.payload?.business_category || state?.business_category,
        business_web_url:
          action.payload?.business_web_url || state?.business_web_url,
        delivery: action.payload?.delivery || state?.delivery,
        status: action.payload?.status || state?.status,
        take_away: action.payload?.take_away || state?.take_away,
        opening_hours: action.payload?.opening_hours || state?.opening_hours,
      };
      return userData;

    case GET_COVER_PIC:
      return {
        ...state,
        userCoverPic: action.payload,
      };

    case UPDATE_COVER_PIC:
      userData = {
        ...state,
        userCoverPic: action.payload,
      };
      return userData;

    case GET_PEOPLE_YOU_MAY_KNOW:
      return {
        ...state,
        peopleYouMayKnow: action.payload,
      };

    case GET_PEOPLE_YOU_MAY_KNOW_VIEW:
      return {
        ...state,
        peopleYouMayKnowView: action.payload,
      };

    case SEARCH_PAGINATED_RESULT:
      const finalArr =
        state.serachResults != undefined
          ? state.serachResults.concat(action.payload.data)
          : action.payload.data;
      return {
        ...state,
        serachResults: finalArr,
        nextSearch: action.payload.nextSearch,
        prevSearch: action.payload.prevSearch,
      };

    case FETCH_SEARCH_RESULTS:
      return {
        ...state,
        serachResults: action.payload.data,
        nextSearch: action.payload.nextSearch,
        prevSearch: action.payload.prevSearch,
      };

    // connections

    case SENT_CONNECTIONS:
      return {
        ...state,
        viewSentConnections: action.payload,
      };

    case CONNECTION_INVITATION:
      return {
        ...state,
        viewConnectionInvitation: action.payload,
      };

    case ACCPET_CONNECTION:
      userData = {
        ...state,
        viewConnectionInvitation: action.payload,
        connectionStateView: action.payload.connectionState,
      };
      return userData;

    case MY_CONNECTIONS:
      return {
        ...state,
        myConnections: action.payload,
      };

    case WITHDRAW_CONNECTION:
      userData = {
        ...state,
        viewSentConnections: action.payload,
      };
      return userData;

    case REMOVE_CONNECTION:
      userData = {
        ...state,
        myConnections: action.payload,
      };
      return userData;

    case IGNORE_CONNECTION:
      userData = {
        ...state,
        viewConnectionInvitation: action.payload,
      };
      return userData;

    case SEND_CONNECTION:
      userData = {
        ...state,
        connectionStateView: action.payload.connectionState,
      };
      return userData;

    case GET_CODE_SUGGESTIONS:
      userData = {
        ...state,
        enterprise_codes: action.payload,
      };
      return userData;

    case DELETE_USER:
      userData = {
        user: {},
        error: "",
      };
      return userData;

    default:
      return { ...state };
  }
};
