import React, { useState } from "react";
import Modal from "../../../../../components/commons/Modal";
import styles from "../../../Profile.module.css";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@mui/material";
import EditTrainingAndConferences from "./Modals/EditTrainingAndConferences";

const TrainingAndConferencesFeed = () => {
  const { t } = useTranslation();
  const trainingsData = useSelector((state) => state.profile?.trainings);

  const [modalState, setModalState] = useState({
    edit: false,
    modalTitle: "",
    id: null,
    image: "",
    credential_url: "",
    organization_name: "",
    title: "",
    description: "",
    issue_date: null,
    add: false,
  });

  const [seeall, setSeeall] = useState(false);
  const [endIdx, setEndIdx] = useState(2);

  const changeEdit = () => {
    setModalState((prev) => ({ ...prev, edit: false }));
  };

  const handleAddExperience = () => {
    const newModalTitle = t("profile.training&Conferences");

    setModalState({
      modalTitle: newModalTitle,
      edit: true,
      add: true,
      id: null,
      image: "",
      credential_url: "",
      organization_name: "",
      title: "",
      description: "",
    });

    document.body.style.overflow = "hidden";
  };

  const handleEditExperience = (item) => {
    const newModalTitle = t("profile.training&ConferencesTitle");

    setModalState({
      modalTitle: newModalTitle,
      edit: true,
      add: false,
      id: item.id,
      image: item.image,
      credential_url: item.credential_url,
      organization_name: item.organization_name,
      title: item.title,
      description: item.description,
    });

    document.body.style.overflow = "hidden";
  };

  const toggleSeeAll = () => {
    if (endIdx > 2) {
      setEndIdx(2);
    } else {
      setEndIdx(trainingsData?.length);
    }
    setSeeall(!seeall);
  };

  return (
    <div>
      <Box sx={{ display: "flex", gap: 2, mb: 1.5 }}>
        <img
          src="/Image/Profile/trainingconference.svg"
          alt="about"
          style={{ width: "25px" }}
        />
        <Typography
          sx={{ color: "#606262", fontSize: "18px", fontWeight: 600 }}
        >
          {t("profile.training&ConferencesTitle")}
        </Typography>
      </Box>

      <div
        className={styles.desc}
        style={{
          boxShadow: "0px 0px 20px #5555551a",
          padding: 10,
          borderRadius: 10,
        }}
      >
        <div
          style={{
            padding: 10,
          }}
        >
          <p className={styles.edit_info}>
            {t("profile.training&Conferences")}
          </p>
          <Button
            disableRipple
            sx={{
              background: "#F33070",
              padding: "6px 20px",
              borderRadius: 40,
              cursor: "pointer",
              ":hover": {
                background: "#F33070",
              },
            }}
            onClick={handleAddExperience}
          >
            <p className={styles.btn_text} style={{ color: "#fff" }}>
              + {t("profile.training&Conferences")}
            </p>
          </Button>
        </div>
        <div
          style={{
            paddingTop: 20,
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          {trainingsData?.slice(0, endIdx).map((item, idx) => {
            return (
              <div
                key={idx}
                style={{
                  borderBottom:
                    trainingsData?.length === 1 ||
                    (trainingsData?.length === 2 && idx === 1)
                      ? "none"
                      : "1px solid #5555551A",
                  paddingBottom: 15,
                  marginTop: idx === 0 ? 0 : 20,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div>
                    <img
                      src={
                        item.image ||
                        "/Image/apps/profile-resume-edit-view/DefaultFeed.svg"
                      }
                      height="50px"
                      width="50px"
                      style={{ borderRadius: 40, marginRight: 15 }}
                      alt="training"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <p className={styles.section_title}>
                          {item.title}
                          <br />
                          <Typography
                            sx={{
                              color: "#606262",
                              fontSize: "14px",
                              opacity: 0.8,
                            }}
                          >
                            {item.organization_name}
                            {/* dont remove this when issue data will come need to show this */}
                            {/* • {item.issue_date} */}
                          </Typography>
                        </p>
                      </div>
                    </div>
                    {item.credential_url && (
                      <a
                        href={item.credential_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button
                          variant="outlined"
                          sx={{
                            fontSize: "12px",
                            textTransform: "none",
                            marginTop: "5px",
                            borderRadius: "20px",
                            color: "#606262",
                            borderColor: "#606262",
                            "&:hover": { borderColor: "#555" },
                          }}
                        >
                          {t("profile.showCredentials")} 🔗
                        </Button>
                      </a>
                    )}
                    <Typography
                      sx={{
                        color: "#606262",
                        fontSize: "14px",
                        marginTop: "5px",
                      }}
                    >
                      {item.description}
                    </Typography>
                  </div>
                </div>
                <div>
                  <img
                    src="/Image/Profile/edit.svg"
                    style={{
                      height: 20,
                      cursor: "pointer",
                    }}
                    onClick={() => handleEditExperience(item)}
                    alt="edit"
                  />
                </div>
              </div>
            );
          })}
        </div>
        {Array.isArray(trainingsData) && trainingsData?.length <= 2 && (
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: 10,
            }}
            onClick={toggleSeeAll}
          >
            <button
              style={{
                fontSize: 12,
                fontWeight: 600,
                color: "#555555",
                margin: 10,
                border: "none",
                background: "none",
                cursor: "pointer",
              }}
            >
              {trainingsData?.length <= 2
                ? null
                : seeall
                ? t("common.collapse")
                : t("common.seeAll")}
            </button>
          </div>
        )}
      </div>

      {modalState.edit && (
        <Modal
          card={
            <EditTrainingAndConferences
              id={modalState.id}
              modalTitle={modalState.modalTitle}
              credential_url={modalState.credential_url}
              organization_name={modalState.organization_name}
              title={modalState.title}
              description={modalState.description}
              image={modalState.image}
              add={modalState.add}
              changeEdit={changeEdit}
            />
          }
        />
      )}
    </div>
  );
};

export default TrainingAndConferencesFeed;
