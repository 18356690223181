import React, { useState, useEffect, useRef } from "react";
import { Button, Paper, Typography } from "@mui/material";
import "./CSS/MapPage.css";
import { Box } from "@material-ui/core";
import PostModal from "../Dashboard/postModal/PostModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchPeopleYouMayKnow } from "../../redux/actions/peopleyoumayknow";
import { viewMyConnections } from "../../redux/actions/connections";
import { GET_PEOPLE_YOU_MAY_KNOW } from "../../redux/constants/actionTypes";
import { MY_CONNECTIONS } from "../../redux/constants/actionTypes";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import StorefrontRoundedIcon from "@mui/icons-material/StorefrontRounded";
import EventAvailableRoundedIcon from "@mui/icons-material/EventAvailableRounded";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import CategoryContent from "./CategoryContent";
import SwipeableEdgeDrawer from "./SwipeableEdgeDrawer";
import { Map, Marker, GoogleApiWrapper, InfoWindow } from "google-maps-react";
import { mapStyle } from "../../components/MapStyles";
import { businessList, eventList, saleList } from "../../template/DummyData";
import { DiscoverContext, DiscoverProvider } from "./DiscoverContext";
import { useContext } from "react";

const GoogleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const buttonsList = [
  {
    icon: ExploreOutlinedIcon,
    color1: "#000",
    color2: "#fff",
    text: "Latest",
    value: "latest",
  },
  {
    icon: StorefrontRoundedIcon,
    color1: "#C24A00",
    color2: "#FFE3B7",
    text: "Businesses",
    value: "business",
  },
  {
    icon: EventAvailableRoundedIcon,
    color1: "#9B00CB",
    color2: "#F6DCFF",
    text: "Events",
    value: "events",
  },
  {
    icon: LocalOfferRoundedIcon,
    color1: "#007A85",
    color2: "#DFF0F1",
    text: "For Sale",
    value: "sale",
  },
];

const MapPage = () => {
  const [lat, setLat] = useState(localStorage.getItem("lat"));
  const [lng, setLng] = useState(localStorage.getItem("lng"));
  const [error, setError] = useState(null);
  const [places, setPlaces] = useState([{}, {}, {}, {}, {}, {}, {}]);
  const [activeCategory, setActiveCategory] = useState("latest");
  const { state } = useContext(DiscoverContext);
  const dispatch = useDispatch();
  const [infoWindowDeatils, setInfoWindowDeatils] = useState({
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
    localBusiness: null,
  });

  const setLocationOnLocalStorageChange = () => {
    setLat(localStorage.getItem("lat"));
    setLng(localStorage.getItem("lng"));
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("userData")).token;

    dispatch(fetchPeopleYouMayKnow(GET_PEOPLE_YOU_MAY_KNOW, token)).then(
      (res) => {
        if (!res.val) {
          setError(res.message);
        }
      }
    );

    dispatch(viewMyConnections(MY_CONNECTIONS, token)).then((res) => {
      if (!res.val) {
        setError(res.message);
      }
    });

    window.addEventListener(
      "getLocationFromLocalStorage",
      setLocationOnLocalStorageChange
    );

    return () => {
      window.removeEventListener(
        "getLocationFromLocalStorage",
        setLocationOnLocalStorageChange
      );
    };
  }, []);

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };

  const _mapLoaded = (mapProps, map) => {
    map.setOptions({
      styles: mapStyle,
    });
  };

  const onMarkerClick = (props, marker, e, user_type) => {
    console.log(props);
    console.log(e);
    setInfoWindowDeatils({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
      localBusiness: user_type === "LOCAL BUSINESS" ? "Business" : null,
    });
  };

  const onMapClicked = (props) => {
    if (infoWindowDeatils.showingInfoWindow) {
      setInfoWindowDeatils({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  return (
    <div className="screen">
      <div className="map-container">
        <Map
          onClick={onMapClicked}
          google={google}
          zoom={14}
          style={{ width: "100%", height: "100%" }}
          containerStyle={{ width: "100%", height: "100vh" }}
          initialCenter={{
            lat: lat,
            lng: lng,
          }}
          center={{
            lat: lat,
            lng: lng,
          }}
          onReady={(mapProps, map) => _mapLoaded(mapProps, map)}
        >
          {(activeCategory === "business" || activeCategory === "latest") &&
            state?.localBusiness?.map((busi) => (
              <Marker
                onClick={(props, marker, e) => onMarkerClick(props, marker, e)}
                title={busi?.location}
                name={busi?.enterprise_name}
                draggable={false}
                position={{ lat: busi?.latitude, lng: busi?.longitude }}
                icon={{
                  url: "/Image/Discover/Map/business.png",
                  // anchor: new google.maps.Point(32, 32),
                  scaledSize: new google.maps.Size(40, 40),
                }}
              />
            ))}
          {(activeCategory === "events" || activeCategory === "latest") &&
            state?.eventPosts?.map((event) => (
              <Marker
                onClick={(props, marker, e) =>
                  onMarkerClick(
                    props,
                    marker,
                    e,
                    event?.post_owner_profile?.user_details?.user_type
                  )
                }
                title={event?.post_details?.address}
                name={event?.post_details?.name}
                draggable={false}
                position={{
                  lat: event?.post_details?.latitude,
                  lng: event?.post_details?.longitude,
                }}
                icon={{
                  url: "/Image/Discover/Map/event.png",
                  // anchor: new google.maps.Point(32, 32),
                  scaledSize: new google.maps.Size(40, 40),
                }}
              />
            ))}
          {(activeCategory === "sale" || activeCategory === "latest") &&
            state?.sellPosts?.map((sale) => (
              <Marker
                onClick={(props, marker, e) =>
                  onMarkerClick(
                    props,
                    marker,
                    e,
                    sale?.post_owner_profile?.user_details?.user_type
                  )
                }
                title={sale?.post_details?.location}
                name={sale?.post_details?.title}
                draggable={false}
                position={{
                  lat: sale?.post_details?.latitude,
                  lng: sale?.post_details?.longitude,
                }}
                icon={{
                  url: "/Image/Discover/Map/sale.png",
                  // anchor: new google.maps.Point(32, 32),
                  scaledSize: new google.maps.Size(40, 40),
                }}
              />
            ))}
          <InfoWindow
            marker={infoWindowDeatils?.activeMarker}
            visible={infoWindowDeatils?.showingInfoWindow}
          >
            <div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <h3 style={{ color: "#f8316d" }}>
                  {infoWindowDeatils?.selectedPlace?.name}
                </h3>
                {infoWindowDeatils?.localBusiness && (
                  <div
                    style={{
                      background: "#ffd221",
                      color: "#1f2153",
                      padding: "0.5px 10px",
                      borderRadius: "17px",
                      fontSize: "12px",
                      fontWeight: 800,
                      fontStyle: "italic",
                    }}
                  >
                    Business
                  </div>
                )}
              </div>
              <h5>{infoWindowDeatils?.selectedPlace?.title}</h5>
            </div>
          </InfoWindow>
        </Map>

        <Box
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "block",
              lg: "block",
              xl: "block",
            },
          }}
        >
          <Paper
            elevation={3}
            sx={{
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              padding: "20px",
              position: "absolute",
              marginTop: "25vh",
              left: "20px",
              width: "320px",
              maxHeight: { lg: "60vh", md: "55vh" },
              zIndex: 50,
              borderRadius: 4,
              overflow: "auto",
            }}
          >
            <CategoryContent activeCategory={activeCategory} places={places} />
          </Paper>
        </Box>

        {/* <Box
          sx={{
            display: {
              xs: "block",
              sm: "block",
              md: "none",
              lg: "none",
              xl: "none",
            },
          }}
        >
          <SwipeableEdgeDrawer />
        </Box> */}

        <Box
          sx={{
            position: "absolute",
            zIndex: 50,
            top: 94.5,
            display: "flex",
            justifyContent: { sm: "center", md: "space-between" },
            width: "100%",
            backgroundColor: "#fff",
            py: 1,
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "22px",
                lineHeight: "28px",
                fontWeight: 600,
                ml: 5,
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                  xl: "block",
                },
              }}
            >
              Discover local favourites
            </Typography>
          </Box>
          <Box
            sx={{
              mr: { xs: 0, lg: 20 },
              display: "flex",
              overflow: { xs: "scroll", sm: "hidden", lg: "hidden" },
            }}
          >
            {buttonsList.map((btn, idx) => (
              <Button
                key={idx}
                disableRipple
                startIcon={
                  <btn.icon
                    sx={{
                      color:
                        activeCategory === btn.value ? btn.color2 : btn.color1,
                    }}
                  />
                }
                onClick={() => handleCategoryClick(btn.value)}
                sx={{
                  color: activeCategory === btn.value ? btn.color2 : btn.color1,
                  textTransform: "capitalize",
                  border: "1px solid rgba(0,0,0,0.08)",
                  borderRadius: "25px",
                  px: { xs: 2, lg: 2 },
                  background:
                    activeCategory === btn.value ? btn.color1 : "none",
                  ":hover": {
                    background:
                      activeCategory === btn.value ? btn.color1 : "none",
                  },
                  mr: 1,
                  fontSize: { xs: "12px", lg: "16px" },
                  py: { xs: 0.5, lg: 0.5 },
                }}
              >
                {btn.text}
              </Button>
            ))}
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: GoogleMapsApiKey,
})(MapPage);
