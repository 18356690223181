import { React, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { loginUser, signUpUser } from "../../../redux/actions/login";
import "../Sign.css";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import SignNav from "../../home/Nav/SignNav";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import OtpInput from "react-otp-input";
import { setDefaultLocation } from "../../../utils";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Signup(
  { user, error, loginUser, signUpUser, status, errorMessage },
  props
) {
  const { t } = useTranslation();
  const history = useHistory();
  const GoogleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const horizontal = "center";
  const vertical = "top";
  const langauage = localStorage.getItem("i18nextLng");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userId, setUserId] = useState("");
  const [category, setCategory] = useState("");
  const [verifyOTP, setVerifyOTP] = useState(false);
  const [OTP, setOTP] = useState("");
  const [otpError, setOtpError] = useState({
    required: false,
    validate: false,
  });
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(30);

  const userRef = useRef(user);
  userRef.current = user;
  const errorRef = useRef(error);
  errorRef.current = error;
  const statusRef = useRef(status);
  statusRef.current = status;
  const errorsRef = useRef(errorMessage);
  errorsRef.current = errorMessage;

  useEffect(() => {
    if (verifyOTP) {
      const timer =
        counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [counter, verifyOTP]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      phone: "",
      user_type: "",
      password: "",
      confirm_password: "",
      first_name: "",
      last_name: "",
      enterprise_name: "",
      latitude: "",
      longitude: "",
      location: "",
    },
  });

  useEffect(() => {
    const success = async (position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      try {
        const res = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GoogleMapsApiKey}`
        );

        localStorage.setItem("lat", JSON.stringify(latitude));
        localStorage.setItem("lng", JSON.stringify(longitude));
        localStorage.setItem(
          "address",
          JSON.stringify(res.data.results[0].formatted_address)
        );
        // localStorage.setItem(
        //   "shortAddress",
        //   JSON.stringify(res.data.results[0].address_components[3])
        // );
      } catch {
        console.log("failed to fetch location");
        setDefaultLocation();
      }
    };

    const error = () => {
      console.log("Unable to retrieve your location");
      setDefaultLocation();
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log("Geolocation not supported");
      setDefaultLocation();
    }
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  function onSubmit(data) {
    data["user_type"] = category;
    data["latitude"] = localStorage.getItem("lat");
    data["longitude"] = localStorage.getItem("lng");
    data["location"] = localStorage.getItem("address");
    delete data["confirm_password"];

    setEmail(data.email.trim());
    setPassword(data.password);
    setLoading(true);

    const onSuccess = (res) => {
      if (res?.data.is_active === false) {
        setMessage("OTP has been sent to your email");
        setSeverity("success");
        setOpen(true);
        setLoading(false);
        setVerifyOTP(true);
        setUserId(res.data.id);
      } else {
        const data = {
          username: data.email,
          password: data.password,
        };
        const onSuccess = (res) => {
          setMessage("Account Created Successfuly");
          setSeverity("success");
          setOpen(true);
          setLoading(false);
          history.push(`/user`);
        };
        const onError = (error) => {
          console.log(error.response);
          setMessage(
            error.response?.data?.detail || "Something went wrong during login"
          );
          setSeverity("error");
          setOpen(true);
          setLoading(false);
        };
        loginUser(JSON.stringify(data), onSuccess, onError);
      }
    };
    const onError = (error) => {
      const errorMessage =
        error.response?.data.error === "User with this email already exists."
          ? "User with this email already exists"
          : "Something went wrong during signup";

      setLoading(false);
      setMessage(errorMessage);
      setSeverity("error");
      setOpen(true);
      if (error.response.data?.user_data.is_active === false) {
        resendOtp(error.response.data?.user_data.email);
        setVerifyOTP(true);
      }
    };

    signUpUser(data, onSuccess, onError);
  }

  const verifyOtp = () => {
    if (OTP.length === 0) {
      setOtpError({ ...otpError, required: true, validate: false });
    } else if (OTP.length < 6) {
      setOtpError({ ...otpError, required: false, validate: true });
    } else {
      setOtpError({ ...otpError, required: false, validate: false });
      setLoading(true);
      const data = {
        otp: OTP,
      };
      const verifyOtpUrl = `${process.env.REACT_APP_API_BASE_URL}api/v1/users/verifyUser/?email=${email}`;
      axios
        .post(verifyOtpUrl, data, {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            const data = {
              username: email,
              password: password,
            };
            const onSuccess = (res) => {
              setMessage("Account Created Successfuly");
              setSeverity("success");
              setOpen(true);
              setLoading(false);
              history.push(`/user`);
            };
            const onError = (error) => {
              console.log(error.response);
              setMessage(
                error.response?.data?.detail ||
                  "Something went wrong during login"
              );
              setSeverity("error");
              setOpen(true);
              setLoading(false);
            };
            loginUser(JSON.stringify(data), onSuccess, onError);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error.response);
          if (error.response.data.message === "failed") {
            setMessage("The OTP you entered is incorrect");
            setSeverity("error");
            setOpen(true);
          } else {
            setMessage("Something went wrong during OTP verification");
            setSeverity("error");
            setOpen(true);
          }
        });
    }
  };

  const resendOtp = (e_mail) => {
    const resendOtpUrl = `${
      process.env.REACT_APP_API_BASE_URL
    }api/v1/users/resendOtp/?email=${e_mail || email}`;
    axios
      .post(resendOtpUrl)
      .then((response) => {
        console.log(response);
        setMessage("OTP Resent Successfully");
        setSeverity("success");
        setOpen(true);
      })
      .catch((error) => {
        console.log(error.response.data);
        setMessage("Something went wrong during OTP resend");
        setSeverity("error");
        setOpen(true);
      });
  };

  return (
    <div className="sign-container">
      <SignNav />
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          {/* {false && ( */}
          {!category && (
            <Box className="sign-form">
              <div className="sign-heading">
                <div>{t("signup.selectCategory")}</div>
                <div>{t("signup.accountCreateDesc")}</div>
              </div>

              <Grid container spacing={2} sx={{ mt: 5, px: 4 }}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <div
                    className="category-card"
                    onClick={() => setCategory("INDIVIDUAL")}
                  >
                    <img src="Image/signImage/individual.png" alt="#" />
                    <div>{t("signup.individual")}</div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <div
                    className="category-card"
                    onClick={() => setCategory("LOCAL BUSINESS")}
                  >
                    <img src="Image/signImage/enterprise.png" alt="#" />
                    <div>{t("signup.enterprise")}</div>
                  </div>
                </Grid>
              </Grid>
            </Box>
          )}

          {/* {false && ( */}
          {category && !verifyOTP && (
            <div className={`sign-form`}>
              <div className="sign-heading">
                <div>{t("common.signup")}</div>
                <div>{t("signup.accountCreateDesc")}</div>
              </div>
              {/* --------------------------------- Individual Form-------------------------------- */}
              {category === "INDIVIDUAL" && (
                <Box
                  component="form"
                  sx={{ width: { md: "400px" } }}
                  className="sign-input"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <Box
                        className="input-and-error-container"
                        sx={{ mr: { xs: 0, md: 2 } }}
                      >
                        <input
                          className="sign-field"
                          {...register("first_name", { required: true })}
                          type="text"
                          placeholder={t("signup.firstName")}
                        />
                        <div className={`input-alert`}>
                          {errors.first_name?.type === "required" &&
                            t("errors.firstNameError")}
                        </div>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="input-and-error-container">
                        <input
                          className="sign-field"
                          {...register("last_name", { required: true })}
                          type="text"
                          placeholder={t("signup.lastName")}
                        />
                        <div className={`input-alert`}>
                          {errors.last_name?.type === "required" &&
                            t("errors.lastNameError")}
                        </div>
                      </div>
                    </Grid>
                  </Grid>

                  <input
                    className="sign-field"
                    {...register("email", {
                      required: true,
                      pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                    })}
                    type="text"
                    placeholder={t("common.email")}
                  />
                  <div className={`input-alert`}>
                    {errors.email?.type === "required" &&
                      t("errors.emailError")}
                  </div>
                  <div className={`input-alert`}>
                    {errors.email?.type === "pattern" && t("errors.validEmail")}
                  </div>

                  <input
                    className="sign-field"
                    {...register("password", { required: true, minLength: 8 })}
                    type="password"
                    placeholder={t("common.password")}
                  />
                  <div className={`input-alert`}>
                    {errors.password?.type === "required" &&
                      t("errors.passwordError")}
                  </div>
                  <div className={`input-alert`}>
                    {errors.password?.type === "minLength" &&
                      t("errors.validPasswordError")}
                  </div>
                  <input
                    className="sign-field"
                    {...register("confirm_password", {
                      required: true,
                      validate: (val) => {
                        if (watch("password") != val) {
                          return "Your passwords do no match";
                        }
                      },
                    })}
                    type="password"
                    placeholder={t("signup.confirmPassword")}
                  />
                  <div className={`input-alert`}>
                    {errors.confirm_password?.type === "required" &&
                      t("errors.confirmPasswordError")}
                  </div>
                  <div className={`input-alert`}>
                    {errors.confirm_password?.type === "validate" &&
                      t("errors.validConfirmPasswordError")}
                  </div>

                  <button className="button sign-btn" type="submit">
                    {loading ? (
                      <CircularProgress size={20} style={{ color: "white" }} />
                    ) : (
                      t("signup.createAccount")
                    )}
                  </button>
                  <div className="or">
                    <hr />
                    {t("common.or")}
                    <hr />
                  </div>
                  <div className="account-already">
                    {t("signup.alreadyAccount")}{" "}
                    <span className="sign-link">
                      <Link to="signin">{t("common.signIn")}</Link>
                    </span>
                  </div>
                </Box>
              )}
              {/* ---------------------------------LOCAL BUSINESS Form--------------------------------  */}
              {category === "LOCAL BUSINESS" && (
                <Box
                  component="form"
                  sx={{ width: { xs: "300px", md: "450px" } }}
                  className="sign-input"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <input
                    className="sign-field"
                    {...register("enterprise_name", { required: true })}
                    type="text"
                    placeholder={t("signup.uniName")}
                  />
                  <div className={`input-alert`}>
                    {errors.enterprise_name?.type === "required" &&
                      t("errors.enterpriseNameError")}
                  </div>

                  <input
                    className="sign-field"
                    {...register("email", {
                      required: true,
                      pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
                    })}
                    type="text"
                    placeholder={t("common.email")}
                  />
                  <div className={`input-alert`}>
                    {errors.email?.type === "required" &&
                      t("errors.emailError")}
                  </div>
                  <div className={`input-alert`}>
                    {errors.email?.type === "pattern" && t("errors.validEmail")}
                  </div>

                  <input
                    className="sign-field"
                    {...register("password", { required: true, minLength: 8 })}
                    type="password"
                    placeholder={t("common.password")}
                  />
                  <div className={`input-alert`}>
                    {errors.password?.type === "required" &&
                      t("errors.passwordError")}
                  </div>
                  <div className={`input-alert`}>
                    {errors.password?.type === "minLength" &&
                      t("errors.validPasswordError")}
                  </div>
                  <input
                    className="sign-field"
                    {...register("confirm_password", {
                      required: true,
                      validate: (val) => {
                        if (watch("password") != val) {
                          return "Your passwords do no match";
                        }
                      },
                    })}
                    type="password"
                    placeholder={t("signup.confirmPassword")}
                  />
                  <div className={`input-alert`}>
                    {errors.confirm_password?.type === "required" &&
                      t("errors.confirmPasswordError")}
                  </div>
                  <div className={`input-alert`}>
                    {errors.confirm_password?.type === "validate" &&
                      t("errors.validConfirmPasswordError")}
                  </div>

                  <button className="button sign-btn" type="submit">
                    {loading ? (
                      <CircularProgress size={20} style={{ color: "white" }} />
                    ) : (
                      t("signup.createAccount")
                    )}
                  </button>
                  <div className="or">
                    <hr />
                    {t("common.or")}
                    <hr />
                  </div>
                  <div className="account-already">
                    {t("signup.alreadyAccount")}{" "}
                    <span className="sign-link">
                      <Link to="signin">{t("common.signIn")}</Link>
                    </span>
                  </div>
                </Box>
              )}
            </div>
          )}
          {/* ---------------------------------Verify OTP--------------------------------  */}
          {/* {true && ( */}
          {verifyOTP && (
            <div className="otp-container">
              <div className="verification-heading">
                <div>{t("signup.verifyOTP")}</div>
              </div>
              <img
                src="/Image/signImage/Email_OTP.svg"
                alt="otp"
                className="otp-image"
              />
              <div className="otp-subheading">{t("signup.otpSubheading")}</div>
              <div className="otp-subheading2">
                {t("signup.otpSubheading2")}
              </div>
              <div className="opt-container">
                <Box sx={{ display: { xs: "none", md: "block" } }}>
                  <OtpInput
                    value={OTP}
                    onChange={setOTP}
                    numInputs={6}
                    renderInput={(props) => <input {...props} />}
                    isInputNum={true}
                    inputStyle={{
                      border: `${
                        otpError.required || otpError.validate
                          ? "1px solid red"
                          : "1px solid #CFD3DB"
                      }`,
                      borderRadius: "8px",
                      width: "40px",
                      height: "40px",
                      fontSize: "10px",
                      color: "#000",
                      fontWeight: "400",
                      margin: "10px",
                    }}
                  />
                </Box>
                <Box sx={{ display: { xs: "block", md: "none" } }}>
                  <OtpInput
                    value={OTP}
                    onChange={setOTP}
                    numInputs={6}
                    renderInput={(props) => <input {...props} />}
                    isInputNum={true}
                    inputStyle={{
                      border: `${
                        otpError.required || otpError.validate
                          ? "1px solid red"
                          : "1px solid #CFD3DB"
                      }`,
                      borderRadius: "4px",
                      width: "30px",
                      height: "30px",
                      fontSize: "10px",
                      color: "#000",
                      fontWeight: "400",
                      margin: "5px",
                    }}
                  />
                </Box>
              </div>
              <div className={`input-alert`}>
                {otpError.required && t("errors.otpError")}
                {otpError.validate && t("errors.validOtpError")}
              </div>

              <div
                className="resend-otp"
                onClick={() => resendOtp()}
                style={{
                  cursor: counter === 0 ? "pointer" : "not-allowed",
                }}
              >
                {langauage === "hi"
                  ? counter === 0
                    ? t("signup.otpResend")
                    : `${counter}  ${t("signup.otpResendIn")}`
                  : counter === 0
                  ? t("signup.otpResend")
                  : `${t("signup.otpResendIn")} ${counter}s!`}
              </div>

              <button
                className="verify-btn"
                onClick={() => {
                  verifyOtp();
                }}
              >
                {loading ? (
                  <CircularProgress size={20} style={{ color: "white" }} />
                ) : (
                  t("signup.otpVerifyButton")
                )}
              </button>
            </div>
          )}
        </Grid>

        <Grid
          item
          xs={0}
          md={5}
          sx={{
            mr: 4,
            display: { xs: "none", md: "block" },
          }}
        >
          <Grid container spacing={1}>
            <Grid
              item
              md={6}
              sx={{
                marginTop: "15vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                gap: 4,
              }}
            >
              <img
                src="/Image/signImage/Asset 8.png"
                alt="sign photo"
                style={{ width: "70%", borderRadius: "20px", marginRight: 20 }}
              />
              <img
                src="/Image/signImage/Asset 7.png"
                alt="sign photo"
                style={{ width: "85%", borderRadius: "20px" }}
              />
            </Grid>
            <Grid
              item
              md={6}
              sx={{
                marginTop: "5vh",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                gap: 4,
              }}
            >
              <img
                src="/Image/signImage/Asset 9.svg"
                alt="sign photo"
                style={{ width: "40%", borderRadius: "20px" }}
              />
              <img
                src="/Image/signImage/Asset 6.png"
                alt="sign photo"
                style={{ width: "90%", borderRadius: "20px" }}
              />
            </Grid>
            <Box
              sx={{
                display: "flex",
                gap: 0.5,
                justifyContent: "center",
                width: "100%",
                mt: 5,
              }}
            >
              <Typography sx={{ fontSize: "26px", color: "#5b5b5b" }}>
                {t("signup.bottomLine1")}
              </Typography>
              <Typography
                sx={{ fontSize: "26px", fontWeight: 600, color: "#ed2f75" }}
              >
                {t("signup.bottomLine2")}{" "}
              </Typography>
              <Typography sx={{ fontSize: "26px", color: "#5b5b5b" }}>
                {t("signup.bottomLine3")}{" "}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography
                sx={{ fontSize: "16px", color: "#5b5b5b", textAlign: "center" }}
              >
                {t("signup.subLine1")}
              </Typography>
              <Typography
                sx={{ fontSize: "16px", color: "#5b5b5b", textAlign: "center" }}
              >
                {t("signup.subLine2")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.profile.user,
    error: state.profile.error,
    status: state?.profile?.status,
    errorMessage: state.errors.errorMessage,
  };
};

/**
 * Maps dispatch functions to props.
 * @param {Function} dispatch - The dispatch function from Redux.
 * @returns {Object} An object containing the mapped dispatch functions.
 */
const mapDispatchToProps = (dispatch) => {
  return {
    /**
     * Dispatches the loginUser action.
     * @param {string} email - The user's email.
     * @param {string} password - The user's password.
     */
    loginUser: (data, onSuccess, onError) =>
      dispatch(loginUser(data, onSuccess, onError)),

    /**
     * Dispatches the signUpUser action.
     * @param {Object} data - The user's data.
     * @param {Function} onSuccess - The success callback function.
     * @param {Function} onError - The error callback function.
     */
    signUpUser: (data, onSuccess, onError) =>
      dispatch(signUpUser(data, onSuccess, onError)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
