import styles from "./css/ChatSearch.module.css";
import { t } from "i18next";

function ChatSearch(props) {
  const { handleChange, handleFocus, handleBlur, searchValue } = props;
  return (
    <div className={`${styles.searchboxWrapper}`}>
      <img src="/Image/DashImage/search_1_icon.svg" />
      <input
        value={searchValue}
        type="text"
        placeholder={t("message.searchPlaceholder")}
        className="form-control-plaintext text-secondary"
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </div>
  );
}

export default ChatSearch;
