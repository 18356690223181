import Dialog from "@mui/material/Dialog";
import { useEffect, useState } from "react";
import axios from "axios";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";

const EditMessageModal = ({ open, messageText, handleClose, handleEdit }) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    if (messageText) setValue(messageText);
  }, [messageText]);

  function handleOnChange(e) {
    setValue(e.target.value);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    await handleEdit(value);
    handleClose();
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "30%", maxHeight: 435 } }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmit,
      }}
    >
      <DialogTitle>Edit Text</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          required
          margin="dense"
          id="name"
          name="email"
          label="New Message"
          type="text"
          fullWidth
          variant="standard"
          value={value}
          onChange={handleOnChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button type="submit">Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditMessageModal;
