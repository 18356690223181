import React from "react";
import styles from "./css/ChatSmall.module.css";

const ChatSmall = ({
  name,
  lastMessage,
  lastMessageTime,
  id,
  openChat,
  userId,
  active,
  profilePic,
}) => {
  const imageUrl = profilePic
    ? profilePic.startsWith("http")
      ? profilePic
      : `${process.env.REACT_APP_API_BASE_URL}${profilePic}`
    : "/Image/header/ddefault_img.svg";

  return (
    <div onClick={() => openChat(id)} className={styles.container}>
      <div
        className={
          active ? styles.innerContainerClicked : styles.innerContainer
        }
      >
        <div className={styles.profilePicContainer}>
          <img className={styles.profilePic} src={imageUrl} alt="profile" />
        </div>
        <div className={styles.nameContainer}>
          <div className={styles.innerNameContainer}>
            <span className={styles.name}>{name}</span>
            <span className={styles.lastMessage}>{lastMessage}</span>
          </div>
          <div className={styles.dateContainer}>{lastMessageTime}</div>
        </div>
      </div>
    </div>
  );
};

export default ChatSmall;
