import React from "react";
import styles from "../css/MessagingPage.module.css";
import MessageBubble from "./MessageBubble";
import FileBubble from "./FileBubble";

const MessageList = ({
  groupedMessages,
  setShowOptionModal,
  setSelectedMsg,
  messagesEndRef,
  setShowEdit,
}) => {
  return (
    <div className={`${styles["conversation-body"]} mt-0 overflow-y-auto`}>
      {Object.entries(groupedMessages).map(([date, messages], idx) => (
        <div key={idx}>
          <div className={styles.dateHeader}>
            <p>{date}</p>
            <div className={styles.line} />
          </div>
          {messages.map((message) => {
            let textMessage;
            let fileMessage;
            if (message.body)
              textMessage = (
                <MessageBubble
                  message={message}
                  setShowOptionModal={setShowOptionModal}
                  setSelectedMsg={setSelectedMsg}
                  setShowEdit={setShowEdit}
                />
              );

            if (message.file_url)
              fileMessage = (
                <FileBubble
                  message={message}
                  setShowOptionModal={setShowOptionModal}
                  setSelectedMsg={setSelectedMsg}
                  setShowEdit={setShowEdit}
                />
              );
            return (
              <div key={message.id}>
                {textMessage}
                {fileMessage}
              </div>
            );
          })}
        </div>
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default MessageList;
