import { convertTimeFromMs } from "../../utils";

export const socketSetup = (
  socketRef,
  setChats,
  setMessages,
  setChat,
  updateChatList,
  isMobileView
) => {
  const userData = localStorage.getItem("userData");
  const parsedUserData = JSON.parse(userData);
  const userId = parsedUserData.user_id;

  socketRef.current = new WebSocket(
    `${process.env.REACT_APP_API_WS_URL}/ws/chat/?user_id=${userId}`
  );

  socketRef.current.addEventListener("message", (e) => {
    const eventData = JSON.parse(e.data);
    const eventType = eventData.event;

    if (eventType === "send_dm_list") {
      const chats = eventData.data.map((item) => {
        const elapsedTime = convertTimeFromMs(
          new Date() - new Date(item.dm_info.msg_timestamp)
        );
        return { ...item, elapsedTime };
      });
      setChats([...chats]);
      if (!isMobileView) setChat(chats[0]);
    } else if (eventType === "chat_message") {
      setMessages((prev) => [...prev, eventData?.message_data]);
    } else if (eventType === "update_message") {
      const updatedMessage = eventData?.message_data;
      if (updatedMessage)
        setMessages((prev) => {
          const newMessages = [...prev];
          const idx = newMessages.findIndex(
            (msg) => msg.id === updatedMessage.id
          );
          if (idx !== -1) newMessages[idx] = updatedMessage;
          return newMessages;
        });
      updateChatList();
    }
  });
};

export const sendMessageViaSocket = (socket, messageId, channelId) => {
  const userData = localStorage.getItem("userData");
  const parsedUserData = JSON.parse(userData);

  const messagePayload = {
    message: messageId,
    sender_id: parsedUserData.user_id,
    channel_id: channelId,
    event: "chat_message",
  };

  socket.send(JSON.stringify(messagePayload));
};

export const editMessage = (socket, editedMsg, selectedMsg) => {
  const userData = localStorage.getItem("userData");
  const parsedUserData = JSON.parse(userData);
  const payload = {
    msg_id: selectedMsg.id,
    content: editedMsg,
    event: "update_message",
    channel_id: selectedMsg.channel,
    user_id: parsedUserData.user_id,
  };

  socket.send(JSON.stringify(payload));
};
