import { extractChatInfo } from "../../../utils";
import styles from "../css/ConversationCard.module.css";

function ConversationCard({ chat, isChatSelected }) {
  const user = extractChatInfo(chat);

  return (
    <div
      className={`${styles.conversationCardContainer} ${
        isChatSelected ? styles.selectedChat : ""
      }`}
    >
      <div className={`${styles["image"]} rounded-pill`}>
        <img className={styles.profilePic} src={user.imageUrl} alt="profile" />
      </div>
      <div className="d-flex justify-content-between w-100">
        <div className="d-flex justify-content-center flex-column">
          <p className="m-0">{user.name}</p>
          <p className={`m-0 ${styles["message-description"]} text-secondary`}>
            {user.lastMessage}
          </p>
        </div>
        <div>
          <p className={`m-0 ${styles["last-message-time"]}`}>
            {user.lastMessageTime}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ConversationCard;
