import React, { useCallback, useEffect, useState } from "react";
import styles from "./css/ChatList.module.css";
import ChatSmall from "./ChatSmall";
import ChatListHeader from "./ChatListHeader";
import axios from "axios";
// import SmallChatSearchView from "./SmallChatSearchView";
import debounce from "lodash.debounce";

export default function ChatList(props) {
  // chatListType - normal, new_message
  const {
    openChat = () => {},
    chatListType = "normal",
    dmList: _dmList = [],
    updateDmList = () => {},
  } = props;

  const [showSuggestions, setShowSuggestions] = useState(
    chatListType === "new_message" ? true : false
  );
  const [selectedNewMessageData, setSelectedNewMessageData] = useState([]);
  const [dmList, setdmList] = useState(_dmList);
  const [searchValue, setSearchValue] = useState("");

  // dmlist will be the dmlist from the server
  useEffect(() => {
    setdmList(_dmList);
  }, [_dmList]);

  const serachUsers = useCallback(
    debounce(async (searchTerm) => {
      if (!searchTerm) return;

      const userData = JSON.parse(localStorage.getItem("userData"));
      const userId = userData.user_id;
      const headers = { Authorization: `Token ${userData.token}` };

      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/results?limit=100&search=${searchTerm}`,
          { headers }
        );

        const filteredData = res.data.data.filter(
          (item) => item.user_id !== userId
        );

        // Format search results to match dm_list structure
        const formattedResults = filteredData.map((user) => ({
          channel: user.user_id, // Use user_id as temporary channel id for search results
          dm_info: {
            members: [
              {
                dm_id: user.user_id,
                dm_name: user.name,
                profile_pic: user.profile_pic,
              },
            ],
            last_msg: "",
          },
          elapsedTime: "",
          profile_pic: user.profile_pic,
          name: user.name,
          user_id: user.user_id,
          isSearchResult: true,
        }));

        setdmList(formattedResults);
        setShowSuggestions(false);
      } catch (error) {
        console.error(error);
      }
    }, 500), // 500ms debounce time
    []
  );

  // handle search input change
  async function handleChange(e) {
    // if there is no input in the search, then show chat list from the server
    setSearchValue(e.target.value);
    if (!e.target.value) {
      setShowSuggestions(true);
      setdmList([..._dmList]);
      return;
    }

    // get the results from the server and update the results
    const results = await serachUsers(e.target.value);
    if (!results) return;

    // Format search results to match dm_list structure
    const formattedResults = results.map((user) => ({
      channel: user.user_id, // Use user_id as temporary channel id for search results
      dm_info: {
        members: [
          {
            dm_id: user.user_id,
            dm_name: user.name,
            profile_pic: user.profile_pic,
          },
        ],
        last_msg: "", // Empty for search results
      },
      elapsedTime: "",
      profile_pic: user.profile_pic,
      name: user.name,
      user_id: user.user_id,
      isSearchResult: true, // Flag to identify search results
    }));

    setdmList(formattedResults);
    setShowSuggestions(false);
  }

  function addItemInNewMessageIds(selectedUserId) {
    const index = dmList.findIndex((item) => {
      // recipient key is in the original dm list (suggested) and
      // user_id key is in the search api
      const userId = item?.dm_info?.members?.[0]?.dm_id || item?.user_id;
      return userId === selectedUserId;
    });

    if (index < 0) return;

    const selectedUser = dmList[index];
    const formattedUser = {
      user_id: selectedUser.user_id,
      dm_info: {
        members: [
          {
            dm_id: selectedUser.user_id,
            dm_name:
              selectedUser.name || selectedUser?.dm_info?.members?.[0]?.dm_name,
            profile_pic:
              selectedUser.profile_pic ||
              selectedUser?.dm_info?.members?.[0]?.profile_pic,
          },
        ],
      },
    };

    setSelectedNewMessageData((prev) => {
      const updated = prev.filter((item) => {
        // recipient key is in the original dm list and
        // user_id key is in the search api
        const userId = item?.dm_info?.members
          ? item?.dm_info.members[0].dm_id
          : item?.user_id;
        return userId !== selectedUserId;
      });

      return [...updated, formattedUser];
    });
  }

  function removeItemInNewMessageIds(removedUserId) {
    setSelectedNewMessageData((prev) => {
      const updated = prev.filter((item) => {
        // recipient key is in the original dm list and
        // user_id key is in the search api
        const userId = item?.dm_info?.members
          ? item?.dm_info.members[0].dm_id
          : item?.user_id;
        return userId !== removedUserId;
      });
      return updated;
    });
  }

  // create new channel api using userids
  async function createNewChannel(userIds) {
    const userData = localStorage.getItem("userData");
    const parsedUserData = JSON.parse(userData);
    const headers = {
      Authorization: `Token ${parsedUserData.token}`,
    };

    const channelType = userIds.length > 1 ? "group" : "direct";

    const result = await axios({
      url: `${process.env.REACT_APP_API_BASE_URL}api/v1/chat/channels/`,
      method: "post",
      headers,
      data: {
        channel_type: channelType,
        recipients_id: userIds,
      },
    }).then((res) => res.data.data);
    // result?.id - for a new message,
    // result?.channel_id - channel already exists
    return result?.id || result?.channel_id;
  }

  function handleOpenChat(channelOrUserId) {
    if (!channelOrUserId) {
      console.warn("Invalid ID received");
      return;
    }

    // Find if this is a search result
    const selectedItem = dmList.find((item) => {
      const itemId = item?.dm_info?.members?.[0]?.dm_id || item?.user_id;
      return itemId === channelOrUserId;
    });

    const isSearchResult = selectedItem?.isSearchResult;

    if (chatListType === "new_message" || isSearchResult) {
      // Handle as new message
      addItemInNewMessageIds(channelOrUserId);
    } else {
      // Handle as normal chat
      openChat(channelOrUserId);
    }
  }

  // oen the chat with new channelid
  async function handleNewMessageClick() {
    const userIds = selectedNewMessageData.map((item) => {
      const userId = item?.dm_info?.members
        ? item?.dm_info.members[0].dm_id
        : item?.user_id;
      return userId;
    });
    const channelId = await createNewChannel(userIds);
    await updateDmList();

    // reset to original state -----
    setSearchValue("");
    setSelectedNewMessageData([]);
    setShowSuggestions(true);
    // -----

    openChat(channelId);
    if (props.hideChat) props.hideChat();
  }

  return (
    <>
      <ChatListHeader
        searchValue={searchValue}
        chatListType={chatListType}
        handleChange={handleChange}
        showSuggestions={showSuggestions}
        removeItemInNewMessageIds={removeItemInNewMessageIds}
        data={selectedNewMessageData}
        handleNewMessageClick={handleNewMessageClick}
      />
      <div id="div-chatlist-container" className={styles.container}>
        {dmList.length
          ? dmList.map((item, index) => {
              // this could be channel id or the list of user ids incase of new message
              const id =
                chatListType === "normal"
                  ? item?.channel
                  : item?.dm_info?.members[0].dm_id || item?.user_id;
              // there will always be single memeber list, because we filtered the list in the parent
              let name;
              if (item?.dm_info?.members.length > 1)
                name = item?.dm_info?.members
                  .map((member) => member.dm_name)
                  .join(", ");
              else
                name = item?.dm_info?.members
                  ? item?.dm_info.members[0].dm_name
                  : item.name;
              if (name.length > 30) name = name.substring(0, 30) + "...";
              const profilePic = item.profile_pic
                ? item.profile_pic
                : item?.dm_info?.members[0].profile_pic;
              const lastMessage =
                (item?.dm_info?.last_msg.length > 35
                  ? item?.dm_info?.last_msg.substring(0, 35) + "..."
                  : item?.dm_info?.last_msg) ?? "";
              return (
                <ChatSmall
                  key={id}
                  openChat={handleOpenChat}
                  name={name}
                  id={id}
                  lastMessage={lastMessage}
                  profilePic={profilePic}
                  // TODO make this dymanic
                  // lastMessageTime={item.dm_info.msg_timestamp}
                  lastMessageTime={item.elapsedTime}
                />
              );
            })
          : null}
      </div>
    </>
  );
}
