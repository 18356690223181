import React from "react";
import { withTranslation } from "react-i18next";
import styles from "../../apps/Profile/Profile.module.css";
import { Button } from "@mui/material";
class SaveButton extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <div className={`${styles.save_btn_wrapper} `}>
        <Button
          sx={{
            border: "1px solid #F33070",
            backgroundColor: "#F33070",
            color: "#fff",
            borderRadius: "20px",
            padding: "4px 25px",
            ":hover": {
              backgroundColor: "#fff",
              color: "#F33070",
            },
            textTransform: "none",
            fontWeight: 600,
          }}
          onClick={this.props.onClick}
          className={`${this.props.isViewTemplateBtn && this.props.className ? this.props.className: ""}`}
        >
          <span>{this.props.btntitle || t("common.save")}</span>
        </Button>
      </div>
    );
  }
}
export default withTranslation()(SaveButton);
