// imports

// packages
import React from "react";
import desc from "./desc";
import "./Template1.css";
import { defaults, Chart } from "react-chartjs-2";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { AspectRatio } from "react-aspect-ratio";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import { Link } from "react-router-dom";
import QRCode from "qrcode.react";

// components
import TitleIconDescComp from "./components/TitleIconDescComp";
import ProgressBarStatic from "./components/ProgressBarStatic";
import TimeTitleDescComp from "./components/TimeTitleDescComp";

import { fetchProfileDataView } from "../../../../redux/actions/profile";
import { GET_PROFILE_DATA_VIEW } from "../../../../redux/constants/actionTypes";
import HandleApiError from "../../../../components/commons/HandleApiError";
import { connect } from "react-redux";
import ShareModal from "../../../Dashboard/shareModal/ShareModal";
import { withTranslation } from "react-i18next";

// global config

// charts
// defaults.interaction.mode = "index";
// defaults.plugins.borderWidth = 10;
// defaults.plugins.legend.display = false;
// defaults.plugins.tooltip.displayColors = false;
// defaults.plugins.tooltip.enabled = false;

// main
class Template1 extends React.Component {
  state = {
    // show: false,
    showShareModal: false,
    link: "ccy-beag-tdz",
  };
  shareModalCloser = () => {
    this.setState({ showShareModal: false });
  };

  shareModalOpener = (link) => {
    this.setState({ showShareModal: true, link: link });
  };
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     myskills:this.props.skillsView.map(skill => ({ name: skill.title, val:5}))
  //   };
  // }
  // skillsDp = [
  //   [
  //     { name: "Music", val: 12 },
  //     { name: "Music", val: 8 },
  //   ],
  //   [
  //     { name: "Reading", val: 19 },
  //     { name: "Reading", val: 1 },
  //   ],
  //   [
  //     { name: "Swimming", val: 3 },
  //     { name: "Swimming", val: 17 },
  //   ],
  //   [
  //     { name: "Fishing", val: 5 },
  //     { name: "Fishing", val: 15 },
  //   ],
  //   [
  //     { name: "Art", val: 15 },
  //     { name: "Art", val: 5 },
  //   ],
  // ];
  // skillsName = ["Music", "Reading", "Swimming", "Fishing", "Art"];
  // skills= {
  //   music: {
  //     labels: ["Music", "Music"],
  //     datasets: [
  //       {
  //         data: [15, 10],
  //         backgroundColor: ["#236AE8", "#E5E7E7"],
  //       },
  //     ],
  //   },
  //   art: {
  //     labels: ["Art", "Art"],
  //     datasets: [
  //       {
  //         data: [15, 10],

  //         backgroundColor: ["#236AE8", "#E5E7E7"],
  //       },
  //     ],
  //   },
  //   teaching: {
  //     labels: ["Teaching", "Teaching"],
  //     datasets: [
  //       {
  //         data: [15, 10],
  //         backgroundColor: ["#236AE8", "#E5E7E7"],
  //       },
  //     ],
  //   },
  //   outdoor: {
  //     labels: ["Outdoor", "Outdoor"],
  //     datasets: [
  //       {
  //         data: [15, 10],
  //         backgroundColor: ["#236AE8", "#E5E7E7"],
  //       },
  //     ],
  //   },
  //   sports: {
  //     labels: ["Sports", "Sports"],
  //     datasets: [
  //       {
  //         data: [15, 10],
  //         backgroundColor: ["#236AE8", "#E5E7E7"],
  //       },
  //     ],
  //   },
  //   adventure: {
  //     labels: ["Adventure", "Adventure"],
  //     datasets: [
  //       {
  //         data: [15, 10],
  //         backgroundColor: ["#236AE8", "#E5E7E7"],
  //       },
  //     ],
  //   },
  // }
  componentDidMount() {
    // this.getCanvasIds();
    // new Chart(this.ctx0, {
    //     type: 'doughnut',
    //     data: this.interests,
    //     options: {
    //         plugins: {
    //             tooltip: {
    //                 enabled: true
    //             }
    //         }
    //     }
    // });

    // new Chart(this.ctx1, {
    //   type: "doughnut",
    //   data: this.skills.art,
    // });
    // new Chart(this.ctx2, {
    //   type: "doughnut",
    //   data: this.skills.adventure,
    // });
    // new Chart(this.ctx3, {
    //   type: "doughnut",
    //   data: this.skills.teaching,
    // });
    // new Chart(this.ctx4, {
    //   type: "doughnut",
    //   data: this.skills.outdoor,
    // });
    // new Chart(this.ctx5, {
    //   type: "doughnut",
    //   data: this.skills.music,
    // });
    // new Chart(this.ctx6, {
    //   type: "doughnut",
    //   data: this.skills.sports,
    // });

    this.props.fetchProfileDataView(
      GET_PROFILE_DATA_VIEW,
      JSON.parse(localStorage.userData).token,
      window.location.pathname.slice(9, -11)
    );
  }

  // getCanvasIds() {
  //   // this.ctx0 = document.getElementById('cv-0').getContext('2d');

  //   this.ctx1 = document.getElementById("cv-1").getContext("2d");
  //   this.ctx2 = document.getElementById("cv-2").getContext("2d");
  //   this.ctx3 = document.getElementById("cv-3").getContext("2d");
  //   this.ctx4 = document.getElementById("cv-4").getContext("2d");
  //   this.ctx5 = document.getElementById("cv-5").getContext("2d");
  //   this.ctx6 = document.getElementById("cv-6").getContext("2d");
  // }

  bgcolor = ["#6564db", "#23e6e8", "#236ae8", "#ffaf02", "#fd0275"];

  // myskill = [
  //   { name: "Music", val: 12 },
  //   { name: "Reading", val: 19 },
  //   { name: "Swimming", val: 3 },
  //   { name: "Fishing", val: 5 },
  // ];

  render() {
    const { t } = this.props;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 20,
          marginTop: 90,
        }}
      >
        <AspectRatio
          ratio="3/4"
          style={{ maxWidth: "100em", userSelect: "none" }}
        >
          <div
            id="main"
            class="page"
            style={{
              margin: "1em 8em 10px 8em",
            }}
          >
            <div style={styles.topviewable} id="topviewable">
              {/* <div>
                <img
                  style={{
                    opacity: 0.1,
                    position: "absolute",
                    top: "50%",
                    marginLeft: "-29em",
                    left: "50%",
                    marginTop: "-30em",
                    height: "60em",
                    width: "56em",
                  }}
                  src="/Image/apps/profile-resume-edit-view/Chalkmate_Watermark.png"
                />
              </div> */}
              <div style={{ marginLeft: 10 }}>
                <div id="vw-prsc" style={styles.vwps}>
                  <div id="vw-la" style={styles.vwla}>
                    <div id="vw-flname" style={styles.vwflname}>
                      <h1
                        style={{
                          color: "#000000",
                          fontSize: 42,
                          textTransform: "uppercase",
                        }}
                      >
                        {this.props.nameView.split(" ")[0] || ""}
                      </h1>
                      <h1
                        style={{
                          color: "#555555",
                          fontSize: 42,
                          textTransform: "uppercase",
                        }}
                      >
                        {this.props.nameView.split(" ")[1] || ""}
                      </h1>
                    </div>
                    <div id="vw-profmail" style={{ paddingBottom: "10px" }}>
                      <TitleIconDescComp
                        title=""
                        desc={this.props.profession}
                        titleSize={0}
                        descSize={15}
                        titleColor="#555555"
                        descColor="#555555"
                        src="/Image/apps/profile-resume-edit-view/designation.png"
                      />
                      <br />
                      <TitleIconDescComp
                        title={""}
                        desc={this.props.email}
                        titleSize={0}
                        descSize={15}
                        titleColor="#555555"
                        descColor="#555555"
                        src="/Image/apps/profile-resume-edit-view/mail.png"
                      />
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <img
                        src={this.props.dp}
                        style={{ height: 162, width: 162, borderRadius: "50%" }}
                        // crossOrigin=" "
                      />
                    </div>
                    <div>
                      <p style={{ color: "#555555" }}>{this.props.about}</p>
                    </div>
                  </div>

                  <div id="vw-rl" style={styles.vwrl}>
                    <div style={{ marginBottom: 10 }}>
                      <img
                        src={
                          "/Image/apps/profile-resume-edit-view/world-cloud.png"
                        }
                        style={{
                          margin: "auto",
                          display: "block",
                          height: "18em",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        marginTop: 20,
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <div style={styles.scard}>
                          <TitleIconDescComp
                            title={`https://nxnearby.com/profile/${this.props.profile_id}`}
                            width={300}
                            desc={t("template.profile")}
                            titleSize={16}
                            descSize={13}
                            titleColor="#555555"
                            descColor="#555555"
                            src="/Image/apps/profile-resume-edit-view/dob.png"
                          />
                        </div>
                        <br />
                        <div style={styles.scard}>
                          <TitleIconDescComp
                            title={this.props.nationality}
                            width={300}
                            desc={t("common.address")}
                            titleSize={16}
                            descSize={13}
                            titleColor="#555555"
                            descColor="#555555"
                            src="/Image/apps/profile-resume-edit-view/nationality.png"
                          />
                        </div>
                        <br />
                        <div style={styles.scard}>
                          <TitleIconDescComp
                            title={this.props.residencecity}
                            desc={t("common.location")}
                            width={300}
                            titleSize={16}
                            descSize={13}
                            titleColor="#555555"
                            descColor="#555555"
                            src="/Image/apps/profile-resume-edit-view/state.png"
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          flex: 1.2,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          {/* <img src={"/Image/apps/profile-resume-edit-view/qrcode.png"} style={{ height: 180 }} /> */}
                          <QRCode
                            value={`${window.location.origin}${
                              "/profile/" + this.props.profile_id + "/view"
                            }`}
                            id="qrCodeEl"
                            size={111}
                            style={{
                              height: "150px",
                              width: "150px",
                              marginLeft: "12px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ margin: "30px 0px 0px 0px" }}>
                <div style={{ marginLeft: 10 }}>
                  <TitleIconDescComp
                    title={t("profile.experience")}
                    desc=""
                    titleSize={16}
                    descSize={13}
                    titleColor="#000"
                    descColor="#555555"
                    src="/Image/apps/profile-resume-edit-view/education.png"
                  />
                </div>
                <div
                  style={{
                    flex: 1,
                    boxShadow: "0px 0px 10px #5555551A",
                    padding: "30px 0px 40px 30px",
                    borderRadius: 10,
                    marginTop: 15,
                  }}
                >
                  <TimeTitleDescComp
                    data={
                      this.props.experience.length != 0
                        ? this.props.experience.slice(0, 5)
                        : [
                            {
                              degree: t("template.noInfoAvailable"),
                              description: t("template.noInfoAvailable"),
                              end_date: t("template.noInfoAvailable"),
                              field_of_study: t("template.noInfoAvailable"),
                              participations: t("template.noInfoAvailable"),
                              school: t("template.noInfoAvailable"),
                              start_date: t("template.noInfoAvailable"),
                            },
                          ]
                    }
                  />
                </div>
              </div>

              <div style={{ margin: "30px 0px 0px 00px" }}>
                <div style={{ marginLeft: 10 }}>
                  <TitleIconDescComp
                    title={t("profile.education")}
                    desc=""
                    titleSize={16}
                    descSize={13}
                    titleColor="#000"
                    descColor="#555555"
                    src="/Image/apps/profile-resume-edit-view/experience.png"
                  />
                </div>
                <div
                  style={{
                    flex: 1,
                    boxShadow: "0px 0px 10px #5555551A",
                    padding: "30px 0px 40px 30px",
                    borderRadius: 10,
                    marginTop: 15,
                  }}
                >
                  <TimeTitleDescComp
                    data={
                      this.props.educationWork.length != 0
                        ? this.props.educationWork.slice(0, 5)
                        : [
                            {
                              degree: t("template.noInfoAvailable"),
                              description: t("template.noInfoAvailable"),
                              end_date: t("template.noInfoAvailable"),
                              field_of_study: t("template.noInfoAvailable"),
                              participations: t("template.noInfoAvailable"),
                              school: t("template.noInfoAvailable"),
                              start_date: t("template.noInfoAvailable"),
                            },
                          ]
                    }
                  />
                </div>
              </div>

              <div
                style={{
                  margin: "30px 0px 0px 0px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <div style={{ marginLeft: 10, marginBottom: 10 }}>
                    <TitleIconDescComp
                      title={t("profile.languages")}
                      desc=""
                      titleSize={16}
                      descSize={13}
                      titleColor="#000"
                      descColor="#555555"
                      src="/Image/apps/profile-resume-edit-view/language.png"
                    />
                  </div>
                  <div style={styles.lcard}>
                    {/* {this.languages.map((lval, idx) => {
                      return <ProgressBarStatic key={idx} fg={lval.color} progress={lval.progress} title={lval.lang} bgcolor="#E5E7E7" weight={500} />;
                    })} */}
                    {this.props.languages.split(",") == "" ? (
                      <p
                        style={{
                          fontSize: 17,
                          color: "#555555",
                          fontWeight: 500,
                          margintop: 10,
                          marginBottom: 0,
                          width: "max-content",
                        }}
                      >
                        {t("template.noInfoAvailable")}
                      </p>
                    ) : (
                      <>
                        {this.props.languages.split(",").map((lval, idx) => {
                          return (
                            <ProgressBarStatic
                              key={idx}
                              fg={lval.color ?? "#236ae8"}
                              progress={lval.progress ?? 100}
                              title={lval}
                              bgcolor="#E5E7E7"
                              weight={500}
                            />
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
                <div>
                  <div style={{ marginLeft: 10, marginBottom: 10 }}>
                    <TitleIconDescComp
                      title={t("profile.interests")}
                      desc=""
                      titleSize={16}
                      descSize={13}
                      titleColor="#000"
                      descColor="#555555"
                      src="/Image/apps/profile-resume-edit-view/intrests.png"
                    />
                  </div>
                  <div style={styles.intlcard}>
                    {this.props.interests.length == 0 ? (
                      <div style={{ width: 212 }}>
                        <p
                          style={{
                            fontSize: 17,
                            color: "#555555",
                            fontWeight: 500,
                            margintop: 10,
                            marginBottom: 0,
                            width: "max-content",
                          }}
                        >
                          {t("template.noInfoAvailable")}
                        </p>
                      </div>
                    ) : (
                      <div style={{ height: "20em", width: "20em" }}>
                        {/* <canvas id='cv-0' /> */}
                        <div style={{ fontSize: 15, height: "100%" }}>
                          <ResponsiveContainer width="100%" height="100%">
                            <PieChart>
                              <Pie
                                data={this.props.interests
                                  .slice(0, 7)
                                  .map((interest) => ({
                                    name: interest.title,
                                    val: 5,
                                  }))}
                                dataKey="val"
                                cx="50%"
                                cy="50%"
                                innerRadius={40}
                                outerRadius={70}
                                fill="#82ca9d"
                              >
                                {this.props.interests.map((entry, index) => (
                                  <Cell
                                    key={`cell-${index}`}
                                    fill={
                                      this.bgcolor[index % this.bgcolor.length]
                                    }
                                  />
                                ))}
                                <LabelList
                                  dataKey="name"
                                  stroke="rgb(85, 85, 85)"
                                  position="outside"
                                  lableline="true"
                                ></LabelList>
                                {/* <Pie data={this.skillsDp} dataKey="val" label={(e) => e.name} cx="50%" cy="50%" innerRadius={40} outerRadius={70} fill="#82ca9d">
                                    {this.skillsDp.map((entry, index) => (
                                      <Cell key={`cell-${index}`} fill={this.bgcolor[index % this.bgcolor.length]} />
                                    ))} */}
                              </Pie>
                            </PieChart>
                          </ResponsiveContainer>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <div style={{ marginLeft: 10, marginBottom: 10 }}>
                    <TitleIconDescComp
                      title={t("profile.skills")}
                      desc=""
                      titleSize={16}
                      descSize={13}
                      titleColor="#000"
                      descColor="#555555"
                      src="/Image/apps/profile-resume-edit-view/skills.png"
                    />
                  </div>
                  <div style={styles.skillslcard}>
                    {this.props.skillsView.length == 0 ? (
                      <div style={{ width: 212, height: "100%" }}>
                        <p
                          style={{
                            fontSize: 17,
                            color: "#555555",
                            fontWeight: 500,
                            margintop: 0,
                            marginBottom: 0,
                            width: "max-content",
                          }}
                        >
                          {t("template.noInfoAvailable")}
                        </p>
                      </div>
                    ) : (
                      <>
                        <div style={styles.sklcrdinside}>
                          {this.props.skillsView
                            .slice(0, 3)
                            .map((item, idx) => {
                              return (
                                <div style={styles.ssklsrd}>
                                  <div style={styles.vwcnv}>
                                    {/* <canvas id="cv-1" /> */}
                                    <ResponsiveContainer
                                      width="100%"
                                      height="100%"
                                    >
                                      <PieChart>
                                        <Pie
                                          // data={item}
                                          data={[
                                            {
                                              name: this.props.skillsView[idx]
                                                .title,
                                              val: 5,
                                            },
                                            {
                                              name: this.props.skillsView[idx]
                                                .title,
                                              val: 2,
                                            },
                                          ]}
                                          dataKey="val"
                                          cx="50%"
                                          cy="50%"
                                          innerRadius={10}
                                          outerRadius={20}
                                          fill="#82ca9d"
                                          startAngle={90}
                                          endAngle={-270}
                                          isAnimationActive={false}
                                        >
                                          {this.props.skillsView.map(
                                            (entry, index) => (
                                              <Cell
                                                key={`cell-${index}`}
                                                fill={
                                                  index == 0
                                                    ? "#236AE8"
                                                    : "#E5E7E7"
                                                }
                                              />
                                            )
                                          )}
                                        </Pie>
                                      </PieChart>
                                    </ResponsiveContainer>
                                  </div>
                                  {/* <p style={styles.cvtitle}>{this.skillsName[idx]}</p> */}
                                  <p style={styles.cvtitle}>
                                    {this.props.skillsView[idx].title}
                                  </p>

                                  {/* <p style={styles.cvtitle}>{Object.keys(this.skills)[0]}</p> */}
                                </div>
                              );
                            })}

                          {/* <div style={styles.ssklsrd}>
                        <div style={styles.vwcnv}>
                          <canvas id="cv-2" />
                        </div>
                        <p style={styles.cvtitle}>{Object.keys(this.skills)[1]}</p>
                      </div>
                      <div style={styles.ssklsrd}>
                        <div style={styles.vwcnv}>
                          <canvas id="cv-3" />
                        </div>
                        <p style={styles.cvtitle}>{Object.keys(this.skills)[2]}</p>
                      </div> */}
                        </div>

                        <div style={styles.sklcrdinside}>
                          {this.props.skillsView
                            .slice(3, 6)
                            .map((item, idx) => {
                              return (
                                <div style={styles.ssklsrd}>
                                  <div style={styles.vwcnv}>
                                    <ResponsiveContainer
                                      width="100%"
                                      height="100%"
                                    >
                                      <PieChart>
                                        <Pie
                                          data={[
                                            {
                                              name: this.props.skillsView[idx]
                                                .title,
                                              val: 5,
                                            },
                                            {
                                              name: this.props.skillsView[idx]
                                                .title,
                                              val: 2,
                                            },
                                          ]}
                                          // data={{name: this.props.skillsView[idx].title, val:5}}
                                          dataKey="val"
                                          cx="50%"
                                          cy="50%"
                                          innerRadius={10}
                                          outerRadius={20}
                                          fill="#82ca9d"
                                          startAngle={90}
                                          endAngle={-270}
                                          isAnimationActive={false}
                                        >
                                          {this.props.skillsView.map(
                                            (entry, index) => (
                                              <Cell
                                                key={`cell-${index}`}
                                                fill={
                                                  index == 0
                                                    ? "#236AE8"
                                                    : "#E5E7E7"
                                                }
                                              />
                                            )
                                          )}
                                        </Pie>
                                      </PieChart>
                                    </ResponsiveContainer>
                                    {/* <canvas id="cv-4" /> */}
                                  </div>
                                  {/* <p style={styles.cvtitle}>{this.skillsName[idx]}</p> */}
                                  <p style={styles.cvtitle}>
                                    {
                                      this.props.skillsView.slice(3, 6)[idx]
                                        .title
                                    }
                                  </p>

                                  {/* <p style={styles.cvtitle}>{Object.keys(this.skills)[3]}</p> */}
                                </div>
                              );
                            })}
                          {/* <div style={styles.ssklsrd}>
                        <div style={styles.vwcnv}>
                          <canvas id="cv-5" />
                        </div>
                        <p style={styles.cvtitle}>{Object.keys(this.skills)[4]}</p>
                      </div>
                      <div style={styles.ssklsrd}>
                        <div style={styles.vwcnv}>
                          <canvas id="cv-6" />
                        </div>
                        <p style={styles.cvtitle}>{Object.keys(this.skills)[5]}</p>
                      </div> */}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AspectRatio>
        <div
          style={{
            margin: "30px 0px 100px 0px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <div
            onClick={async () => {
              document.body.style.maxWidth = "90em";
              const input = document.getElementById("topviewable");

              const canvas = await html2canvas(input, { 
                y: -30,
                x: -20,
                allowTaint : true,
                useCORS: true,
                logging: true, 
              })
              // .then((canvas) => {
                const imgData = canvas.toDataURL("image/png");
                const pdf = new jsPDF("p", "px", "a4", true);
                var width = pdf.internal.pageSize.getWidth();
                var height = pdf.internal.pageSize.getHeight();
                // imgData.onload = () => {
                  pdf.addImage(imgData, "JPEG", 0, 0, width - 20, height - 20);
                  pdf.save("resume.pdf");
                // window.open(pdf.output('datauristring'))
                document.body.style.maxWidth = "100%";
                // }

                // pdf.addImage(imgData, "JPEG", 0, 0, width - 20, height - 20);
                // pdf.save("resume.pdf");
                // // window.open(pdf.output('datauristring'))
                // document.body.style.maxWidth = "100%";
              // });
            }}
          >
            <div id="btn-ftr" style={styles.footerbtn}>
              <span style={styles.btntitle}>{t("template.printThisResume")}</span>
            </div>
          </div>
          <div onClick={this.shareModalOpener}>
            <div id="btn-ftr" style={styles.footerbtn}>
              <span style={styles.btntitle}>{t("template.shareThisResume")}</span>
            </div>
          </div>
          <div>
            <Link
              to={`/profile/${this.props.profile_id}/view`}
              style={{ textDecoration: "none" }}
            >
              <div id="btn-ftr" style={styles.footerbtn}>
                <span style={styles.btntitle}>
                  {t("template.viewDetailedResume")}
                </span>
              </div>
            </Link>
          </div>
        </div>
        <ShareModal
          closeModal={this.shareModalCloser}
          show={this.state.showShareModal}
          shareLink={window.location.href}
        />
      </div>
    );
  }
}
function mapStateToProps(state, props) {
  const { t } = props;
  return {
    nameView: state?.profile?.nameView || "",
    profession:
      state?.profile?.designationView || t("template.noInfoAvailable"),
    dob: state?.profile?.dobView || t("template.noInfoAvailable"),
    nationality: state?.profile?.addressView || t("template.noInfoAvailable"),
    residencecity:
      state?.profile?.locationView || t("template.noInfoAvailable"),
    residencestate: t("template.noInfoAvailable"),
    email: state?.profile?.user_email || t("template.noInfoAvailable"),
    dp:
      state?.profile?.userProfilePicView ||
      "/Image/apps/profile-resume-edit-view/default-user.png",
    about: state?.profile?.aboutView || t("template.noInfoAvailable"),
    languages: state?.profile?.language_knownView || "",
    interests: state?.profile?.interestsView || [],
    skillsView: state?.profile?.skillsView || [],
    educationWork: state?.profile?.educationView || [
      {
        degree: t("template.noInfoAvailable"),
        description: t("template.noInfoAvailable"),
        end_date: t("template.noInfoAvailable"),
        field_of_study: t("template.noInfoAvailable"),
        participations: t("template.noInfoAvailable"),
        school: t("template.noInfoAvailable"),
        start_date: t("template.noInfoAvailable"),
      },
    ],
    experience: state?.profile?.experienceView || [
      {
        company: "DataPitcher",
        description: "",
        employment_type: "Internship",
        end_date: "2021-04-15",
        start_date: "2020-12-15",
        title: "Angular Developer",
      },
    ],
    profile_id: state?.profile?.profileIdView || "",

    // nameView: state?.profile?.user?.first_name || "Write a short bio about yourself.",
    certificationView: state?.profile?.certificationView || [],
    volunteerView: state?.profile?.volunteerView || [],
    achievementView: state?.profile?.achievementView || [],
    organisationView:
      state?.profile?.organisationView || t("template.noInfoAvailable"),
  };
}
function mapDispatchToProps(dispatch) {
  return {
    fetchProfileDataView: (action, token, id) =>
      dispatch(fetchProfileDataView(action, token, id)),
  };
}

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Template1)
);
const styles = {
  topviewable: {
    overflow: "hidden",
    position: "relative",
  },

  vwps: {
    display: "flex",
  },

  vwla: {
    flex: 0.9,
  },

  vwflname: {
    marginBottom: "50px",
    lineHeight: 0.8,
  },

  vwrl: {
    flex: 1,
    marginLeft: 20,
  },

  scard: {
    flex: 1,
    boxShadow: "0px 0px 10px #5555551A",
    borderRadius: 10,
    padding: "10px 10px 10px 20px",
  },

  mcard: {
    display: "flex",
    justifyContent: "center",
  },

  lcard: {
    borderRadius: 10,
    display: "flex",
    boxShadow: "0px 0px 10px #5555551A",
    alignItems: "center",
    flexDirection: "column",
    lineHeight: 0.6,
    padding: 15,
    height: "12em",
  },

  intlcard: {
    borderRadius: 10,
    display: "flex",
    boxShadow: "0px 0px 10px #5555551A",
    alignItems: "center",
    flexDirection: "column",
    lineHeight: 0.6,
    padding: "15px 100px 15px 100px",
    height: "12em",
  },

  skillslcard: {
    borderRadius: 10,
    display: "flex",
    boxShadow: "0px 0px 10px #5555551A",
    flexDirection: "column",
    lineHeight: 0.6,
    padding: 20,
    height: "12em",
    justifyContent: "space-around",
  },

  sklcrdinside: {
    display: "flex",
    justifyContent: "space-around",
  },

  ssklsrd: {
    boxShadow: "0px 0px 10px #5555551A",
    borderRadius: 10,
    padding: "4px 12px 4px 12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    margin: "0px 10px 0px 10px",
  },

  cvtitle: {
    fontSize: 10,
    lineHeight: 1.2,
  },

  vwcnv: {
    height: 40,
    width: 40,
    position: "relative",
  },

  vwbtnftr: {
    display: "flex",
    flexDirection: "row",
  },

  footerbtn: {
    borderRadius: 30,
    border: "2px solid #00d498",
    margin: "10px 60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
  },

  btntitle: {
    padding: "3px 40px 3px 40px",
    fontSize: 10,
    fontWeight: 600,
  },
};
