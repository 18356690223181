import {
  GET_PROFILE_DROP_DOWN,
  POST_PROFILE_DATA,
} from "../constants/actionTypes";

const defaultState = {
  jobs: [],
  profileFilterData: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case POST_PROFILE_DATA:
      return {
        ...state,
        jobs: action.payload.sort((a, b) => a.name.localeCompare(b.name)),
      };
    case GET_PROFILE_DROP_DOWN:
      return {
        ...state,
        profileFilterData: action.payload,
      };
    default:
      return { ...state };
  }
};
