import axios from "axios";
import { convertTimeFromMs } from "../../utils";

export const getMessages = async (channelId) => {
  const userData = localStorage.getItem("userData");
  const parsedUserData = JSON.parse(userData);
  const headers = {
    Authorization: `Token ${parsedUserData.token}`,
  };
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}api/v1/chat/store_msg/?channel_id=${channelId}&dynamic_limit=50`,
      { headers }
    );
    return data;
  } catch (e) {
    console.log("Error in fetching messages from channelId");
  }
};

export const getChats = async () => {
  const userData = localStorage.getItem("userData");
  const parsedUserData = JSON.parse(userData);
  const headers = {
    Authorization: `Token ${parsedUserData.token}`,
  };
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}api/v1/chat/channel-subscriptions/`,
      { headers }
    );

    const chats = data.data;
    const chatsWithElapsedTime = chats.map((item) => {
      const messageTimestamp = item.dm_info.msg_timestamp;
      const elapsedTime = convertTimeFromMs(
        new Date() - new Date(messageTimestamp)
      );
      return { ...item, elapsedTime };
    });
    return chatsWithElapsedTime;
  } catch (e) {
    console.log("Error in fetching chats", e);
  }
};

export const getSearchList = async (searchTerm) => {
  const userData = localStorage.getItem("userData");
  const parsedUserData = JSON.parse(userData);
  const userId = parsedUserData.user_id;
  const headers = {
    Authorization: `Token ${parsedUserData.token}`,
  };

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/results?limit=100&search=${searchTerm}`,
      { headers }
    );

    const searchList = data?.data?.filter((user) => user.user_id !== userId);
    return searchList;
  } catch (e) {
    console.log("Error in fetching searchList", e);
  }
};

export const createNewChannel = async (userIds) => {
  const userData = localStorage.getItem("userData");
  const parsedUserData = JSON.parse(userData);
  const headers = {
    Authorization: `Token ${parsedUserData.token}`,
  };

  const channelType = userIds.length > 1 ? "group" : "direct";
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}api/v1/chat/channels/`,
    {
      channel_type: channelType,
      recipients_id: userIds,
    },
    { headers }
  );

  if (data?.data?.id) return data?.data?.id;
  else return data?.data?.channel_id;
  // result?.id - for a new message,
  // result?.channel_id - channel already exists
};

export const deleteMsgById = async (msgId) => {
  const userData = localStorage.getItem("userData");
  const parsedUserData = JSON.parse(userData);
  const headers = {
    Authorization: `Token ${parsedUserData.token}`,
  };
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}api/v1/chat/store_msg/del-msgs/`,
      {
        headers,
        data: {
          dlt_all: false,
          msg_ids: [msgId],
        },
      }
    );
    return data;
  } catch (e) {
    console.log("Error in deleting message", e);
    return null;
  }
};

export const postMessage = async (formData) => {
  const userData = localStorage.getItem("userData");
  const parsedUserData = JSON.parse(userData);
  const headers = {
    Authorization: `Token ${parsedUserData.token}`,
  };

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}api/v1/messages/`,
      formData,
      { headers }
    );
    return data?.id;
  } catch (e) {
    console.log("Error in sending message", e);
    throw e;
  }
};

export const handleDownloadFile = async (fileUrl, fileName) => {
  try {
    const response = await axios.get(fileUrl, {
      responseType: "blob",
    });

    const blob = new Blob([response.data]);
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};
