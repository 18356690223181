import React from "react";
import styles from "../css/MessageBubble.module.css";
import { format } from "date-fns";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { handleDownloadFile } from "../../../api/messages/messagesApi";

const formatDateTime = (isoString) => {
  return format(new Date(isoString), "h:mm a");
};

// MessageBubble Component
const FileBubble = ({
  message,
  setShowOptionModal,
  setSelectedMsg,
  setShowEdit,
}) => {
  const time = formatDateTime(message.sent_at);
  const userId = JSON.parse(localStorage.getItem("userData"))?.user_id;
  const isNotUser = userId !== message.sender;
  const fileName = message.file_url
    .substring(
      message.file_url.indexOf("Message_files/") + "Message_files/".length
    )
    .replaceAll("_", " ");

  return (
    <div className={`d-flex my-2 mx-2 ${isNotUser ? "" : "flex-row-reverse"}`}>
      <div
        className={`${styles["conversation-image"]} rounded-pill mx-2`}
        style={{
          backgroundImage: `url(${"Image/header/ddefault_img.svg"})`,
        }}
      ></div>
      <div
        style={{
          maxWidth: "60%",
        }}
      >
        <div
          className={`${
            isNotUser
              ? "rounded-bottom-4 rounded-end-4"
              : "rounded-top-4 rounded-start-4"
          }`}
          style={{
            backgroundColor: isNotUser ? "#fef7f8" : "#d71963",
            color: isNotUser ? "#000" : "#fff",
            display: "flex",
            alignItems: "center",
            gap: "5px",
            padding: "20px 15px",
          }}
        >
          <div className={styles.fileContainer}>
            <div className={styles.fileIconContainer}>
            <img src={isNotUser ? "/Image/Messaging/file.svg":"/Image/Messaging/file_white.svg"} alt={fileName} style={{width: '20px', height: '20px', color: 'white'}} />
            </div>
            <p>{fileName}</p>
            <DownloadRoundedIcon
              onClick={() => handleDownloadFile(message.file_url, fileName)}
              sx={{ marginLeft: "10px", cursor: "pointer" }}
            />
          </div>
          {!isNotUser && (
            <MoreVertRoundedIcon
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedMsg(message);
                setShowOptionModal(true);
                setShowEdit(false);
              }}
            />
          )}
        </div>
        <p
          className={`${styles["conversation-time"]} m-0 mt-1`}
          style={{
            textAlign: isNotUser ? "left" : "right",
          }}
        >
          {time}
        </p>
      </div>
    </div>
  );
};

export default FileBubble;
