import styles from "./css/MessagingPage.module.css";
import "bootstrap/dist/css/bootstrap.css";
import { useEffect, useRef, useState } from "react";
import SearchView from "./components/SearchView";
import ConversationCard from "./components/ConversationCard";
import {
  deleteMsgById,
  getChats,
  getMessages,
  postMessage,
} from "../../api/messages/messagesApi";
import {
  editMessage,
  sendMessageViaSocket,
  socketSetup,
} from "../../api/messages/messageSocket";
import { formatImageUrl, groupMessagesByDate } from "../../utils";
import { useMediaQuery } from "@mui/material";
import OptionModal from "../../components/chat/OptionModal";
import EditMessageModal from "./components/EditMessageModal";
import MessageList from "./components/MessageList";
import SearchPlaceholder from "../../components/chat/SearchPlaceholder";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import i18next, { t } from "i18next";

function MessagingPage() {
  const [chats, setChats] = useState([]);
  const [chat, setChat] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [showSendBtn, setShowSendBtn] = useState(false);
  const [showOptionModal, setShowOptionModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedMsg, setSelectedMsg] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showEdit, setShowEdit] = useState(true);

  // Added state variables for translations
  const [currentLanguage, setCurrentLanguage] = useState(i18next.language);
  const [translations, setTranslations] = useState({
    messageTitle: t("message.title"),
    searchPlaceholder: t("message.searchPlaceholder"),
    messagePlaceholder: t("message.messagePlaceholder"),
    noChatSelected: t("message.noChatSelected"),
  });

  const socketRef = useRef(null);
  const isMobileView = useMediaQuery("(max-width:800px)");

  const messagesEndRef = useRef(null);
  const messageInputRef = useRef(null);
  const searchViewRef = useRef(null);
  const fileInputRef = useRef(null);

  // Added effect to detect language change
  useEffect(() => {
    const languageChanged = () => {
      setCurrentLanguage(i18next.language);
      setTranslations({
        messageTitle: t("message.title"),
        searchPlaceholder: t("message.searchPlaceholder"),
        messagePlaceholder: t("message.messagePlaceholder"),
        noChatSelected: t("message.noChatSelected"),
      });
    };

    i18next.on("languageChanged", languageChanged);

    return () => {
      i18next.off("languageChanged", languageChanged);
    };
  }, []);

  useEffect(() => {
    socketSetup(
      socketRef,
      setChats,
      setMessages,
      setChat,
      updateChatList,
      isMobileView
    );

    return () => {
      socketRef.current?.close();
    };
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    updateMsgList();
  }, [chat]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const sendMessage = async () => {
    if (!inputMessage && !selectedFile) return;
    const formData = new FormData();
    formData.append("channel_id", chat.channel);
    formData.append("message", inputMessage);
    if (selectedFile) formData.append("file_url", selectedFile);
    try {
      const messageId = await postMessage(formData);
      if (messageId)
        sendMessageViaSocket(socketRef.current, messageId, chat.channel);
      setInputMessage("");
      setSelectedFile(null);
      updateChatList();
    } catch (e) {
      console.log("Unable to send message", e);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        setInputMessage((prev) => prev + "\n");
      } else {
        e.preventDefault();
        sendMessage();
      }
    }
  };

  const updateChatList = async () => {
    const chats = await getChats();
    setChats(chats);
  };

  const updateMsgList = async () => {
    if (!chat?.channel) return;
    const messages = await getMessages(chat.channel);
    setMessages(messages.data);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const groupedMessages = groupMessagesByDate(messages);

  const resetSearch = () => {
    setSearchTerm("");
  };

  const handleMessageMenuClick = async (action) => {
    if (action === "delete") {
      await deleteMsgById(selectedMsg.id);
      updateChatList();
      updateMsgList();
    } else {
      setShowEditModal(true);
    }
    setShowOptionModal(false);
  };

  const handleEdit = (editedMsg) => {
    editMessage(socketRef.current, editedMsg, selectedMsg);
  };

  return (
    <div className={`${styles.main} rounded-3`}>
      <div className={`d-flex w-100`}>
        {(!isMobileView || (isMobileView && chat === null)) && (
          <div className={`${styles["message-list"]}`}>
            <div>
              <div className="d-flex align-items-center justify-content-between mx-4 my-4">
                <div>
                  <h5 className="fw-bold m-0">{translations.messageTitle}</h5>
                </div>
              </div>
              <div
                className={`${styles["searchbox-wrapper"]} border rounded-5 mx-3 mb-4`}
              >
                <img src="/Image/DashImage/search_1_icon.svg" />
                <input
                  type="text"
                  placeholder={translations.searchPlaceholder}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="form-control-plaintext text-secondary"
                />
                {showSendBtn && (
                  <button
                    className="btn"
                    onClick={() => {
                      if (searchViewRef.current)
                        searchViewRef.current.handleSendMessage();
                    }}
                  >
                    <img
                      src="/Image/Messaging/send.svg"
                      alt="send"
                      style={{ width: "1rem" }}
                    />
                  </button>
                )}
              </div>
            </div>
            <div
              className={`d-flex flex-column ${styles["message-list-container"]} overflow-y-auto`}
            >
              <SearchView
                searchTerm={searchTerm}
                chats={chats}
                setChat={setChat}
                setChats={setChats}
                messageInputRef={messageInputRef}
                resetSearch={resetSearch}
                setShowSendBtn={setShowSendBtn}
                ref={searchViewRef}
              />
              {searchTerm === "" ? (
                chats.length > 0 ? (
                  chats.map((curChat) => (
                    <div key={curChat.id} onClick={() => setChat(curChat)}>
                      <ConversationCard
                        chat={curChat}
                        isChatSelected={curChat?.id === chat?.id}
                      />
                    </div>
                  ))
                ) : (
                  <div>
                    <SearchPlaceholder />
                  </div>
                )
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
        {chat ? (
          <div className={`${styles["conversations"]} position-relative`}>
            <div
              className={`${styles.conversationHeader} d-flex gap-2 px-3 py-2 fw-bold border-bottom align-items-center`}
            >
              {isMobileView && (
                <button className="btn p-0" onClick={() => setChat(null)}>
                  <img
                    src="/Image/lecturePlan/back-arrow.svg"
                    alt="send"
                    style={{ width: "1.3rem" }}
                  />
                </button>
              )}
              <div className={`rounded-pill`}>
                <img
                  className={styles.profilePic}
                  src={formatImageUrl(
                    chat?.dm_info?.members[0]?.profile_pic,
                    process.env.REACT_APP_API_BASE_URL
                  )}
                  alt="profile"
                />
              </div>
              <div className="d-flex justify-content-center flex-column">
                <p className="m-0">
                  {chat?.dm_info?.members.length === 1
                    ? chat?.dm_info?.members[0]?.dm_name
                    : chat?.dm_info?.members
                        .map((member) => member.dm_name)
                        .join(", ")}
                </p>
              </div>
            </div>
            <MessageList
              groupedMessages={groupedMessages}
              setShowOptionModal={setShowOptionModal}
              setSelectedMsg={setSelectedMsg}
              messagesEndRef={messagesEndRef}
              setShowEdit={setShowEdit}
            />
            <div
              className={`${styles["conversation-footer"]} position-absolute bottom-0 w-100 bg-white`}
            >
              {selectedFile && (
                <div className={styles.fileContainer}>
                  <div className={styles.fileIconContainer}>
                    <img src="/Image/Messaging/file.svg" alt="file_uploaded" style={{width: '20px', height: '20px'}} />
                  </div>
                  {selectedFile.name}
                  <CloseRoundedIcon
                    sx={{ marginLeft: "auto", cursor: "pointer" }}
                    onClick={() => setSelectedFile(null)}
                  />
                </div>
              )}
              <div
                className={`${styles["searchbox-wrapper"]} border rounded-3 m-3`}
              >
                <div
                  className={styles.addContainer}
                  onClick={() => fileInputRef.current.click()}
                >
                  <AttachFileIcon sx={{ transform: "rotateZ(45deg)", width: 25, height: 25, padding: '2px' }} />
                </div>
                <input
                  type="file"
                  className={styles.fileInput}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
                <input
                  type="text"
                  placeholder={translations.messagePlaceholder}
                  className="fw-bold form-control-plaintext text-secondary"
                  value={inputMessage}
                  onKeyDown={handleKeyPress}
                  onChange={(e) => setInputMessage(e.target.value)}
                  ref={messageInputRef}
                />
                <button className="btn" onClick={sendMessage}>
                  <img
                    src="/Image/Messaging/send.svg"
                    alt="send"
                    style={{ width: "1rem" }}
                  />
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.messageWindowPlaceholder}>
            {translations.noChatSelected}
          </div>
        )}
      </div>
      <OptionModal
        open={showOptionModal}
        handleClose={() => setShowOptionModal(false)}
        showEdit={showEdit}
        handleListItemClick={handleMessageMenuClick}
      />
      <EditMessageModal
        open={showEditModal}
        handleClose={() => setShowEditModal(false)}
        messageText={selectedMsg?.body}
        handleEdit={handleEdit}
      />
    </div>
  );
}

export default MessagingPage;
