import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import SearchPlaceholder from "../../../components/chat/SearchPlaceholder";
import { getSearchList } from "../../../api/messages/messagesApi";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import styles from "../css/SearchView.module.css";
import { createChatObj, formatImageUrl } from "../../../utils";
import { createNewChannel } from "../../../api/messages/messagesApi";

const SearchView = forwardRef(
  (
    {
      searchTerm,
      chats,
      setChat,
      setChats,
      messageInputRef,
      resetSearch,
      setShowSendBtn,
    },
    ref
  ) => {
    const [searchList, setSearchList] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedIdx, setSelectedIdx] = useState(0);

    useEffect(() => {
      if (searchTerm) fetchSearchList();
      else setSearchList([]);
    }, [searchTerm]);

    useEffect(() => {
      if (selectedUsers.length > 0) setShowSendBtn(true);
      else setShowSendBtn(false);
    }, [selectedUsers]);

    useEffect(() => {
      const handleKeyDown = (e) => {
        if (searchList.length === 0) return;
        if (e.key === "ArrowDown") {
          e.preventDefault();
          setSelectedIdx((prev) =>
            prev + 1 < searchList.length ? prev + 1 : 0
          );
        }
        if (e.key === "ArrowUp") {
          e.preventDefault();
          setSelectedIdx((prev) =>
            prev - 1 >= 0 ? prev - 1 : searchList.length - 1
          );
        }
        if (e.key === "Enter") {
          e.preventDefault();
          handleUserClick(searchList[selectedIdx]);
        }
      };
      window.addEventListener("keydown", handleKeyDown);
      return () => window.removeEventListener("keydown", handleKeyDown);
    }, [selectedIdx, searchList]);

    const fetchSearchList = async () => {
      const searchList = await getSearchList(searchTerm);
      setSearchList(searchList);
    };

    // if (!searchTerm.length) {
    //   return <SearchPlaceholder />;
    // }

    const handleUserClick = (selectedUser) => {
      if (
        selectedUsers.filter((user) => user.user_id === selectedUser.user_id)
          .length === 0
      )
        setSelectedUsers((prev) => [...prev, selectedUser]);
    };

    const handleRemoveUserClick = (selectedUser) => {
      const newSelectedUsers = selectedUsers.filter(
        (user) => user.user_id !== selectedUser.user_id
      );
      setSelectedUsers(newSelectedUsers);
    };

    useImperativeHandle(ref, () => ({
      async handleSendMessage() {
        if (!selectedUsers.length) return;
        if (selectedUsers.length === 1) {
          const selectedUser = selectedUsers[0];
          const selectedChat = chats.filter((chat) => {
            return (
              chat?.dm_info?.members.length === 1 &&
              chat?.dm_info?.members[0]?.dm_id === selectedUser.user_id
            );
          });
          if (selectedChat.length) setChat(selectedChat[0]);
          else {
            await createNewChat();
          }
        } else await createNewChat();
        setSelectedUsers([]);
        messageInputRef.current.focus();
        resetSearch();
      },
    }));

    const createNewChat = async () => {
      const selectedUserIds = selectedUsers.map((user) => user.user_id);
      const newChannelId = await createNewChannel(selectedUserIds);
      const selectedChat = chats.find((chat) => chat.channel === newChannelId);
      if (selectedChat) setChat(selectedChat);
      else {
        const newChat = createChatObj(
          selectedUsers.map((user) => user.name),
          newChannelId,
          selectedUsers[0].profile_pic
        );
        setChats((prev) => [newChat, ...prev]);
        setChat(newChat);
      }
    };

    return (
      <div>
        {selectedUsers.length > 0 && (
          <div className={styles.userPillContainer}>
            {selectedUsers.map((user) => (
              <div key={user.id} className={styles.userPill}>
                <p>{user.name}</p>
                <CloseRoundedIcon
                  onClick={() => handleRemoveUserClick(user)}
                  sx={{ color: "white", cursor: "pointer" }}
                />
              </div>
            ))}
          </div>
        )}
        <div className={styles.userList}>
          {searchList.map((user, idx) => (
            <div
              key={user.id}
              className={`${styles.user} ${
                selectedIdx === idx ? styles.highlight : ""
              }`}
              onClick={() => handleUserClick(user)}
            >
              <img
                className={styles.profilePic}
                src={formatImageUrl(user?.profile_pic)}
                alt="profile-pic"
              />
              <p>{user.name}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }
);

export default SearchView;
