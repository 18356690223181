import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ADD_TRAININGS,
  UPDATE_TRAININGS,
} from "../../../../../../redux/constants/actionTypes";
import styles from "../../../../Profile.module.css";
import SaveButton from "../../../../../../components/commons/SaveButton";
import ModalHeader from "../../../../../../components/commons/ModalHeader";
import ScrollDivBody from "../../../../../../components/commons/ScrollDivBody";
import HandleApiError from "../../../../../../components/commons/HandleApiError";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import PublicIcon from "@mui/icons-material/Public";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import {
  addTrainings,
  updateTrainings,
} from "../../../../../../redux/actions/trainings";

const EditTrainingAndConferences = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.profile?.user);

  const [formState, setFormState] = useState({
    modalTitle: props.modalTitle,
    id: props.id,
    image: props.image,
    title: props.title,
    description: props.description,
    issue_date: props.issue_date,
    organization_name: props.organization_name,
    credential_url: props.credential_url,
    pictureAsFile: props.image,
    error: false,
    message: "",
    uploadImageFlag: false,
  });

  useEffect(() => {
    if (formState.image === "") {
      fetch("/Image/apps/profile-resume-edit-view/DefaultFeed.svg")
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "exp.png", blob);
          setFormState((prev) => ({
            ...prev,
            pictureAsFile: file,
            image: "/Image/apps/profile-resume-edit-view/DefaultFeed.svg",
          }));
        });
    }
  }, []);

  const handleImageChange = (event) => {
    setFormState((prev) => ({
      ...prev,
      image: URL.createObjectURL(event.target.files[0]),
      pictureAsFile: event.target.files[0],
      uploadImageFlag: true,
    }));
  };

  const handleModalHeaderClick = () => {
    props.changeEdit();
    document.body.style.overflow = "visible";
  };

  const handleSaveButtonClick = () => {
    const expState = {
      id: props.id,
      title: formState.title,
      description: formState.description,
      issue_date: formState.issue_date,
      credential_url: formState.credential_url,
      organization_name: formState.organization_name,
      pictureAsFile: formState.pictureAsFile,
      uploadImageFlag: formState.uploadImageFlag,
    };

    if (formState.end_date !== null) {
      expState.end_date = formState.end_date;
    }

    const action = props.add
      ? addTrainings(expState, ADD_TRAININGS)
      : updateTrainings(expState, UPDATE_TRAININGS);

    dispatch(action)
      .then((res) => {
        if (res.val) {
          document.body.style.overflow = "visible";
          props.changeEdit();
          return;
        }
        setFormState((prev) => ({
          ...prev,
          error: true,
          message: res.message,
        }));
      })
      .catch((error) => {
        console.error("Error occurred:", error);
        setFormState((prev) => ({
          ...prev,
          error: true,
          message: "An error occurred while saving the training and conferences.",
        }));
      })
      .finally(() => {
        document.body.style.overflow = "visible";
      });
  };

  const handleInputChange = (field, value) => {
    setFormState((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const ScrollBody = () => {
    return (
      <div>
        <div style={{ paddingBottom: 20 }}>
          <div className={styles.imageUpload}>
            <p className={styles.modal_heading}>{t("profile.media")}*</p>
            <div style={{ display: "flex", borderRadius: "5px" }}>
              <img
                src={formState.image || "/Image/apps/profile-resume-edit-view/DefaultFeed.svg"}
                style={{
                  width: "96px",
                  height: "96px",
                  borderRadius: "10px",
                  objectFit: "cover",
                }}
                alt="rearchWork"
              />
              <div style={{ marginLeft: "20px" }}>
                <p className={styles.sub_heading}>
                  {t("profile.addImageOfYourCompany")}
                </p>
                <label style={{ marginLeft: 30, marginTop: 5 }}>
                  <Box
                    sx={{
                      border: "2px solid #F33070",
                      padding: "8px 20px",
                      borderRadius: 40,
                      cursor: "pointer",
                      background: "#F33070",
                      color: "#fff",
                      ":hover": {
                        background: "#fff",
                        color: "#F33070",
                      },
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      {t("common.upload")}
                    </p>
                  </Box>
                  <input
                    type="file"
                    onChange={handleImageChange}
                    style={{ cursor: "pointer", display: "none" }}
                    accept="image/png, image/jpeg, image/svg"
                  />
                </label>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 15,
            }}
          >
            <p className={styles.modal_heading}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{ marginRight: "10px", width: 25 }}
                  src="/Image/Profile/Professional Experience/title.svg"
                  alt="Title icon"
                />
                {t("profile.title")}*
              </div>
            </p>
            <input
              className={styles.modal_para}
              type="text"
              placeholder={t("profile.modalPlaceholder.ExRetailSalesManager")}
              maxLength="50"
              style={{
                border: "1px solid #5555555A",
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
              }}
              value={formState.title}
              onChange={(e) => handleInputChange("title", e.target.value)}
            />
            <br />
          </div>

          {/* Added Research Work field */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 15,
            }}
          >
            <p className={styles.modal_heading}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <AccountBalanceIcon
                  sx={{ color: "#d61a61", marginRight: "7px", width: 25 }}
                />
                {t("profile.organisationName")}*
              </div>
            </p>
            <input
              className={styles.modal_para}
              type="text"
              placeholder={t("profile.modalPlaceholder.addTextHere")}
              maxLength="100"
              style={{
                border: "1px solid #5555555A",
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
              }}
              value={formState.organization_name}
              onChange={(e) =>
                handleInputChange("organization_name", e.target.value)
              }
            />
            <br />
          </div>

          <div className={styles.modal_date}>
            <div style={{width: '100%', marginRight: 0}}>
              <p className={styles.modal_heading}>
                <div style={{ display: "flex", alignItems: "center"}}>
                  <img
                    style={{ marginRight: "10px", width: 20 }}
                    src="/Image/Profile/Professional Experience/date.svg"
                    alt="Start date icon"
                  />
                  {t("profile.issueDate")}*
                </div>
              </p>
              <input
                className={styles.modal_para}
                type="date"
                style={{
                  border: "1px solid #5555555A",
                  padding: 10,
                  fontSize: 15,
                  borderRadius: 50,
                }}
                value={formState.issue_date}
                onChange={(e) =>
                  handleInputChange("issue_date", e.target.value)
                }
              />
              <br />
            </div>
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <p className={styles.modal_heading}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <PublicIcon
                  sx={{ color: "#d61a61", marginRight: "7px", width: 25 }}
                />
                {t("profile.credentialURL")}
              </div>
            </p>
            <input
              className={styles.modal_para}
              type="text"
              placeholder={t("profile.modalPlaceholder.ExFulltime")}
              maxLength="50"
              style={{
                border: "1px solid #5555555A",
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
              }}
              value={formState.credential_url}
              onChange={(e) =>
                handleInputChange("credential_url", e.target.value)
              }
            />
            <br />
          </div>
        </div>

        <p className={styles.modal_heading}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              style={{ marginRight: "10px", width: 20 }}
              src="/Image/Profile/Professional Experience/description.svg"
              alt="Description icon"
            />
            {t("common.description")}
          </div>
        </p>
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <textarea
            className={styles.modal_para}
            id={styles.textarea}
            cols={55}
            rows={8}
            style={{
              border: "1px solid #5555555A",
              padding: 10,
              fontSize: 15,
              borderRadius: 10,
            }}
            value={formState.description}
            placeholder={t("profile.describeExperienceWithOrganisation")}
            onChange={(e) => handleInputChange("description", e.target.value)}
          />
        </div>
      </div>
    );
  };

  return (
    <div id={styles.template_modal} className={`${styles.modal_wrapper}`}>
      <ModalHeader title={props.modalTitle} onClick={handleModalHeaderClick} />
      <ScrollDivBody body={ScrollBody} />
      <SaveButton onClick={handleSaveButtonClick} />
      <HandleApiError
        error={formState.error}
        message={formState.message}
        onClose={() => setFormState((prev) => ({ ...prev, error: false }))}
      />
    </div>
  );
};

export default EditTrainingAndConferences;
