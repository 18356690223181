import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "../../apps/Profile/Profile.module.css";
import modalStyles from "./CSS/shortBio.module.css";
import ModalHeader from "./ModalHeader";
import ScrollDivBody from "./ScrollDivBody";
import SaveButton from "./SaveButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import TemplatePreviewCard from "../../apps/Profile/pages/templates/TemplatePreviewCard";
import ChooseTemplates from "../../apps/Profile/pages/templates/ChooseTemplates";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileData } from "../../redux/actions/profile";
import { EDIT_PROFILE_DATA } from "../../redux/constants/actionTypes";
const templates = [
  {
    id: "1",
    name: "Profile Template 1",
    price: "Free",
    image: "/Image/apps/profile-resume-edit-view/template1-preview.png",
  },
  {
    id: "2",
    name: "Profile Template 2",
    price: "Free",
    image: "/Image/apps/profile-resume-edit-view/template2-preview.png",
  },
  {
    id: "3",
    name: "Profile Template 3",
    price: "Free",
    image: "/Image/apps/profile-resume-edit-view/template3-preview.png",
  },
  {
    id: "4",
    name: "Profile Template 4",
    price: "Free",
    image: "/Image/apps/profile-resume-edit-view/template4-preview.png",
  },
];

const ShortBioModal = ({ changeEdit, editpage }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const short_bio_selected_id = useSelector(
    (state) => state?.profile?.short_bio
  );

  const closeModal = () => {
    if (selectedTemplate && selectedTemplate.id) {
      setSelectedTemplate(null);
    } else {
      changeEdit(false);
      document.body.style.overflow = "visible";
    }
  };

  const handleViewTemplate = (selectedTemplate) => {
    const userData = localStorage.getItem("userData");
    const parsedUserData = JSON.parse(userData);
    const userId = parsedUserData.user_id;
    const url = `template/${selectedTemplate?.id}`;
    window.open(url, "_blank");
  };

  const handleUse = (selectedTemplate) => {
    const formData = new FormData();
    formData.append("short_bio", selectedTemplate.id);
    dispatch(updateProfileData(EDIT_PROFILE_DATA, formData));
  };

  const ScrollBody = () => (
    <div>
      {/* Template Preview */}
      {selectedTemplate && selectedTemplate?.id ? (
        <div className={modalStyles.previewtemplateContainer}>
          <div className={modalStyles.previewTemplateCard}>
            <img
              src={selectedTemplate.image}
              alt={selectedTemplate.name}
              className={modalStyles.templateImage}
            />
          </div>
        </div>
      ) : (
        <div className={modalStyles.templateContainer}>
          {/* Template Selection */}
          {templates.map((template, index) => (
            <div key={template.id} className={modalStyles.templateCard}>
              <div className={modalStyles.templateOverlay}>
                <h3 className={modalStyles.templateTitle}>{template.name}</h3>
                <p className={modalStyles.templatePrice}>{t(template.price)}</p>
                <button
                  className={modalStyles.viewButton}
                  onClick={() => setSelectedTemplate(template)}
                >
                  {t("template.view")}
                </button>
                {short_bio_selected_id === template.id ? (
                  <button className={modalStyles.setButton}>{t("template.set")}</button>
                ) : (
                  <button
                    className={modalStyles.useButton}
                    onClick={() => handleUse(template)}
                  >
                    {t("template.use")}
                  </button>
                )}
              </div>
              <img
                src={template.image}
                alt={template.name}
                className={modalStyles.templateImage}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );

  return (
    <div
      id={styles.template_modal}
      className={`${styles.modal_wrapper} ${modalStyles.template_modal}`}
    >
      <ModalHeader
        title={selectedTemplate ? t("template.previewTemplate"): t("profile.chooseTemplate")}
        onClick={closeModal}
      />
      <ScrollDivBody body={ScrollBody} />

      {selectedTemplate && (
        <SaveButton
          btntitle={`${t("template.viewThisTemplate")}`}
          onClick={() => handleViewTemplate(selectedTemplate)}
          className={modalStyles.viewTemplateBtn}
          isViewTemplateBtn={selectedTemplate ? true : false}
        />
      )}
    </div>
  );
};

export default ShortBioModal;
