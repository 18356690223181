import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { DiscoverContext } from "../../Discover/DiscoverContext";
import { useContext } from "react";

const EventDisplay = ({
  eventPost,
  name,
  address,
  event_type,
  start_date_time,
  free,
  event_description,
  end_date_time,
  pricing,
  discounted_price,
  show = true,
  actionType,
  EventModalOpener,
  updateEventPost,
  postId,
}) => {
  const { MarkInterested } = useContext(DiscoverContext);
  return (
    <Box sx={{ p: "16px 24px" }}>
      {show === true ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            disableRipple
            onClick={EventModalOpener}
            sx={{ background: "#666", padding: "5px" }}
          >
            <EditIcon fontSize="small" sx={{ color: "#fff" }} />
          </IconButton>
          {actionType !== "EDIT" && (
            <IconButton
              disableRipple
              onClick={() => updateEventPost(null)}
              sx={{ background: "#666", ml: 1, padding: "5px" }}
            >
              <CloseIcon fontSize="small" sx={{ color: "#fff" }} />
            </IconButton>
          )}
        </Box>
      ) : null}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: { xs: "60%", sm: "70%" } }}>
          <Typography
            sx={{
              fontSize: "17px",
              fontWeight: 600,
              lineHeight: "25px",
              display: "flex",
              alignItems: { xs: "flex-start", md: "center" },
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            {name ? name : eventPost?.name}
            <Box
              sx={{
                ml: { xs: 0, sm: 1 },
                background: "#d7eefc",
                p: "1px 10px",
                borderRadius: "10px",
                fontSize: { xs: "10px", sm: "13px" },
                mt: { xs: 1, sm: 0 },
              }}
            >
              {event_type
                ? event_type === "INPERSON"
                  ? "In Person"
                  : "Online"
                : eventPost?.event_type === "INPERSON"
                ? "In Person"
                : "Online"}
            </Box>
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "15px",
              display: "flex",
              alignItems: "flex-start",
              mt: { xs: 1, sm: 0.5 },
              color: "#606060",
            }}
          >
            <img
              src="/Image/DashImage/RightSidebar/connect_icon.svg"
              alt="fire icon"
              style={{
                width: "9px",
                filter: "grayscale(100%)",
                marginRight: 5,
              }}
            />
            {address ? address : eventPost?.address}
          </Typography>
        </Box>
        <Box
          sx={{
            p: "7px 12px",
            background: "#d71a62",
            borderRadius: "12px",
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap"
          }}
        >
          <Typography
            onClick={() => MarkInterested(postId)}
            sx={{
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "20px",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <img
              src="/Image/Discover/Sale/Asset 1.svg"
              alt="message icon"
              style={{
                width: "17px",
                marginRight: 8,
              }}
            />
            Interested
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          gap: 1,
          // justifyContent: "space-around",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            // width: { xs: "90%", sm: "50%", lg: "70%" },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <img
              src="/Image/Discover/Events/Asset 3.svg"
              alt="time icon"
              style={{ width: "22px" }}
            />
            <Box
              sx={{
                background: "#349946",
                p: "8px 14px",
                borderRadius: "10px",
                fontSize: "13px",
                fontWeight: 600,
                color: "#fff",
              }}
            >
              {start_date_time
                ? start_date_time?.slice(0, 11)
                : eventPost?.start_date_time?.slice(0, 11)}{" "}
              {start_date_time
                ? start_date_time?.slice(12, 20)
                : eventPost?.start_date_time?.slice(12, 20)}
            </Box>
          </Box>
          {end_date_time != "undefined," &&
            eventPost?.end_date_time != "undefined," && (
              <Box sx={{ display: "center", alignItems: "center", gap: 1 }}>
                <p>—</p>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Box
                    sx={{
                      background: "#c71f3c",
                      p: "8px 14px",
                      borderRadius: "10px",
                      fontSize: "13px",
                      fontWeight: 600,
                      color: "#fff",
                    }}
                  >
                    {end_date_time
                      ? end_date_time?.slice(0, 11)
                      : eventPost?.end_date_time?.slice(0, 11)}{" "}
                    {end_date_time
                      ? end_date_time?.slice(12, 20)
                      : eventPost?.end_date_time?.slice(12, 20)}
                  </Box>
                </Box>
              </Box>
            )}
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            width: { xs: "100%", sm: "35%", lg: "30%" },
            ml: { xs: 0, sm: 2 },
          }}
        >
          <img
            src="/Image/Discover/Events/Asset 4.svg"
            alt="free icon"
            style={{ width: "22px" }}
          />
          {free === true || eventPost?.free === true ? (
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "20px",
                color: "#000",
              }}
            >
              FREE
            </Typography>
          ) : (
            <>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                  lineHeight: "20px",
                  color: "#000",
                  textDecoration:
                    discounted_price !== "" && eventPost?.discountedPrice !== ""
                      ? "line-through"
                      : "none",
                }}
              >
                ₹{pricing ? pricing : eventPost?.price}
              </Typography>
              {discounted_price !== "" && eventPost?.discountedPrice !== "" && (
                <>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 600,
                      lineHeight: "20px",
                      color: "#000",
                    }}
                  >
                    ₹
                    {discounted_price
                      ? discounted_price
                      : eventPost?.discountedPrice}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 600,
                      lineHeight: "20px",
                      color: "#000",
                    }}
                  >
                    {pricing
                      ? `${(
                          ((pricing - discounted_price) / pricing) *
                          100
                        ).toFixed(2)}% Off`
                      : `${(
                          ((eventPost?.price - eventPost?.discountedPrice) /
                            eventPost?.price) *
                          100
                        ).toFixed(2)}% Off`}
                  </Typography>
                </>
              )}
            </>
          )}
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Box>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "16px",
              textAlign: "left",
              color: "#000",
            }}
          >
            Event Description
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "20px",
              textAlign: "left",
              color: "#656565",
              whiteSpace: "pre-wrap",
            }}
          >
            {event_description
              ? event_description
              : eventPost?.event_description}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default EventDisplay;
