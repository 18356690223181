import React from "react";
import styles from "../../../../Profile.module.css";
import SaveButton from "../../../../../../components/commons/SaveButton";
import ModalHeader from "../../../../../../components/commons/ModalHeader";
import ScrollDivBody from "../../../../../../components/commons/ScrollDivBody";
import { connect } from "react-redux";
import HandleApiError from "../../../../../../components/commons/HandleApiError";
import {
  updateCertification,
  addCertification,
} from "../../../../../../redux/actions/certifications";
import {
  UPDATE_LICENSES,
  ADD_LICENSES,
} from "../../../../../../redux/constants/actionTypes";
import { withTranslation } from "react-i18next";
import { Box } from "@mui/material";

class EditLicences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalTitle: this.props.modalTitle,
      id: this.props.id,
      upload_img: this.props.upload_img,
      name: this.props.name,
      description: this.props.description,
      issue_date: this.props.issue_date,
      expiry_date: this.props.expiry_date,
      issuer: this.props.issuer,
      certification_id: this.props.certification_id,
      certification_url: this.props.certification_url,
      pictureAsFile: this.props.upload_img,
      error: false,
      message: "",
      uploadImageFlag: false,
    };

    this.handleImageChange = (event) => {
      this.setState({
        upload_img: URL.createObjectURL(event.target.files[0]),
        pictureAsFile: event.target.files[0],
        uploadImageFlag: true,
      });
    };

    this.ModalHeaderOnClick = this.ModalHeaderOnClick.bind(this);
    this.ScrollBody = this.ScrollBody.bind(this);
    this.SaveButtonOnClick = this.SaveButtonOnClick.bind(this);
  }

  componentDidMount() {
    if (this.state.upload_img == "") {
      fetch("/Image/apps/profile-resume-edit-view/default-certificate.png")
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "ed.png", blob);
          this.setState({
            pictureAsFile: file,
            upload_img:
              "/Image/apps/profile-resume-edit-view/default-certificate.png",
          });
        });
    }
  }

  ModalHeaderOnClick() {
    this.props.changeEdit();
    document.body.style.overflow = "visible";
  }

  SaveButtonOnClick() {
    const laeState = {
      id: this.state.id,
      upload_img: this.state.upload_img,
      name: this.state.name,
      description: this.state.description,
      issue_date: this.state.issue_date,
      expiry_date: this.state.expiry_date,
      issuer: this.state.issuer,
      certification_id: this.state.certification_id,
      certification_url: this.state.certification_url,
      pictureAsFile: this.state.pictureAsFile,
      uploadImageFlag: this.state.uploadImageFlag,
    };
    if (this.props.add) {
      this.props.addCertification(laeState, ADD_LICENSES).then((res) => {
        if (res.val) {
          this.props.changeEdit();
          document.body.style.overflow = "visible";
          return;
        }
        this.setState({ error: true, message: res.message });
      });
    } else {
      this.props.updateCertification(laeState, UPDATE_LICENSES).then((res) => {
        if (res.val) {
          this.props.changeEdit();
          document.body.style.overflow = "visible";
        }
        this.setState({ error: true, message: res.message });
      });
    }
  }

  ScrollBody() {
    const { t } = this.props;
    return (
      <div>
        <div
          style={{
            // borderBottom: '1px solid #5555555A',
            paddingBottom: 20,
          }}
        >
          <div className={styles.imageUpload}>
            <p className={styles.modal_heading}>{t("profile.media")}*</p>
            <div style={{ display: "flex", borderRadius: "5px" }}>
              <img
                src={this.state.upload_img}
                style={{
                  width: "96px",
                  height: "96px",
                  borderRadius: "10px",
                  objectFit: "cover",
                }}
              />
              <div style={{ marginLeft: "20px" }}>
                <p className={styles.sub_heading}>
                  {t("profile.addImageOfYourCompany")}
                </p>
                <label style={{ marginLeft: 30 }}>
                  <Box
                    sx={{
                      border: "2px solid #F33070",
                      padding: "8px 20px",
                      borderRadius: 40,
                      cursor: "pointer",
                      background: "#F33070",
                      color: "#fff",
                      ":hover": {
                        background: "#fff",
                        color: "#F33070",
                      },
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      {t("common.upload")}
                    </p>
                  </Box>
                  <input
                    type="file"
                    onChange={this.handleImageChange}
                    style={{ cursor: "pointer", display: "none" }}
                    accept="image/png, image/jpeg, image/svg"
                  />
                </label>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 15,
            }}
          >
            <p className={styles.modal_heading}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{ marginRight: "10px", width: "25px" }}
                  src="/Image/Profile/Professional Experience/title.svg"
                />
                {t("common.name")}*
              </div>
            </p>
            <input
              className={styles.modal_para}
              type="text"
              placeholder={t(
                "profile.modalPlaceholder.ExNetworkAssociateSecurity"
              )}
              maxlength="50"
              style={{
                // border: '1px solid #5555555A',
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
              }}
              value={this.state.name}
              onChange={(event) => {
                this.setState({ name: event.target.value });
              }}
            />
            <br />
          </div>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <p className={styles.modal_heading}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{ marginRight: "10px", width: "25px" }}
                  src="/Image/Profile/Professional Experience/company.svg"
                />
                {t("profile.issuingOrganization")}*
              </div>
            </p>
            <input
              className={styles.modal_para}
              type="text"
              placeholder={t("profile.modalPlaceholder.ExMicrosoft")}
              maxlength="50"
              style={{
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
              }}
              value={this.state.issuer}
              onChange={(event) => {
                this.setState({ issuer: event.target.value });
              }}
            />
          </div>
        </div>

        <div className={styles.modal_date}>
          <div>
            <p className={styles.modal_heading}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{ marginRight: "10px", width: "20px" }}
                  src="/Image/Profile/Professional Experience/date.svg"
                />
                {t("profile.issueDate")}*
              </div>
            </p>
            <input
              className={styles.modal_para}
              type="date"
              style={{
                border: "1px solid #5555555A",
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
              }}
              value={this.state.issue_date}
              onChange={(event) => {
                this.setState({ issue_date: event.target.value });
              }}
            />
            <br />
          </div>
          <div>
            <p className={styles.modal_heading}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{ marginRight: "10px", width: "20px" }}
                  src="/Image/Profile/Professional Experience/date.svg"
                />
                {t("profile.ExpirationDate")}
              </div>
            </p>
            <input
              className={styles.modal_para}
              type="date"
              style={{
                border: "1px solid #5555555A",
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
              }}
              value={this.state.expiry_date}
              onChange={(event) => {
                this.setState({ expiry_date: event.target.value });
              }}
            />
            <br />
          </div>
        </div>

        <div
          style={{ display: "flex", flexDirection: "column", marginBottom: 15 }}
        >
          <p className={styles.modal_heading}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                style={{ marginRight: "10px", width: "25px" }}
                src="/Image/Profile/Volunteer Experience/role.svg"
              />
              {t("profile.credentialID")}
            </div>
          </p>
          <input
            className={styles.modal_para}
            type="text"
            style={{
              border: "1px solid #5555555A",
              padding: 10,
              fontSize: 15,
              borderRadius: 50,
            }}
            value={this.state.certification_id}
            placeholder={t("profile.modalPlaceholder.EnteryourCredentialID")}
            onChange={(event) => {
              this.setState({ certification_id: event.target.value });
            }}
          />
          <br />
        </div>
        <p className={styles.modal_heading}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              style={{ marginRight: "10px", width: "20px" }}
              src="/Image/Profile/Volunteer Experience/description.svg"
            />
            {t("common.description")}
          </div>
        </p>
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <textarea
            className={styles.modal_para}
            id={styles.textarea}
            cols={60}
            rows={8}
            style={{
              border: "1px solid #5555555A",
              padding: 10,
              fontSize: 15,
              borderRadius: 10,
              marginBottom: 15,
            }}
            value={this.state.description}
            placeholder={t(
              "profile.modalPlaceholder.DescribeYourCertification"
            )}
            onChange={(event) => {
              this.setState({ description: event.target.value });
            }}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <p className={styles.modal_heading}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                style={{ marginRight: "10px", width: "20px" }}
                src="/Image/Profile/contact_info_icons/profile url.svg"
              />
              {t("profile.credentialURL")}
            </div>
          </p>
          <input
            className={styles.modal_para}
            type="text"
            style={{
              border: "1px solid #5555555A",
              padding: 10,
              fontSize: 15,
              borderRadius: 50,
            }}
            value={this.state.certification_url}
            placeholder={t(
              "profile.modalPlaceholder.EnterCertificationCredential"
            )}
            onChange={(event) => {
              this.setState({ certification_url: event.target.value });
            }}
          />
          <br />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div id={styles.template_modal} className={`${styles.modal_wrapper}`}>
        <ModalHeader
          title={this.props.modalTitle}
          onClick={this.ModalHeaderOnClick}
        />

        <ScrollDivBody body={this.ScrollBody} />

        <SaveButton onClick={this.SaveButtonOnClick} />

        <HandleApiError
          error={this.state.error}
          message={this.state.message}
          onClose={() => this.setState({ error: false })}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateCertification: (laeState, action) =>
      dispatch(updateCertification(laeState, action)),
    addCertification: (laeState, action) =>
      dispatch(addCertification(laeState, action)),
  };
}

export default withTranslation()(
  connect(null, mapDispatchToProps)(EditLicences)
);
