import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ADD_PROJECT,
  UPDATE_PROJECT,
} from "../../../../../../redux/constants/actionTypes";
import styles from "../../../../Profile.module.css";
import SaveButton from "../../../../../../components/commons/SaveButton";
import ModalHeader from "../../../../../../components/commons/ModalHeader";
import ScrollDivBody from "../../../../../../components/commons/ScrollDivBody";
import HandleApiError from "../../../../../../components/commons/HandleApiError";
import { useTranslation } from "react-i18next";
import { Box, Checkbox } from "@mui/material";
import {
  addConsultancyAndProject,
  updateConsultancyAndProject,
} from "../../../../../../redux/actions/consultancyAndProject";

const EditConsultancyAndProjects = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [formState, setFormState] = useState({
    modalTitle: props.modalTitle,
    id: props.id,
    image: props.image,
    name: props.name,
    description: props.description,
    start_date: props.start_date,
    end_date: props.end_date,
    pictureAsFile: props.image,
    image: props.image,
    error: false,
    message: "",
    isPresent: props.end_date ? false : true,
    uploadImageFlag: false,
  });

  useEffect(() => {
    if (formState.image === "") {
      fetch("/Image/apps/profile-resume-edit-view/DefaultFeed.svg")
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "exp.png", blob);
          setFormState((prev) => ({
            ...prev,
            pictureAsFile: file,
            image: "/Image/apps/profile-resume-edit-view/DefaultFeed.svg",
          }));
        });
    }
  }, []);

  const handleImageChange = (event) => {
    setFormState((prev) => ({
      ...prev,
      image: URL.createObjectURL(event.target.files[0]),
      pictureAsFile: event.target.files[0],
      uploadImageFlag: true,
    }));
  };

  const handleModalHeaderClick = () => {
    props.changeEdit();
    document.body.style.overflow = "visible";
  };

  const handleSaveButtonClick = () => {
    const expState = {
      id: props.id,
      name: formState.name,
      description: formState.description,
      start_date: formState.start_date,
      end_date: formState.end_date,
      pictureAsFile: formState.pictureAsFile,
      uploadImageFlag: formState.uploadImageFlag,
    };

    if (formState.end_date !== null) {
      expState.end_date = formState.end_date;
    }

    const action = props.add
      ? addConsultancyAndProject(expState, ADD_PROJECT)
      : updateConsultancyAndProject(expState, UPDATE_PROJECT);

    dispatch(action)
      .then((res) => {
        if (res.val) {
          document.body.style.overflow = "visible";
          props.changeEdit();
          return;
        }
        setFormState((prev) => ({
          ...prev,
          error: true,
          message: res.message,
        }));
      })
      .catch((error) => {
        console.error("Error occurred:", error);
        setFormState((prev) => ({
          ...prev,
          error: true,
          message: "An error occurred while saving the project.",
        }));
      })
      .finally(() => {
        document.body.style.overflow = "visible";
      });
  };

  const handleInputChange = (field, value) => {
    setFormState((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const ScrollBody = () => {
    return (
      <div>
        <div style={{ paddingBottom: 20 }}>
          <div className={styles.imageUpload}>
            <p className={styles.modal_heading}>{t("profile.media")}*</p>
            <div style={{ display: "flex", borderRadius: "5px" }}>
              <img
                src={formState.image || "/Image/apps/profile-resume-edit-view/DefaultFeed.svg"}
                style={{
                  width: "96px",
                  height: "96px",
                  borderRadius: "10px",
                  objectFit: "cover",
                }}
                alt="rearchWork"
              />
              <div style={{ marginLeft: "20px" }}>
                <p className={styles.sub_heading}>
                  {t("profile.addImageOfYourCompany")}
                </p>
                <label style={{ marginLeft: 30, marginTop: 5 }}>
                  <Box
                    sx={{
                      border: "2px solid #F33070",
                      padding: "8px 20px",
                      borderRadius: 40,
                      cursor: "pointer",
                      background: "#F33070",
                      color: "#fff",
                      ":hover": {
                        background: "#fff",
                        color: "#F33070",
                      },
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      {t("common.upload")}
                    </p>
                  </Box>
                  <input
                    type="file"
                    onChange={handleImageChange}
                    style={{ cursor: "pointer", display: "none" }}
                    accept="image/png, image/jpeg, image/svg"
                  />
                </label>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 15,
            }}
          >
            <p className={styles.modal_heading}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{ marginRight: "10px", width: 25 }}
                  src="/Image/Profile/Professional Experience/title.svg"
                  alt="Title icon"
                />
                {t("profile.title")}*
              </div>
            </p>
            <input
              className={styles.modal_para}
              type="text"
              placeholder={t("profile.modalPlaceholder.ExRetailSalesManager")}
              maxLength="50"
              style={{
                border: "1px solid #5555555A",
                padding: 10,
                fontSize: 15,
                borderRadius: 50,
              }}
              value={formState.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
            />
            <br />
          </div>

          <p className={styles.modal_heading}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                style={{ marginRight: "10px", width: 20 }}
                src="/Image/Profile/Professional Experience/description.svg"
                alt="Description icon"
              />
              {t("common.description")}
            </div>
          </p>
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              marginBottom: 15,
            }}
          >
            <textarea
              className={styles.modal_para}
              id={styles.textarea}
              cols={55}
              rows={8}
              style={{
                border: "1px solid #5555555A",
                padding: 10,
                fontSize: 15,
                borderRadius: 10,
              }}
              value={formState.description}
              placeholder={t("profile.describeExperienceWithOrganisation")}
              onChange={(e) => handleInputChange("description", e.target.value)}
            />
          </div>

          <div className={styles.modal_date}>
            <div>
              <p className={styles.modal_heading}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    style={{ marginRight: "10px", width: 20 }}
                    src="/Image/Profile/Professional Experience/date.svg"
                    alt="Start date icon"
                  />
                  {t("profile.StartDate")}*
                </div>
              </p>
              <input
                className={styles.modal_para}
                type="date"
                style={{
                  border: "1px solid #5555555A",
                  padding: 10,
                  fontSize: 15,
                  borderRadius: 50,
                }}
                value={formState.start_date}
                onChange={(e) =>
                  handleInputChange("start_date", e.target.value)
                }
              />
              <br />
            </div>

            <div>
              <p className={styles.modal_heading}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    style={{ marginRight: "10px", width: 20 }}
                    src="/Image/Profile/Professional Experience/date.svg"
                    alt="End date icon"
                  />
                  {t("profile.EndDate")}*
                </div>
              </p>
              <input
                className={styles.modal_para}
                type="date"
                style={{
                  border: "1px solid #5555555A",
                  padding: 10,
                  fontSize: 15,
                  borderRadius: 50,
                }}
                value={formState.end_date}
                onChange={(e) => handleInputChange("end_date", e.target.value)}
              />
              <br />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div id={styles.template_modal} className={`${styles.modal_wrapper}`}>
      <ModalHeader title={props.modalTitle} onClick={handleModalHeaderClick} />
      <ScrollDivBody body={ScrollBody} />
      <SaveButton onClick={handleSaveButtonClick} />
      <HandleApiError
        error={formState.error}
        message={formState.message}
        onClose={() => setFormState((prev) => ({ ...prev, error: false }))}
      />
    </div>
  );
};

export default EditConsultancyAndProjects;
