import styles from "./css/SearchPlaceholder.module.css";
import { t } from "i18next";

function SearchPlaceholder() {
  return (
    <div className={styles.placeholderWrapper}>
      <img height="200" width="200" src="/Image/chat/SearchPlaceholder.svg" />
      <p>{t("message.noChatsFound")}</p>
    </div>
  );
}

export default SearchPlaceholder;
