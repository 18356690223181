import React, { useState } from "react";
import Modal from "../../../../../components/commons/Modal";
import EditExperience from "./Modals/EditExperience";
import styles from "../../../Profile.module.css";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@mui/material";
import EditConsultancyAndProjects from "./Modals/EditConsultancyAndProjects";

const ConsultancyAndProjectsSection = ({ expContent = [] }) => {
  const { t } = useTranslation();
  const projectsData = useSelector((state) => state.profile?.projects);

  const [modalState, setModalState] = useState({
    edit: false,
    modalTitle: "",
    id: null,
    image: "",
    name: "",
    description: "",
    start_date: null,
    end_date: null,
    add: false,
  });

  const [seeall, setSeeall] = useState(false);
  const [endIdx, setEndIdx] = useState(2);

  const changeEdit = () => {
    setModalState((prev) => ({ ...prev, edit: false }));
  };

  const handleAddExperience = () => {
    const newModalTitle = t('profile.consultancy&Project')

    setModalState({
      modalTitle: newModalTitle,
      edit: true,
      add: true,
      id: null,
      image: "",
      name: "",
      description: "",
      start_date: null,
      end_date: null,
      employment_type: "",
      company: "",
    });

    document.body.style.overflow = "hidden";
  };

  const handleEditExperience = (item) => {
    const newModalTitle = t('profile.consultancy&Project')

    setModalState({
      modalTitle: newModalTitle,
      edit: true,
      add: false,
      id: item.id,
      image: item.image,
      name: item.name,
      description: item.description,
      start_date: item.start_date,
      end_date: item.end_date,
      employment_type: item.employment_type,
      company: item.company,
    });

    document.body.style.overflow = "hidden";
  };

  const toggleSeeAll = () => {
    if (endIdx > 2) {
      setEndIdx(2);
    } else {
      setEndIdx(projectsData?.length);
    }
    setSeeall(!seeall);
  };

  return (
    <div>
      <Box sx={{ display: "flex", gap: 2, mb: 1.5 }}>
        <img
          src="/Image/Profile/consultancy.svg"
          alt="about"
          style={{ width: "25px" }}
        />
        <Typography
          sx={{ color: "#606262", fontSize: "18px", fontWeight: 600 }}
        >
          {t("profile.consultancy&ProjectTitle")}
        </Typography>
      </Box>

      <div
        className={styles.desc}
        style={{
          boxShadow: "0px 0px 20px #5555551a",
          padding: 10,
          borderRadius: 10,
        }}
      >
        <div
          style={{
            padding: 10,
          }}
        >
          <p className={styles.edit_info}>
            {t("profile.consultancy&Project")}
          </p>
          <Button
            disableRipple
            sx={{
              background: "#F33070",
              padding: "6px 20px",
              borderRadius: 40,
              cursor: "pointer",
              ":hover": {
                background: "#F33070",
              },
            }}
            onClick={handleAddExperience}
          >
            <p className={styles.btn_text} style={{ color: "#fff" }}>
              + {t("profile.consultancy&Project")}
            </p>
          </Button>
        </div>
        <div
          style={{
            paddingTop: 20,
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          {projectsData && projectsData?.slice(0, endIdx).map((item, idx) => {
            return (
              <div
                key={idx}
                style={{
                  borderBottom:
                    projectsData?.length === 1 ||
                    (projectsData?.length === 2 && idx === 1)
                      ? "none"
                      : "1px solid #5555551A",
                  paddingBottom: 15,
                  marginTop: idx === 0 ? 0 : 20,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div>
                    <img
                      src={item.image || "/Image/apps/profile-resume-edit-view/DefaultFeed.svg"}
                      height="50px"
                      width="50px"
                      style={{ borderRadius: 40, marginRight: 15 }}
                      alt="experience"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <span className={styles.section_name}>
                          {item.name}
                        </span>
                      </div>
                    </div>
                    <p
                      className={styles.followers}
                      style={{
                        opacity: 0.7,
                        color: "#555555",
                        marginBottom: 6,
                      }}
                    >
                      {item.start_date} -{" "}
                      {item.end_date ? item.end_date : "Present"}
                    </p>
                    <p className={styles.section_desc}>{item.description}</p>
                  </div>
                </div>
                <div>
                  <img
                    src="/Image/Profile/edit.svg"
                    style={{
                      height: 20,
                      cursor: "pointer",
                    }}
                    onClick={() => handleEditExperience(item)}
                    alt="edit"
                  />
                </div>
              </div>
            );
          })}
        </div>
        {Array.isArray(projectsData) && projectsData?.length <= 2 && (
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingTop: 10,
            }}
            onClick={toggleSeeAll}
          >
            <button
              style={{
                fontSize: 12,
                fontWeight: 600,
                color: "#555555",
                margin: 10,
                border: "none",
                background: "none",
                cursor: "pointer",
              }}
            >
              {projectsData?.length <= 2
                ? null
                : seeall
                ? t("common.collapse")
                : t("common.seeAll")}
            </button>
          </div>
        )}
      </div>

      {modalState.edit && (
        <Modal
          card={
            <EditConsultancyAndProjects
              id={modalState.id}
              modalTitle={modalState.modalTitle}
              name={modalState.name}
              description={modalState.description}
              start_date={modalState.start_date}
              end_date={modalState.end_date}
              image={modalState.image}
              add={modalState.add}
              changeEdit={changeEdit}
            />
          }
        />
      )}
    </div>
  );
};

export default ConsultancyAndProjectsSection;
