import axios from "axios";
import { errorMessageFromResponse } from "../constants/commonFunctions";
import { handleErrorMessage } from "./classes";

export function postProfileData(filterData, action) {
  return async (dispatch) => {
    const headers = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    return axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/filters/dashboard/`,
        filterData,
        { headers: headers }
      )
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          dispatch({
            type: action,
            payload: res.data?.data,
          });
          return { val: true };
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}

export function getProfileDropDown(action) {
  return async (dispatch) => {
    const headers = {
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    return axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/filters/drop_down/`,
        { headers: headers }
      )
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          dispatch({
            type: action,
            payload: res.data?.data,
          });
          return { val: true };
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}
