import { format } from "date-fns";

export const convertTimeFromMs = (totalMilliseconds) => {
  if (isNaN(totalMilliseconds)) return "";
  const totalSeconds = Math.floor(totalMilliseconds / 1000);
  if (totalSeconds >= 86400) return `${Math.floor(totalSeconds / 86400)}d`;
  if (totalSeconds >= 3600) return `${Math.floor(totalSeconds / 3600)}h`;
  if (totalSeconds >= 60) return `${Math.floor(totalSeconds / 60)}m`;
  return `${totalSeconds}s`;
};

export const formatImageUrl = (image, host) => {
  if (!image) return "/Image/header/ddefault_img.svg";
  let imageUrl = image;
  if (imageUrl.startsWith("/"))
    imageUrl = imageUrl.substring(1, imageUrl.length);
  if (!imageUrl.startsWith("http")) imageUrl = `${host}${imageUrl}`;

  return imageUrl;
};

export const groupMessagesByDate = (messages) => {
  return messages.reduce((acc, cur) => {
    const date = format(new Date(cur.sent_at), "MMM d"); // Format to "Feb 21, 2025"
    if (!acc[date]) acc[date] = [];
    acc[date].push(cur);
    return acc;
  }, {});
};

export const createChatObj = (namesList, channelId, profilePic) => {
  const members = namesList.map((name) => {
    return {
      id: crypto.randomUUID(),
      profile_pic: profilePic,
      dm_name: name,
    };
  });
  return {
    channel: channelId,
    dm_info: {
      members,
    },
  };
};

export const setDefaultLocation = () => {
  if (
    localStorage.getItem("address") &&
    localStorage.getItem("lat") &&
    localStorage.getItem("lng")
  )
    return;
  localStorage.setItem("lat", "28.6139298");
  localStorage.setItem("lng", "77.2088282");
  const address = "New Delhi, Delhi, India";
  localStorage.setItem("address", JSON.stringify(address));
  window.dispatchEvent(new Event("getLocationFromLocalStorage"));
};

export const extractChatInfo = (chat) => {
  const members = chat?.dm_info?.members;
  let name =
    members?.length === 1
      ? members[0]?.dm_name
      : members.map((member) => member?.dm_name).join(", ");
  name = name.length > 25 ? name.substring(0, 25) + "..." : name;
  const image = chat?.dm_info?.members[0]?.profile_pic;
  const imageUrl = formatImageUrl(image, process.env.REACT_APP_API_BASE_URL);
  const lastMessage = chat?.dm_info?.last_msg;
  const lastMessageTime = chat?.elapsedTime;

  return {
    name,
    imageUrl,
    lastMessage,
    lastMessageTime,
  };
};
