import axios from "axios";
import { errorMessageFromResponse } from "../constants/commonFunctions";
import { handleErrorMessage } from "./classes";
import { handleSuccessMessage } from "./success";

export function addResearch(researchState, action) {
  return async (dispatch) => {
    let data = new FormData();
    data.append("title", researchState.title);
    data.append("description", researchState.description);
    data.append("url", researchState.url);
    data.append("industry_name", researchState.industry_name);
    data.append("issue_date", researchState.issue_date);
    data.append("research_type", researchState.research_type);
    data.append("ongoing", researchState.isOnGoing);
    data.append(
      "profile",
      JSON.parse(localStorage.getItem("userData")).profile_id
    );
    if (researchState.uploadImageFlag) {
      data.append(
        "image",
        researchState.pictureAsFile,
        researchState?.pictureAsFile?.name
      );
    }

    // validations
    if (researchState.issue_date == null) {
      return {
        val: false,
        message: "Error adding research - Empty start date",
      };
    }

    const headers = {
      "content-type": "multipart/form-data",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    return axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/research/`,
        data,
        { headers: headers }
      )
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          dispatch(handleSuccessMessage("Successfully added research"));
          dispatch({
            type: action,
            payload: res.data?.data,
          });
          return { val: true };
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}

export function updateResearch(researchState, action) {
  return async (dispatch) => {
    let data = new FormData();
    data.append("title", researchState.title);
    data.append("description", researchState.description);
    data.append("url", researchState.url);
    data.append("industry_name", researchState.industry_name);
    data.append("issue_date", researchState.issue_date);
    data.append("research_type", researchState.research_type);
    data.append("ongoing", researchState.isOnGoing);
    data.append(
      "profile_id",
      JSON.parse(localStorage.getItem("userData")).profile_id
    );
    if (researchState.uploadImageFlag) {
      data.append(
        "image",
        researchState.pictureAsFile,
        researchState?.pictureAsFile?.name
      );
    }

    // validations
    if (researchState.issue_date == null) {
      return {
        val: false,
        message: "Error updating research - Empty filing date",
      };
    }

    const headers = {
      "content-type": "multipart/form-data",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    return axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/research/${researchState?.id}/`,
        data,
        { headers: headers }
      )
      .then((res) => {
        if (res.status >= 200 && res.status < 300 && res.data.status == "1") {
          let value = Object.fromEntries(data.entries());
          if (researchState.uploadImageFlag) {
            value = {
              ...value,
              id: researchState.id,
              upload_img: URL.createObjectURL(researchState.pictureAsFile),
            };
          } else {
            value = {
              ...value,
              id: researchState.id,
              upload_img: researchState.pictureAsFile,
            };
          }
          dispatch({
            type: action,
            payload: value,
          });
          dispatch(handleSuccessMessage("Updated Successfully"));
          return { val: true };
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}
