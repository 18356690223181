import React from "react";
import { withRouter } from "react-router";
import { Button } from "@mui/material";

class FollowButton extends React.Component {
  constructor() {
    super();

    this.state = {
      img: "./Image/DashImage/live-session.svg",
    };
  }

  render() {
    return (
      <Button
        onClick={() => this.props.history.push(`/profile/${this.props.id}`)}
        disableRipple
        size="small"
        sx={{
          "&.MuiButton-root": {
            textTransform: "capitalize",
            background: "var(--optional-gradient)",
            borderRadius: "20px",
            color: "#fff",
            p: "3px 12px",
            ":hover": {
              background: "var(--optional-gradient)",
            },
          },
        }}
      >
        {this.props.t("common.connect")}
      </Button>
    );
  }
}

export default withRouter(FollowButton);
