import React from "react";
import styles from "../css/MessageBubble.module.css";
import { format } from "date-fns";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";

const formatDateTime = (isoString) => {
  return format(new Date(isoString), "h:mm a");
};

// MessageBubble Component
const MessageBubble = ({
  message,
  setShowOptionModal,
  setSelectedMsg,
  setShowEdit,
}) => {
  const text = message.body;
  const time = formatDateTime(message.sent_at);
  const userId = JSON.parse(localStorage.getItem("userData"))?.user_id;
  const isNotUser = userId !== message.sender;

  return (
    <div className={`d-flex my-2 mx-2 ${isNotUser ? "" : "flex-row-reverse"}`}>
      <div
        className={`${styles["conversation-image"]} rounded-pill mx-2`}
        style={{
          backgroundImage: `url(${"Image/header/file.svg"})`,
        }}
      ></div>
      <div
        style={{
          maxWidth: "60%",
        }}
      >
        <div
          className={`${
            isNotUser
              ? "rounded-bottom-4 rounded-end-4"
              : "rounded-top-4 rounded-start-4"
          }`}
          style={{
            backgroundColor: isNotUser ? "#fef7f8" : "#d71963",
            color: isNotUser ? "#000" : "#fff",
            display: "flex",
            alignItems: "center",
            gap: "5px",
            padding: "10px 15px",
          }}
        >
          <p className={`${styles["conversation-text"]} fw-bold m-0`}>{text}</p>
          {!isNotUser && (
            <MoreVertRoundedIcon
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedMsg(message);
                setShowOptionModal(true);
                setShowEdit(true);
              }}
            />
          )}
        </div>
        <p
          className={`${styles["conversation-time"]} m-0 mt-1`}
          style={{
            textAlign: isNotUser ? "left" : "right",
          }}
        >
          {time}
        </p>
      </div>
    </div>
  );
};

export default MessageBubble;
