import React, { useState } from "react";
import styles from "./css/mymessage.module.css";
import OptionModal from "./OptionModal";
import EditModal from "./EditModal";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import InsertDriveFileRoundedIcon from "@mui/icons-material/InsertDriveFileRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { handleDownloadFile } from "../../api/messages/messagesApi";

export default function MyMessage(props) {
  const {
    message,
    deleteMessage,
    id,
    updateMessages,
    channelId,
    socket,
    fileUrl,
  } = props;
  const [open, setOpen] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  function handleOptionClick() {
    setOpen(true);
  }

  function handleModalClose() {
    setOpen(false);
  }

  function handleEditModalClose() {
    setShowEditModal(false);
  }

  function handleListItemClick(action) {
    if (action === "delete") {
      deleteMessage(id).finally(() => {
        updateMessages(channelId);
        setOpen(false);
      });
    } else if (action === "edit") {
      setOpen(false);
      setShowEditModal(true);
    }
  }

  const fileName = fileUrl
    ?.substring(fileUrl?.indexOf("Message_files/") + "Message_files/".length)
    ?.replaceAll("_", " ");

  return (
    <div className={styles.outerContainer}>
      <div className={`${styles.container} rounded-top-4 rounded-start-4`}>
        {!fileUrl ? (
          message
        ) : (
          <div className={styles.fileContainer}>
            <div className={styles.fileIconContainer}>
              <InsertDriveFileRoundedIcon />
            </div>
            <p>{fileName}</p>
            <DownloadRoundedIcon
              onClick={() => handleDownloadFile(fileUrl, fileName)}
              sx={{ marginLeft: "10px", cursor: "pointer" }}
            />
          </div>
        )}
        <MoreVertRoundedIcon
          sx={{ cursor: "pointer" }}
          onClick={handleOptionClick}
        />

        <OptionModal
          showEdit={!fileUrl}
          open={open}
          handleClose={handleModalClose}
          handleListItemClick={handleListItemClick}
        />
        <EditModal
          socket={socket}
          open={showEditModal}
          defaultValue={message}
          handleClose={handleEditModalClose}
          channelId={channelId}
          messageId={id}
        />
      </div>
    </div>
  );
}
