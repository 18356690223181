import React, { useState } from "react";
import styles from "./css/yourmessage.module.css";
import OptionModal from "./OptionModal";
import InsertDriveFileRoundedIcon from "@mui/icons-material/InsertDriveFileRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { handleDownloadFile } from "../../api/messages/messagesApi";

export default function YourMessage(props) {
  const { message, deleteMessage, id, updateMessages, channelId, fileUrl } =
    props;
  const [open, setOpen] = useState(false);

  function handleOptionClick() {
    setOpen(true);
  }

  function handleModalClose() {
    setOpen(false);
  }

  function handleListItemClick() {
    deleteMessage(id).finally(() => {
      updateMessages(channelId);
      setOpen(false);
    });
  }

  const fileName = fileUrl
    ?.substring(fileUrl?.indexOf("Message_files/") + "Message_files/".length)
    ?.replaceAll("_", " ");

  return (
    <div className={styles.outer}>
      <div className={`${styles.container} rounded-bottom-4 rounded-end-4`}>
        {!fileUrl ? (
          message
        ) : (
          <div className={styles.fileContainer}>
            <div className={styles.fileIconContainer}>
              <InsertDriveFileRoundedIcon />
            </div>
            <p>{fileName}</p>
            <DownloadRoundedIcon
              onClick={() => handleDownloadFile(fileUrl, fileName)}
              sx={{ marginLeft: "10px", cursor: "pointer" }}
            />
          </div>
        )}
        {/* <img
          onClick={handleOptionClick}
          src="/Image/chat/options.svg"
          style={{
            marginLeft: "10px",
            height: "13.64px",
            cursor: "pointer",
            width: "3.9px",
          }}
        /> */}
        {/* <OptionModal
          open={open}
          handleClose={handleModalClose}
          handleListItemClick={handleListItemClick}
        /> */}
      </div>
    </div>
  );
}
