import React from "react";
import styles from "../../styles/JobPage.module.css";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

const PersonCard = ({ person, onViewClick }) => {
  const { t } = useTranslation();
  const isWindowScreen = useMediaQuery("(min-width: 768px)");

  return (
    <div className={styles.personCard}>
      <div className={styles.personInfo}>
        <img
          src={person.image || "/Image/header/ddefault_img.svg"}
          alt={person.name}
          className={styles.avatar}
        />
        <div>
          <div className={styles.personName}>{person.name}</div>
          {person.tagline && (
            <div className={styles.personTagline}>{person.tagline}</div>
          )}
        </div>
      </div>
      {isWindowScreen ? (
        <button className={styles.viewProfileButton} onClick={()=>onViewClick(person)}>
          {t("filter.viewProfile")}
        </button>
      ) : (
        <VisibilityIcon sx={{fill: "#ee2f75"}} onClick={()=>onViewClick(person)} />
      )}
    </div>
  );
};

export default PersonCard;
