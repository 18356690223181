import React from "react";
import { Component } from "react";
import styles from "./MobileBottom.module.css";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import Referral from "../referral/referral";
import { withTranslation } from "react-i18next";
import i18next, { t } from "i18next";
import {
  fetchProfileDataEdit,
  fetchProfileDataView,
} from "../../redux/actions/profile";
import {
  GET_PROFILE_DATA_EDIT,
  GET_PROFILE_DATA_VIEW,
} from "../../redux/constants/actionTypes";
import { connect } from "react-redux";
import LanguageButton from "../LanguageButton";
class MobileBottom extends Component {
  constructor() {
    super();

    this.state = {
      showMenu: false,
      notifyChanges: false,
      path: "",
      chat: null,
      showReferralModal: false,
      showLangMenu: false,
      lang:
        localStorage.getItem("i18nextLng") ||
        navigator.language ||
        navigator.userLanguage,
    };
  }

  idetifyChange = () => {
    this.setState({
      notifyChanges: !this.state.notifyChanges,
    });
  };

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside, true);
  }

  handleClickOutside = (event) => {
    const domNode = ReactDOM.findDOMNode(this);

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        notifyChanges: false,
      });
    }
  };
  referralOpen = () => {
    this.setState({ showReferralModal: true });
  };
  ReferralModalCloser = () => {
    this.setState({ showReferralModal: false });
  };

  render() {
    let arr = window.location.href.split("/");
    let path = arr[3];
    const { t } = this.props;
    return (
      <div className={styles.mobileBottom}>
        <Referral
          closeModal={this.ReferralModalCloser}
          show={this.state.showReferralModal}
        />
        <div className={styles.bottom}>
          <div className={styles.bottom_container}>
            <Link to={`/user`} style={{ textDecoration: "none" }}>
              <div>
                <img
                  src="/Image/header/home_icon.svg"
                  className={styles.image}
                  style={{
                    width: 30,
                    height: 30,
                  }}
                />
              </div>
            </Link>
          </div>
        </div>

        <div className={styles.bottom}>
          <div className={styles.bottom_container}>
            <Link to={`/jobs`} style={{ textDecoration: "none" }}>
              <img
                src="/Image/header/jobs.svg"
                style={{
                  width: 30,
                  height: 30,
                }}
              />
            </Link>
          </div>
        </div>

        <div className={styles.bottom}>
          <div className={styles.bottom_container}>
            <Link to={`/messaging`} style={{ textDecoration: "none" }}>
              <img
                src="/Image/header/message_icon.svg"
                className={styles.image}
                style={{
                  width: 30,
                  height: 30,
                }}
              />
            </Link>
          </div>
        </div>

        <div className={styles.bottom}>
          <div className={styles.bottom_container}>
            <a href="http://www.superskil.com/" target="_blank">
              <img
                src="/Image/header/Courses.svg"
                style={{
                  width: 40,
                  height: 30,
                }}
              />
            </a>
          </div>
        </div>
        {/* dont remove may be in future need to add this icon */}
        {/* <div className={styles.bottom}>
          <div className={styles.bottom_container}>
            <a href="https://github.com/nxnearby/generative-ai" target="_blank">
              <img
                src="/Image/header/GithubIcon.svg"
                className={styles.github_icon}
                style={{
                  width: 30,
                  height: 30,
                }}
              />
            </a>
          </div>
        </div> */}

        <div className={styles.bottom}>
          <div className={styles.bottom_container}>
            <LanguageButton />
          </div>
        </div>

        <div></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state?.profile?.user,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchProfileDataEdit: (action, token, id) =>
      dispatch(fetchProfileDataEdit(action, token, id)),
    fetchProfileDataView: (action, token, id) =>
      dispatch(fetchProfileDataView(action, token, id)),
  };
}
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(MobileBottom)
);
