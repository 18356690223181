import React, { useState, useEffect, useRef } from "react";
import styles from "../../styles/JobPage.module.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";

const FilterSection = ({ onFilterChange, profileFilterData }) => {
  const { t } = useTranslation();
  const filterKeys = [
    "Companies",
    "Tags",
    "Designations",
    "Languages",
    "Countries",
  ];
  const translatedFilterKeys = filterKeys.map(key => t(`filter.${key}`));

  const [openDropdowns, setOpenDropdowns] = useState(
    Object.fromEntries(translatedFilterKeys.map((key) => [key, false]))
  );

  const [categories, setCategories] = useState({
    INDIVIDUAL: false,
    "LOCAL BUSINESS": false,
  });

  const [selections, setSelections] = useState({});
  const [filterOptions, setFilterOptions] = useState({});

  const filterRef = useRef(null);

  useEffect(() => {
    if (profileFilterData) {
      const processedData = {
        [t("filter.Categories")]: profileFilterData.categories || [],
        [t("filter.Companies")]: (profileFilterData.companies || []).map(
          company => Array.isArray(company) ? company[0] : company
        ),
        [t("filter.Tags")]: (profileFilterData.tags || []).map(
          tag => Array.isArray(tag) ? tag[0] : tag
        ),
        [t("filter.Designations")]: profileFilterData.designations || [],
        [t("filter.Languages")]: profileFilterData.languages || [],
        [t("filter.Countries")]: profileFilterData.countries || [],
      };

      setFilterOptions(processedData);
      
      if (profileFilterData.categories && profileFilterData.categories.length > 0) {
        const initialCategories = {};
        profileFilterData.categories.forEach(category => {
          initialCategories[category] = false;
        });
        setCategories(initialCategories);
      }
    }
  }, [profileFilterData]);

  useEffect(() => {
    if (Object.keys(filterOptions).length > 0) {
      const initialSelections = {};
      
      Object.keys(filterOptions).forEach(filter => {
        if (Array.isArray(filterOptions[filter])) {
          initialSelections[filter] = {};
          filterOptions[filter].forEach(option => {
            const key = option.code || option;
            initialSelections[filter][key] = false;
          });
        }
      });
  
      setSelections(initialSelections);
    }
  }, [JSON.stringify(filterOptions)]);
  

  useEffect(() => {
    const selectedFilters = {
      categories: Object.keys(categories).filter((key) => categories[key]),
    };
    
    Object.keys(selections).forEach(filter => {
      const filterName = filter.replace(t("filter."), "");
      
      const selectedItems = Object.keys(selections[filter] || {})
        .filter(key => selections[filter][key]);
      
      if (filter === t("filter.Languages")) {
        selectedFilters["languages"] = selectedItems;
      } else {
        selectedFilters[filterName] = selectedItems;
      }
    });

    onFilterChange(selectedFilters);
  }, [categories, selections, onFilterChange, t]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setOpenDropdowns(
          Object.fromEntries(Object.keys(filterOptions).map((key) => [key, false]))
        );
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [filterOptions]);

  const toggleCategory = (category) => {
    setCategories((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  const toggleDropdown = (dropdown) => {
    setOpenDropdowns((prev) => ({
      ...Object.fromEntries(Object.keys(filterOptions).map((key) => [key, false])),
      [dropdown]: !prev[dropdown],
    }));
  };

  const toggleSelection = (filter, option) => {
    setSelections((prev) => {
      const optionKey = typeof option === 'object' ? option.code : option;
      return {
        ...prev,
        [filter]: {
          ...prev[filter],
          [optionKey]: !prev[filter]?.[optionKey],
        },
      };
    });
  };

  const getSelectedCount = (filter) => {
    return Object.values(selections[filter] || {}).filter(Boolean).length;
  };

  const hasSelectedFilters = (filter) => {
    return Object.values(selections[filter] || {}).some(Boolean);
  };

  const getOptionDisplayName = (filter, optionKey) => {
    if (filter === t("filter.Languages")) {
      const languageOption = filterOptions[filter]?.find(
        lang => (typeof lang === 'object' ? lang.code : lang) === optionKey
      );
      return languageOption?.name || optionKey;
    }
    return optionKey;
  };

  return (
    <div ref={filterRef}>
      <div className={styles.filterContainer}>
        <button className={styles.peopleButton}>{t("filter.people")}</button>

        {Object.keys(filterOptions)
          .map((filter) => (
          <div key={filter} className={styles.filterDropdown}>
            <button
              className={`${styles.filterButton} ${
                hasSelectedFilters(filter) ? styles.activeFilter : ""
              }`}
              onClick={() => toggleDropdown(filter)}
            >
              {filter}
              {getSelectedCount(filter) > 0 && (
                <span className={styles.filterCount}>
                  {getSelectedCount(filter)}
                </span>
              )}
              <ExpandMoreIcon />
            </button>

            {openDropdowns[filter] && (
              <div className={styles.dropdownMenu}>
                <div className={styles.dropdownHeader}>{filter}</div>
                <div className={styles.dropdownContent}>
                  {Array.isArray(filterOptions[filter]) && filterOptions[filter].length > 0 ?
                    filterOptions[filter].map((option) => {
                      const optionKey = filter === t("filter.Languages") && typeof option === 'object' 
                        ? option.code 
                        : option;
                      const optionName = filter === t("filter.Languages") && typeof option === 'object' 
                        ? option.name 
                        : option;
                      
                      return (
                        <div
                          key={optionKey}
                          className={`${styles.checkboxItem} ${
                            selections[filter]?.[optionKey]
                              ? styles.selected
                              : ""
                          }`}
                          onClick={() => toggleSelection(filter, option)}
                        >
                          <input
                            type="checkbox"
                            id={`${filter}-${optionKey}`}
                            checked={selections[filter]?.[optionKey] || false}
                            onChange={() => toggleSelection(filter, option)}
                            className={styles.checkbox}
                          />
                          <label htmlFor={`${filter}-${optionKey}`}>
                            {optionName}
                          </label>
                        </div>
                      );
                    })
                    : (
                      <div className={styles.noOptions}>No options available</div>
                    )
                  }
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      <div className={styles.selectedFiltersContainer}>
        {Object.entries(categories)
          .filter(([_, isSelected]) => isSelected)
          .map(([category]) => (
            <div key={category} className={styles.filterChip}>
              {category}
              <button
                className={styles.removeChip}
                onClick={() => toggleCategory(category)}
              >
                ×
              </button>
            </div>
          ))}

        {Object.entries(selections)
          .flatMap(([filter, options]) =>
            Object.entries(options)
              .filter(([_, isSelected]) => isSelected)
              .map(([optionKey]) => ({ filter, optionKey }))
          )
          .map(({ filter, optionKey }) => {
            const originalOption = filter === t("filter.Languages") && filterOptions[filter]
              ? filterOptions[filter].find(opt => 
                  (typeof opt === 'object' ? opt.code : opt) === optionKey
                )
              : optionKey;
            
            return (
              <div key={`${filter}-${optionKey}`} className={styles.filterChip}>
                {getOptionDisplayName(filter, optionKey)}
                <button
                  className={styles.removeChip}
                  onClick={() => toggleSelection(filter, originalOption)}
                >
                  ×
                </button>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default FilterSection;