import axios from "axios";
import { errorMessageFromResponse } from "../constants/commonFunctions";
import { handleErrorMessage } from "./classes";
import { handleSuccessMessage } from "./success";

export function addTrainings(trainingsState, action) {
  return async (dispatch) => {
    let data = new FormData();
    data.append("title", trainingsState.title);
    data.append("description", trainingsState.description);
    data.append("organization_name", trainingsState.organization_name);
    data.append("credential_url", trainingsState.credential_url);
    data.append("issue_date", trainingsState.issue_date);
    data.append(
      "profile_id",
      JSON.parse(localStorage.getItem("userData")).profile_id
    );
    if (trainingsState.uploadImageFlag) {
      data.append(
        "image",
        trainingsState.pictureAsFile,
        trainingsState?.pictureAsFile?.name
      );
    }

    // validations
    if (trainingsState.issue_date == null) {
      return {
        val: false,
        message: "Error adding training - Empty issue date",
      };
    }

    const headers = {
      "content-type": "multipart/form-data",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    return axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/trainings/`,
        data,
        { headers: headers }
      )
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          dispatch(handleSuccessMessage("Successfully added training"));
          dispatch({
            type: action,
            payload: res.data?.data,
          });
          return { val: true };
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}

export function updateTrainings(trainingsState, action) {
  return async (dispatch) => {
    let data = new FormData();
    data.append("title", trainingsState.title);
    data.append("description", trainingsState.description);
    data.append("organization_name", trainingsState.organization_name);
    data.append("credential_url", trainingsState.credential_url);
    data.append("issue_date", trainingsState.issue_date);
    data.append(
      "profile_id",
      JSON.parse(localStorage.getItem("userData")).profile_id
    );
    if (trainingsState.uploadImageFlag) {
      data.append(
        "image",
        trainingsState.pictureAsFile,
        trainingsState.pictureAsFile.name
      );
    }

    // validations
    if (!trainingsState.issue_date) {
      return {
        val: false,
        message: "Error updating training - Empty filing date",
      };
    }

    const headers = {
      "content-type": "multipart/form-data",
      Authorization: `Token ${localStorage.getItem("token")}`,
    };

    return axios
      .put(
        `${process.env.REACT_APP_API_BASE_URL}api/v1/profile/trainings/edit/`,
        data,
        { headers: headers }
      )
      .then((res) => {
        if (res.status >= 200 && res.status < 300 && res.data.status == "1") {
          let value = Object.fromEntries(data.entries());
          if (trainingsState.uploadImageFlag) {
            value = {
              ...value,
              id: trainingsState.id,
              upload_img: URL.createObjectURL(trainingsState.pictureAsFile),
            };
          } else {
            value = {
              ...value,
              id: trainingsState.id,
              upload_img: trainingsState.pictureAsFile,
            };
          }
          dispatch({
            type: action,
            payload: value,
          });
          dispatch(handleSuccessMessage("Updated Successfully"));
          return { val: true };
        }
      })
      .catch(async (error) => {
        dispatch(handleErrorMessage(errorMessageFromResponse(error)));
        return error;
      });
  };
}
