import React from "react";
import { withTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { connect } from "react-redux";
class IconSectionView extends React.Component {
  render() {
    const { t, userTypeView } = this.props;

    const individualTitles = [
      "profile.about",
      "profile.interests",
      "profile.experience",
      "profile.education",
      "profile.volunteerExperience",
      "profile.skillsAndEndorsements",
      "profile.achievements",
      "profile.posts",
      "profile.featured",
    ];

    const businessTitles = [
      "profile.businessInfo",
      "profile.businessCategory",
      "profile.licensesAndCertifications",
      "profile.businessOffering",
      "profile.posts",
      "profile.featured",
    ];

    const feedTitle = [
      ...(userTypeView === "INDIVIDUAL" ? individualTitles : []),
      ...(userTypeView === "LOCAL BUSINESS" ? businessTitles : []),
    ].map((title) => t(title));

    return (
      <div>
        <div
          style={{
            boxShadow: "0px 0px 20px #5555551a",
            padding: "20px",
            borderRadius: 10,
          }}
        >
          {feedTitle.map((title, idx) => {
            if (title !== null) {
              return (
                <div>
                  <Button
                    href={`#${title}`}
                    disableRipple
                    key={idx}
                    sx={{
                      "&.MuiButton-root": {
                        textTransform: "none",
                        textAlign: "left",
                        color: "#606060",
                        p: "6px 8px",
                        width: "100%",
                        fontSize: "14px",
                        lineHeight: 1.2,
                        display: "flex",
                        justifyContent: "flex-start",
                        ":hover": {
                          color: "#F33070",
                          backgroundColor: "#fef7f8",
                          fontWeight: 600,
                        },
                      },
                    }}
                  >
                    {title}
                  </Button>
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //U can destructure here to get only the required attributes from the state
  return {
    userTypeView: state?.profile?.user_type_view,
  };
};

export default withTranslation()(
  connect(mapStateToProps, null)(IconSectionView)
);
