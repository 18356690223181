import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles/JobPage.module.css";
import FilterSection from "./components/FilterSection/FilterSection";
import SearchResults from "./components/SearchSection/SearchSection";
import ConnectionRightFeedComp from "../../components/commons/ConnectionRightFeedComp";
import { useDispatch, useSelector } from "react-redux";
import { getProfileDropDown, postProfileData } from "../../redux/actions/jobs";
import {
  GET_PROFILE_DROP_DOWN,
  POST_PROFILE_DATA,
} from "../../redux/constants/actionTypes";

const keyTranslations = {
  कंपनियाँ: "companies",
  टैग्स: "tags",
  देश: "countries",
  पदनाम: "designations",
  श्रेणियाँ: "categories",
  भाषाएँ: "languages",
};

const JobPage = () => {
  const dispatch = useDispatch();

  const profileFilterData = useSelector(
    (state) => state?.jobs?.profileFilterData
  );
  const profileData = useSelector((state) => state?.jobs?.jobs);
  const [filteredData, setFilteredData] = useState(profileData);

  useEffect(() => {
    dispatch(getProfileDropDown(GET_PROFILE_DROP_DOWN));
  }, []);

  useEffect(() => {
    if (profileData) {
      setFilteredData(profileData);
    }
  }, [profileData]);

  const convertKeysToEnglish = (filters) => {
    return Object.fromEntries(
      Object.entries(filters).map(([key, value]) => [
        keyTranslations[key] || key,
        value,
      ])
    );
  };

  const handleFilterChange = useCallback((selectedFilters) => {
    const updatedFilters = Object.keys(selectedFilters).reduce((acc, key) => {
      const lowerKey = key.toLowerCase();
      acc[lowerKey] = selectedFilters[key];
      return acc;
    }, {});

    dispatch(
      postProfileData(convertKeysToEnglish(updatedFilters), POST_PROFILE_DATA)
    );
  }, []);

  return (
    <div className={styles.container}>
      <FilterSection
        onFilterChange={handleFilterChange}
        profileFilterData={profileFilterData}
      />
      <div className={styles.mainContent}>
        <SearchResults peopleData={filteredData} />
        <div className={styles.connectionsContainer}>
          <ConnectionRightFeedComp />
        </div>
      </div>
    </div>
  );
};

export default JobPage;
