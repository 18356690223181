import React, { Component } from "react";
import styles from "./css/SmallChatBox.module.css";
import ArrowUp from "./images/ArrowUp.svg";
import placeholder from "./images/humanplaceholder.jpeg";
import Add from "./images/edit.svg";
import More from "./images/more.svg";
import Cross from "./images/cross.svg";
import Video from "./images/Video.svg";
import Phone from "./images/Phone.svg";
import { Link } from "react-router-dom";
import { useState } from "react";

const ChatHeader = ({
  showImage = true,
  title,
  cross,
  handleNewMessageClick,
  arrowClick,
  click,
  profilePic,
}) => {
  const [arrowDown, setArrowDown] = useState(false);

  const handleArrowClick = () => {
    if (window.innerWidth > 1100) {
      setArrowDown((prev) => !prev);
    }
    arrowClick();
  };

  const handleClick = () => {
    if (window.innerWidth > 1100) {
      if (click) click();
      else handleArrowClick();
    }
  };

  const imageUrl = profilePic
    ? profilePic.startsWith("http")
      ? profilePic
      : `${process.env.REACT_APP_API_BASE_URL}${profilePic}`
    : "/Image/DashImage/profile.png";

  return (
    <div
      id="div-chatheader"
      onClick={handleClick}
      className={styles.innerContainer}
    >
      {showImage && (
        <div id="chat-header-profile" className={styles.profilePicContainer}>
          <img className={styles.profilePic} src={imageUrl} alt="profile" />
        </div>
      )}
      {title}
      <div className={styles.rightContainer}>
        {/* {this.props.cross ? (
                <img className={styles.phone} src={Phone} alt="Call" />
            ) : null} */}
        {/* {
                this.props.cross ? (
                    <Link to="/video">
                        <img
                            className={styles.video}
                            src={Video}
                            alt="Video Call"
                        />
                    </Link>
                ) : null // <img className={styles.arrowUp} src={Add} alt="Add" />
            } */}
        {/* <img className={styles.arrowUp} src={More} alt="More" /> */}
        {!cross && (
          <img
            // onClick={handleNewMessageClick}
            src="/Image/Messaging/edit.svg"
            alt="edit"
            style={{ width: "1rem", marginRight: "1rem" }}
          />
        )}
        {cross ? (
          <img
            src={Cross}
            alt="Close"
            onClick={(e) => {
              e.stopPropagation();
              handleArrowClick();
            }}
            className={arrowDown ? styles.arrowDown : styles.arrowUp}
          />
        ) : (
          <img
            id="cursor-arrowup"
            onClick={handleArrowClick}
            className={arrowDown ? styles.arrowDown : styles.arrowUp}
            src={ArrowUp}
            alt="Arrow Up"
          />
        )}
      </div>
    </div>
  );
};

export default ChatHeader;
