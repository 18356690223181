import Cross from "./images/cross_white.svg";

function ChatPill(props) {
  const { person, removeItemInNewMessageIds } = props;
  return (
    <div
      className="rounded-pill p-1 px-2 text-white d-flex align-items-center justify-content-center gap-2"
      style={{
        backgroundColor: "#fb3565",
      }}
    >
      <p className="m-0">
        {person?.dm_info?.members
          ? person?.dm_info.members[0].dm_name
          : person.name || ""}
      </p>
      <img
        onClick={() => {
          removeItemInNewMessageIds(
            person?.dm_info?.members[0]?.dm_id || person?.user_id
          );
        }}
        src={Cross}
        alt="Close"
        style={{
          cursor: "pointer",
        }}
      />
    </div>
  );
}

export default ChatPill;
