import ChatSearch from "./ChatSearch";
import styles from "./css/ChatListHeader.module.css";
import ChatPill from "./ChatPill";
import { Button } from "@mui/material";
import { t } from "i18next";

function ChatListHeader(props) {
  const {
    chatListType = "normal",
    showSuggestions = false,
    data = [],
    removeItemInNewMessageIds = () => {},
    handleNewMessageClick = () => {},
  } = props;

  return (
    <div className={`${styles.chatSearchWrapper} p-3`}>
      <ChatSearch {...props} />
      {data && data.length ? (
        <>
          <div className="d-flex gap-2 my-2 flex-wrap mt-3">
            {data.map((person) => {
              return (
                <ChatPill
                  person={person}
                  removeItemInNewMessageIds={removeItemInNewMessageIds}
                />
              );
            })}
          </div>
          <Button
            onClick={handleNewMessageClick}
            disableRipple
            size="small"
            sx={{
              textTransform: "none",
              background: "var(--secondary-gradient)",
              borderRadius: "20px",
              color: "#fff",
              px: 2,
              mt: 1,
              ":hover": {
                background: "var(--secondary-gradient)",
              },
              fontWeight: 500,
              lineHeight: { xs: 1.75, sm: 1.75, md: 1.25, lg: 1.75 },
            }}
          >
            {t("message.sendMessage")}
          </Button>
        </>
      ) : null}
      {chatListType === "new_message" && showSuggestions && (
        <p className="pt-2" style={{ margin: ".25rem .25rem -.5rem .25rem" }}>
          {t("message.suggestions")}
        </p>
      )}
    </div>
  );
}

export default ChatListHeader;
