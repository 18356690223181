import React, { useState, useEffect, useRef } from "react";
import SmallChatBox from "./SmallChatBox";
import BigBox from "./BigBox";
import styles from "./css/chat.module.css";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { convertTimeFromMs } from "../../utils";
import { t } from "i18next";

const Chat = ({ location, toggleChatList, isChatListOpen }) => {
  const socket = useRef(null);
  const [openChats, setOpenChats] = useState([]);
  const [dmList, setDmList] = useState([]);
  const [newMessageObj, setNewMessageObj] = useState({});
  const [updatedMessageObj, setUpdatedMessageObj] = useState({});

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const parsedUserData = JSON.parse(userData);
    const userId = parsedUserData.user_id;

    socket.current = new WebSocket(
      `${process.env.REACT_APP_API_WS_URL}/ws/chat/?user_id=${userId}`
    );

    socket.current.addEventListener("message", (e) => {
      const eventData = JSON.parse(e.data);
      const eventType = eventData.event;

      if (eventType === "send_dm_list") {
        const chats = eventData.data.map((item) => {
          const messageTimestamp = item.dm_info.msg_timestamp;
          const elapsedTime = convertTimeFromMs(
            new Date() - new Date(messageTimestamp)
          );
          return { ...item, elapsedTime };
        });
        setDmList([...chats]);
      } else if (eventType === "chat_message") {
        setNewMessageObj({
          id: eventData.message_data.id || 0,
          body: eventData.message_data.body,
          sender: eventData.message_data.sender,
          channelId: eventData.message_data.channel,
        });
      } else if (eventType === "update_message") {
        setUpdatedMessageObj({
          id: eventData.id,
          body: eventData.message_data.body,
          channelId: eventData.channel_id,
        });
      }
    });

    return () => {
      socket.current?.close();
    };
  }, []);

  const updateDmList = async () => {
    const userData = localStorage.getItem("userData");
    const parsedUserData = JSON.parse(userData);
    const headers = {
      Authorization: `Token ${parsedUserData.token}`,
    };
    const chats = await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}api/v1/chat/channel-subscriptions/`,
        { headers }
      )
      .then((res) => {
        if (!res.data) return;
        return res.data.data;
      })
      .catch(console.error);
    const chatsWithElapsedTime = chats.map((item) => {
      const messageTimestamp = item.dm_info.msg_timestamp;
      const elapsedTime = convertTimeFromMs(
        new Date() - new Date(messageTimestamp)
      );
      return { ...item, elapsedTime };
    });
    setDmList([...chatsWithElapsedTime]);
  };

  const chatOpener = (channelId) => {
    setOpenChats((prev) =>
      prev.includes(channelId)
        ? prev.filter((id) => id !== channelId)
        : [...prev, channelId]
    );
  };

  const closeChat = (channelId) => {
    setOpenChats((prev) => prev.filter((id) => id !== channelId));
  };

  const showChats = () => {
    return openChats.map((id) => {
      const channelIndex = dmList.findIndex((item) => item.channel === id);
      if (id !== "new_message" && channelIndex === -1) return null;

      const channelDetails = dmList[channelIndex];
      const name = channelDetails?.dm_info.members
        .map((item) => item.dm_name)
        .join(", ");
      const profilePic = channelDetails?.dm_info.members[0]?.profile_pic;

      return (
        <BigBox
          socket={socket.current}
          updatedMessageObj={updatedMessageObj}
          newMessageObj={newMessageObj}
          key={id}
          id={id}
          dmDetails={channelDetails}
          closeChat={closeChat}
          {...(id === "new_message" && { showImage: false })}
          name={id === "new_message" ? t("message.newMessage") : name}
          profilePic={profilePic}
          chatOpener={chatOpener}
          dmList={dmList}
          updateDmList={updateDmList}
        />
      );
    });
  };

  if (location.pathname === "/messaging") {
    return null;
  }

  return (
    <div id="chat-container" className={styles.container}>
      {showChats()}
      <SmallChatBox
        toggleChatList={toggleChatList}
        isChatListOpen={isChatListOpen}
        chatOpener={chatOpener}
        dmList={dmList}
        updateDmList={updateDmList}
      />
    </div>
  );
};

export default withRouter(Chat);
