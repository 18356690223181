import { Button, Card, Rating, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { ProfileContext } from "../../../contexts/ProfileContext";
import { useSelector } from "react-redux";

const CustomerRateFeed = () => {
  const [rating, setRating] = useState(0);
  const { addRating } = useContext(ProfileContext);
  const profileIdView = useSelector((state) => state.profile.profileIdView);
  const profileRatingView = useSelector(
    (state) => state.profile.profile_rating_view
  );
  var userData = localStorage.getItem("userData");
  var parsedUserData = JSON.parse(userData);
  const userRated = profileRatingView?.filter(
    (item) => item.profile_liked_by === parsedUserData?.profile_id
  );

  const submitRating = () => {
    addRating(rating, profileIdView);
  };

  return (
    <Card
      elevation={1}
      sx={{
        "&.MuiCard-root": {
          padding: "25px 20px",
          borderRadius: "10px",
          backgroundColor: "#faf9f5",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
        },
      }}
    >
      <Typography sx={{ color: "#9b9c9b", fontSize: "14px" }}>
        Rate this business
      </Typography>
      <Rating
        name="read-only"
        value={userRated?.length > 0 ? userRated[0].rating : rating}
        size="large"
        onChange={(e, newValue) => setRating(newValue)}
        readOnly={userRated?.length > 0 ? true : false}
      />
      {userRated?.length <= 0 && (
        <Button
          onClick={submitRating}
          disableRipple
          variant="contained"
          sx={{
            "&.MuiButton-root": {
              borderRadius: "25px",
              padding: "3px 30px",
              color: "white",
              boxShadow: "none",
              textTransform: "none",
              backgroundColor: "#F33070",
              ":hover": {
                backgroundColor: "#AA214E",
              },
            },
          }}
        >
          Submit
        </Button>
      )}
    </Card>
  );
};

export default CustomerRateFeed;
