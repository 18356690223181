import React, { useState } from "react";
import Modal from "../../../../../components/commons/Modal";
import EditResearch from "./Modals/EditResearch";
import styles from "../../../Profile.module.css";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography, Menu, MenuItem } from "@mui/material";

const menuOptions = [
  { id: 1, name: "Industry - Ongoing", value: "industry ongoing" },
  { id: 2, name: "Government - Ongoing", value: "govt ongoing" },
  { id: 3, name: "Other - Ongoing", value: "other ongoing" },
  { id: 4, name: "Industry - Past", value: "industry past" },
  { id: 5, name: "Government - Past", value: "govt past" },
  { id: 6, name: "Other - Past", value: "other past" },
];

const ResearchWork = ({ expContent = [] }) => {
  const { t } = useTranslation();
  const researchData = useSelector((state) => state.profile?.research);
  const [endIdx, setEndIdx] = useState(2);
  const [seeAll, setSeeAll] = useState(false);
  const [modalState, setModalState] = useState({
    edit: false,
    modalTitle: "",
    id: null,

    title: "",
    description: "",
    industry_name: "",
    upload_img: "/Image/Profile/Default.svg",
    research_type: "",
    issue_date: null,
    isongoing: false,
    url: "",
    add: false,
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);

  const handleDropdownOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (item) => {
    setSelectedValue(item);
    handleDropdownClose();
    handleEditExperience(item);
  };

  const handleEditExperience = (item) => {
    const newModalTitle = t("profile.addResearchWork");

    setModalState({
      modalTitle: newModalTitle,
      edit: true,
      add: true,
      id: item.id,
      upload_img: item.image || "",
      title: item.title,
      description: item.description,
      issue_date: item.issue_date,
      industry_name: item.industry_name,
      url: item.url,
      research_type: item.value,
    });

    document.body.style.overflow = "hidden";
  };

  const changeEdit = () => {
    setModalState((prev) => ({ ...prev, edit: false }));
  };

  const handleEdit = (item) => {
    setModalState({
      modalTitle: t("profile.editResearchWork"),
      edit: true,
      add: false,
      id: item.id,
      upload_img: item.image,
      title: item.title,
      description: item.description,
      issue_date: item.issue_date,
      industry_name: item.industry_name,
      url: item.url,
      research_type: item.research_type,
      isongoing: item.ongoing,
    });
    document.body.style.overflow = "hidden";
  };

  return (
    <div>
      <Box sx={{ display: "flex", gap: 2, mb: 1.5 }}>
        <img
          src="/Image/Profile/research.svg"
          alt="research"
          style={{ width: "25px" }}
        />
        <Typography
          sx={{ color: "#606262", fontSize: "18px", fontWeight: 600 }}
        >
          {t("profile.researchWorkTitle")}
        </Typography>
      </Box>

      <div
        className={styles.desc}
        style={{
          boxShadow: "0px 0px 20px #5555551a",
          padding: 10,
          borderRadius: 10,
        }}
      >
        <div style={{ padding: 10 }}>
          <p className={styles.edit_info}>{t("profile.addResearchWork")}</p>

          {/* Button to open dropdown menu */}
          <Button
            disableRipple
            sx={{
              background: "#F33070",
              padding: "6px 20px",
              borderRadius: 40,
              cursor: "pointer",
              ":hover": { background: "#F33070" },
            }}
            onClick={handleDropdownOpen}
          >
            <p className={styles.btn_text} style={{ color: "#fff" }}>
              + {t("profile.addResearchWork")}
            </p>
          </Button>
          {researchData &&
            researchData?.slice(0, endIdx).map((item, idx) => (
              <div
                key={item.id}
                style={{
                  borderBottom:
                    researchData?.length === 1 ||
                    (researchData?.length === 2 && idx === 1)
                      ? "none"
                      : "1px solid #5555551A",
                  paddingBottom: 15,
                  marginTop: 20,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* Patent Image */}
                  <img
                    src={
                      item.image ||
                      "/Image/apps/profile-resume-edit-view/DefaultFeed.svg"
                    }
                    height="50px"
                    width="50px"
                    style={{ borderRadius: "40px", marginRight: 15 }}
                    alt="Research"
                  />

                  {/* Patent Details */}
                  <div>
                    {/* Title + Issuer */}
                    <Typography sx={{ fontWeight: 600, color: "#F33070" }}>
                      <u>{item.title}</u>
                    </Typography>
                    <Typography
                      sx={{ color: "#606262", fontSize: "14px", opacity: 0.8 }}
                    >
                      {item.industry_name} • {item.issue_date}
                    </Typography>

                    {/* Show Publication Button */}
                    {item.url && (
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ margin: "0.3rem 0" }}
                      >
                        <Button
                          variant="outlined"
                          sx={{
                            fontSize: "12px",
                            textTransform: "none",
                            marginTop: "5px",
                            borderRadius: "20px",
                            color: "#606262",
                            borderColor: "#606262",
                            "&:hover": { borderColor: "#555" },
                          }}
                        >
                          {t("profile.showResearch")} 🔗
                        </Button>
                      </a>
                    )}

                    {/* Description */}
                    <Typography
                      sx={{
                        color: "#606262",
                        fontSize: "14px",
                        marginTop: "5px",
                      }}
                    >
                      {item.description}
                    </Typography>
                  </div>
                </div>
                <div>
                  <img
                    src="/Image/Profile/edit.svg"
                    style={{ height: 20, cursor: "pointer" }}
                    alt="Edit"
                    onClick={() => handleEdit(item)}
                  />
                </div>
              </div>
            ))}

          {Array.isArray(researchData) && researchData?.length > 2 && (
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: 10,
              }}
              onClick={() => {
                if (endIdx > 2) {
                  setEndIdx(2);
                } else {
                  setEndIdx(researchData?.length);
                }
                setSeeAll(!seeAll);
              }}
            >
              <button
                style={{
                  fontSize: 12,
                  fontWeight: 600,
                  color: "#555555",
                  margin: 10,
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                {seeAll ? t("common.collapse") : t("common.seeAll")}
              </button>
            </div>
          )}

          {/* Dropdown menu */}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleDropdownClose}
            MenuListProps={{ "aria-labelledby": "basic-button" }}
            menuStyle={{ boxShadow: "none" }}
            className={styles.memberMenu}
            PaperProps={{
              style: {
                boxShadow: "0px 0px 20px rgba(63, 63, 65, 0.102)",
              },
            }}
          >
            {menuOptions.map((opt, index) => (
              <MenuItem onClick={() => handleSelect(opt)} key={index}>
                {opt.name}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </div>

      {/* Modal opens only when an item is selected */}
      {modalState.edit && (
        <Modal
          card={
            <EditResearch
              id={modalState.id}
              modalTitle={modalState.modalTitle}
              title={modalState.title}
              issue_date={modalState.issue_date}
              industry_name={modalState.industry_name}
              upload_img={modalState.upload_img}
              description={modalState.description}
              research_type={modalState.research_type}
              url={modalState.url}
              add={modalState.add}
              isongoing={modalState.isongoing}
              changeEdit={changeEdit}
            />
          }
        />
      )}
    </div>
  );
};

export default ResearchWork;
