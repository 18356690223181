export const getResearchTitleLabelText = (title, t) => {
  if (title?.includes("industry")) return `${t("profile.industryName")}*`;
  if (title?.includes("govt")) return `${t("profile.governmentName")}*`;
  if (title?.includes("other")) return `${t("profile.conductorName")}*`;
  return "";
};

export const getPatentLabelText = (title, type, t) => {
  const labels = {
    Book: {
      title: "profile.bookTitle",
      publisher: "profile.bookPublisher",
      date: "profile.publicationDate",
      url: "profile.bookURL",
    },
    Patent: {
      title: "profile.patentTitle",
      publisher: "profile.patentPublisher",
      date: "profile.issueDate",
      url: "profile.patentURL",
    },
    Paper: {
      title: "profile.paperTitle",
      publisher: "profile.paperPublisher",
      date: "profile.issueDate",
      url: "profile.paperURL",
    },
    "Case Study": {
      title: "profile.caseStudyTitle",
      publisher: "profile.caseStudyPublisher",
      date: "profile.issueDate",
      url: "profile.caseStudyURL",
    },
  };

  const key = Object.keys(labels).find((key) => title?.includes(key));

  return key ? t(labels[key][type]) : "";
};
