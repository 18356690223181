import React, { useEffect, useState } from "react";
import styles from "./css/SmallChatBox.module.css";
import ChatList from "./ChatList";
import ChatHeader from "./ChatHeader";
import { useSelector } from "react-redux";
import i18next, { t } from "i18next";

export default function SmallChatBox(props) {
  const { isChatListOpen, chatOpener, toggleChatList, dmList = [], updateDmList} = props;
  const profilePicUrl = useSelector((state) =>
    state.profile.userProfilePic?.replace("blob:", "")
  );
  const [currentLanguage, setCurrentLanguage] = useState(i18next.language);
  const [messageTitle, setMessageTitle] = useState(t("message.title"));

  // Added effect to detect language change
  useEffect(() => {
    const languageChanged = () => {
      setCurrentLanguage(i18next.language);
      setMessageTitle(t("message.title"));
    };

    i18next.on("languageChanged", languageChanged);

    return () => {
      i18next.off("languageChanged", languageChanged);
    };
  }, []);

  const openChat = (id = "new_message", person = {}) => {
    chatOpener(id);
  };

  const openChatList = () => {
    toggleChatList();
    updateDmList();
  };

  // open new chat big box
  const handleCloseNewMessageBox = (e) => {
    e.stopPropagation();
    openChat("new_message");
  };

  return (
    <div id="div-smallchat" className={styles.outerContainer}>
      <ChatHeader
        arrowClick={openChatList}
        title={messageTitle}
        handleNewMessageClick={handleCloseNewMessageBox}
        profilePic={profilePicUrl}
      />
      {isChatListOpen && <ChatList updateDmList={updateDmList} openChat={openChat} dmList={dmList} />}
    </div>
  );
}
